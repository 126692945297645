import React, {useContext} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import HeaderLogoIcon from 'img/header-logo-icon.png';
import AppContext from "../contexts/AppContext";
import {GetMenuUrl} from "../constants/MenuCons";

const Header = ({}) => {

  const { pathname } = useLocation();
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const goLoginPage = () => {
    navigate('/');
  }

  const goStep1 = () => {
    navigate('/literature/step1');
  }

  return (
    <div className="bg-[#60448d] text-white">
      <div className={'container px-10 py-5'}>
        <div className="flex justify-between items-center">
          <div>
            <div className="flex cursor-pointer gap-[9px]" onClick={goStep1}>
              <span className="font-bold text-[36px]">KERAS</span>
              <span className={`flex gap-[5px] items-center`}>
              <img src={HeaderLogoIcon} alt="" className={`w-[14px] h-[14px]`}/>
              오염부지 토양생태 위해성평가 프로그램
            </span>
            </div>
          </div>
          <p className="flex justify-between font-bold text-[18px] gap-[30px]">
            {
              location.pathname !== '/' ?
                <>
                  <span className="cursor-pointer" onClick={() => GetMenuUrl(pathname, navigate, appContext.appData)}>다음</span>
                  <span className="cursor-pointer" onClick={() => appContext.initialize()}>데이터 초기화</span>
                  <span className="cursor-pointer" onClick={e => appContext.fileOpen()}>열기</span>
                  <span className="cursor-pointer" onClick={e => appContext.fileDownload()}>저장</span>
                  <span className={`cursor-pointer`} onClick={goLoginPage}>로그아웃</span>
                </>
                :
                <></>
            }
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
