import React, {useContext} from 'react';
import Side from "components/Side";
import {CheckboxColWrap, CheckboxLabel, FormGroup, FormWrap, RadioLabel, RadioWrap} from "../../constants/FormConstants";
import {GetMenuUrl} from "constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import {
  LayoutBox,
  LayoutBoxHeader,
  LayoutBoxHeaderH2,
  LayoutBoxHeaderRight,
  LayoutContentsHeader,
  LayoutContentsInner,
  LayoutContentsWrap,
  LayoutSubTitle
} from "../../constants/LayoutCons";
import AppContext from "../../contexts/AppContext";
import * as lodash from "lodash";

const Step1 = ({}) => {

  const appContext = useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const checkValue = () => {
    let flag = false;
    Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행']).map(d => {
      if(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행'][d]) {
        flag = true;
      }
    });
    Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행']).map(d => {
      if(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행'][d]) {
        flag = true;
      }
    });
    return flag;
  }

  const onChange = (field, value, checked) => {
    appContext.appData['생태위해성평가 필요성']['생태위해성평가'][field][value] = checked;
    appContext.appData['생태위해성평가 필요성']['생태위해성평가']['생태위해성평가가 필요한가?'] = checkValue() === true ? '예' : '아니오';
    const data = lodash.merge({}, appContext.appData);
    appContext.setAppData(data);
  }

  const 필요성체크 = (value) => {
    if(value === '예' && checkValue() === false) {
      alert('1, 2번 항목을 선택해 주세요.');
      return false;
    }
    if(value === '아니오') {
      Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행']).map(d => {
        appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행'][d] = false
      });
      Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행']).map(d => {
        appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행'][d] = false
      });
    }
    appContext.appData['생태위해성평가 필요성']['생태위해성평가']['생태위해성평가가 필요한가?'] = value;
    const data = lodash.merge({}, appContext.appData);
    appContext.setAppData(data);
  }

  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <FormWrap>
                <FormGroup>
                  <LayoutSubTitle>1.대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행</LayoutSubTitle>
                  <CheckboxColWrap>
                    {
                      Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행']).map(d => {
                        return <CheckboxLabel key={d}>
                          <input type={`checkbox`} name={'이행1'} onChange={e => onChange('대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행', e.target.value, e.target.checked)} value={d} checked={appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행'][d]}/> {d}
                        </CheckboxLabel>
                      })
                    }
                  </CheckboxColWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>2.대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행</LayoutSubTitle>
                  <CheckboxColWrap>
                    {
                      Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행']).map(d => {
                        return <CheckboxLabel key={d}>
                          <input type={`checkbox`} name={'이행2'} onChange={e => onChange('대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행', e.target.value, e.target.checked)} value={d} checked={appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행'][d]}/> {d}
                        </CheckboxLabel>
                      })
                    }
                  </CheckboxColWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>생태위해성평가가 필요한가?</LayoutSubTitle>
                  <RadioWrap>
                    <RadioLabel>
                      <input type={`radio`} name={'필요'} value={'예'} checked={appContext.appData['생태위해성평가 필요성']['생태위해성평가']['생태위해성평가가 필요한가?'] === '예'} onChange={e => 필요성체크(e.target.value)}/> 예
                    </RadioLabel>
                    <RadioLabel>
                      <input type={`radio`} name={'필요'} value={'아니오'} checked={appContext.appData['생태위해성평가 필요성']['생태위해성평가']['생태위해성평가가 필요한가?'] === '아니오'} onChange={e => 필요성체크(e.target.value)}/> 아니오
                    </RadioLabel>
                  </RadioWrap>
                </FormGroup>
              </FormWrap>
            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

export default Step1;
