import React, {useContext} from 'react';
import Side from "components/Side";
import {FormGroup, FormWrap,} from "../../constants/FormConstants";
import {GetMenuUrl} from "constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import {
  LayoutBox,
  LayoutBoxHeader,
  LayoutBoxHeaderH2,
  LayoutBoxHeaderRight,
  LayoutContentsHeader,
  LayoutContentsInner,
  LayoutContentsWrap,
  LayoutSubTitle
} from "../../constants/LayoutCons";
import AppContext from "../../contexts/AppContext";
import * as lodash from "lodash";

const Step2 = ({}) => {

  const appContext = useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <FormWrap>
                <FormGroup>
                  <LayoutSubTitle>주요 관속식물상</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 관속식물상']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 관속식물상'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>주요 무척추동물(곤충 등)</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 무척추동물(곤충 등)']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 무척추동물(곤충 등)'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>주요 양서파충류상</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 양서파충류상']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 양서파충류상'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>주요 조류상</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 조류상']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 조류상'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>주요 포유류상</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 포유류상']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 포유류상'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>주요 어류상</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 어류상']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 어류상'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>기타 필요한 사항</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['생태수용체 기본정보']['기타 필요한 사항']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['생태수용체 기본정보']['기타 필요한 사항'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
              </FormWrap>
            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

export default Step2;
