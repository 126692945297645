import './App.css';
import {Route, Routes} from "react-router-dom";
import AppContext from "./contexts/AppContext";
import Layout from "./components/Layout";
import MainPage from "./pages/MainPage";
import ChemistryRouter from "./pages/chemistry/Router";
import LiteratureRouter from "./pages/literature/Router";
import EcologicalRouter from "./pages/ecological/Router";
import InvestigationRouter from "./pages/investigation/Router";
import DeterminingRouter from "./pages/determining/Router";
import EcotoxicityRouter from "./pages/ecotoxicity/Router";
import RiskecologicalRouter from "./pages/riskecological/Router";
import FinalPage from "./pages/FinalPage";
import IntegratedPage from "./pages/IntegratedPage";
import FinalReportPage from "./pages/FinalReportPage";
import {useContext, useRef, useState} from "react";
import {Toaster} from "react-hot-toast";
import {InitialSettingAppData} from "./constants/MenuCons";
import Decimal from "decimal.js";

function App() {

  let InitialAppData = {};
  try {
    const appData = localStorage.getItem('appData');
    InitialAppData = appData ? JSON.parse(appData) : InitialSettingAppData;
  } catch (e) {
  }
  const [appData, setAppData] = useState(InitialAppData);
  const hiddenFileInput = useRef(null); // ADDED
  const hiddenExcelFileInput = useRef(null); // ADDED

  const saveLocalStorage = (data) => {
    try {
      localStorage.setItem('appData', JSON.stringify(data));
    } catch (e) {
      alert('local storage not working');
      console.log(e);
    }
  }

  var ExcelToJSON = function(func) {

    this.parseExcel = function(file) {
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = e.target.result;
        var workbook = window.XLSX.read(data, {
          type: 'binary'
        });
        let i = 0;
        workbook.SheetNames.forEach(function(sheetName) {
          // Here is your object
          var XL_row_object = window.XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          var json_object = JSON.stringify(XL_row_object);
          if(i === 0) {
            func(JSON.parse(json_object));
          }
          ++i;
        })
      };

      reader.onerror = function(ex) {
        console.log(ex);
      };
      reader.readAsBinaryString(file);
    };
  };

  const handleExcelChange = (event, func) => {
    var files = event.target.files; // FileList object
    var xl2json = new ExcelToJSON(func);
    xl2json.parseExcel(files[0]);
    event.target.value = '';
  }

  const handleChange = (event) => {
    const data = event.target;
    const reader = new FileReader();
    reader.onload = () => {
      console.log('Open file', JSON.parse(reader.result));

      const data = JSON.parse(reader.result);
      if(data['토양독성실험종'].length > 0) {
        data['토양독성실험종'].map((실험, 실험순번) => {
          실험['시료'].map((시료, 시료순번) => {
            let total = 0, length = 시료['데이터'].length;
            const 데이터배열 = 시료['데이터'];
            const 첫번째시료 = data['토양독성실험종'][실험순번]['시료'][0];
            const 첫번째시료평균 = 첫번째시료['평균'];
            데이터배열.map(데이터 => {
              데이터.map(num => {
                try {
                  if(num !== 0 && num !== '') {
                    // ++length;
                    total = new Decimal(num).plus(total).toNumber();
                  }
                } catch (e) {}
              });
            });
            if(length === 0) length = 1;
            시료['평균'] = Number(new Decimal(total).div(length).toNumber().toFixed(2));
            if(시료순번 === 0) {
              시료['%전환'] = 100;
            } else {
              if(첫번째시료평균 === 0 || 시료['평균'] === 0) {
                시료['%전환'] = 0.001;
              } else {
                시료['%전환'] = Number(new Decimal(시료['평균']).div(첫번째시료평균).mul(100).toNumber().toFixed(2));
                if(new Decimal(시료['평균']).div(첫번째시료평균).mul(100).toNumber() === 0) {
                  시료['%전환'] = 0.001;
                }
              }
            }
          })
        })
      }
      if(data['토양추출액독성실험종'].length > 0) {
        data['토양추출액독성실험종'].map((실험, 실험순번) => {
          console.log(실험);
          if(실험['실험명'] === "어류(수정란)") {
            실험['시료'].map((시료, 시료순번) => {
              let total = 0, length = 0;
              const 데이터배열 = 시료['데이터'];
              const 첫번째시료 = data['토양추출액독성실험종'][실험순번]['시료'][0];
              const 첫번째시료평균 = 첫번째시료['평균'];
              데이터배열.map(데이터 => {
                const 노출개체수 = Number(데이터[0]);
                const 정상개체수 = Number(데이터[1]);
                데이터[2] = 정상개체수 > 0 && 노출개체수 >0 ? new Decimal(정상개체수).div(노출개체수).mul(100).toNumber() : 0;

                ++length;
                total += 데이터[2];
              });
              if(length === 0) length = 1;
              시료['평균'] = Number(new Decimal(total).div(length).toNumber().toFixed(2));
              if(시료순번 === 0) {
                시료['%전환'] = 100;
              } else {
                if(첫번째시료평균 === 0 || 시료['평균'] === 0) {
                  시료['%전환'] = 0.001;
                } else {
                  시료['%전환'] = Number(new Decimal(시료['평균']).div(첫번째시료평균).mul(100).toNumber().toFixed(2));
                  if(new Decimal(시료['평균']).div(첫번째시료평균).mul(100).toNumber() === 0) {
                    시료['%전환'] = 0.001;
                  }
                }
              }
            });
          } else {
            실험['시료'].map((시료, 시료순번) => {
              let total = 0, length = 시료['데이터'].length;
              const 데이터배열 = 시료['데이터'];
              const 첫번째시료 = data['토양추출액독성실험종'][실험순번]['시료'][0];
              const 첫번째시료평균 = 첫번째시료['평균'];
              데이터배열.map(데이터 => {
                데이터.map(num => {
                  try {
                    if(num !== 0 && num !== '') {
                      // ++length;
                      total = new Decimal(num).plus(total).toNumber();
                    }
                  } catch (e) {}
                });
              });
              if(length === 0) length = 1;
              시료['평균'] = Number(new Decimal(total).div(length).toNumber().toFixed(2));
              if(시료순번 === 0) {
                시료['%전환'] = 100;
              } else {
                if(첫번째시료평균 === 0 || 시료['평균'] === 0) {
                  시료['%전환'] = 0.001;
                } else {
                  시료['%전환'] = Number(new Decimal(시료['평균']).div(첫번째시료평균).mul(100).toNumber().toFixed(2));
                  if(new Decimal(시료['평균']).div(첫번째시료평균).mul(100).toNumber() === 0) {
                    시료['%전환'] = 0.001;
                  }
                }
              }
            })
          }
        })
      }

      if(data['Eco plate']['시료'].length > 0) {
        let 시료데이터 = data['Eco plate']['시료'];
        시료데이터.map((시료, 시료순번) => {
          const 데이터배열 = 시료['데이터'];
          let total = 0, length = 데이터배열.length;
          데이터배열.map(데이터 => {
            데이터.map(num => {
              try {
                total = new Decimal(num).plus(total).toNumber();
              } catch (e) {}
            });
          });
          시료['평균'] = new Decimal(total).div(length).toNumber().toFixed(2);
          const 대조구역평균 = 시료데이터[0]['평균'];
          시료['%전환'] = new Decimal(시료['평균']).div(대조구역평균).mul(100).toNumber().toFixed(2);
          if(new Decimal(시료['평균']).div(대조구역평균).mul(100).equals(0)) {
            시료['%전환'] = 0.001;
          }
        });
      }
      if(data['Bait-lamina']['시료'].length > 0) {
        let 시료데이터 = data['Bait-lamina']['시료'];
        시료데이터.map((시료, 시료순번) => {
          const 데이터배열 = 시료['데이터'];
          let total = 0, length = 데이터배열.length;
          데이터배열.map(데이터 => {
            데이터.map(num => {
              try {
                total = new Decimal(num).plus(total).toNumber();
              } catch (e) {}
            });
          });
          시료['평균'] = new Decimal(total).div(length).toNumber().toFixed(2);
          const 대조구역평균 = 시료데이터[0]['평균'];
          시료['%전환'] = new Decimal(시료['평균']).div(대조구역평균).mul(100).toNumber().toFixed(2);
          if(new Decimal(시료['평균']).div(대조구역평균).mul(100).equals(0)) {
            시료['%전환'] = 0.001;
          }
        });
      }
      if(data['톡토기 종 풍부도']['시료'].length > 0) {
        let 시료데이터 = data['톡토기 종 풍부도']['시료'];
        시료데이터.map((시료, 시료순번) => {
          const 데이터배열 = 시료['데이터'];
          let total = 0, length = 데이터배열.length;
          데이터배열.map(데이터 => {
            데이터.map(num => {
              try {
                total = new Decimal(num).plus(total).toNumber();
              } catch (e) {}
            });
          });
          시료['평균'] = new Decimal(total).div(length).toNumber().toFixed(2);
          const 대조구역평균 = 시료데이터[0]['평균'];
          시료['%전환'] = new Decimal(시료['평균']).div(대조구역평균).mul(100).toNumber().toFixed(2);
          if(new Decimal(시료['평균']).div(대조구역평균).mul(100).equals(0)) {
            시료['%전환'] = 0.001;
          }
        });
      }
      if(data['응애 종 풍부도']['시료'].length > 0) {
        let 시료데이터 = data['응애 종 풍부도']['시료'];
        시료데이터.map((시료, 시료순번) => {
          const 데이터배열 = 시료['데이터'];
          let total = 0, length = 데이터배열.length;
          데이터배열.map(데이터 => {
            데이터.map(num => {
              try {
                total = new Decimal(num).plus(total).toNumber();
              } catch (e) {}
            });
          });
          시료['평균'] = new Decimal(total).div(length).toNumber().toFixed(2);
          const 대조구역평균 = 시료데이터[0]['평균'];
          시료['%전환'] = new Decimal(시료['평균']).div(대조구역평균).mul(100).toNumber().toFixed(2);
          if(new Decimal(시료['평균']).div(대조구역평균).mul(100).equals(0)) {
            시료['%전환'] = 0.001;
          }
        });
      }


      {
        try {
          const pollutants = ['유기물질', '중금속', '기타'];
          pollutants.map(pollutant => {
            Object.keys(data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(v => {
              if(data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][v] === true) {
                let total = 0, length = 0;
                data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => {
                  if(parseFloat(o[v]) && o[v] > 0) {
                    ++length;
                    total += parseFloat(o[v]);
                  }
                });
                data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
                  if(parseFloat(o[v]) && o[v] > 0) {
                    ++length;
                    total += parseFloat(o[v]);
                  }
                });
                try {
                  data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력']['평균'][v] = isNaN(new Decimal(total).div(length).toNumber()) ? 0 : new Decimal(total).div(length).toNumber();
                } catch (e) {
                  data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력']['평균'][v] = 0;
                }
              }
            })
          })

          // 표준 편차
          pollutants.map(pollutant => {
            Object.keys(data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(v => {
              if(data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][v] === true) {
                let total = 0, length = 0;
                data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => {
                  if(parseFloat(o[v]) && o[v] > 0) {
                    ++length;
                    let value = parseFloat(o[v]) - data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['평균'][v];
                    total += parseFloat((Math.pow(value, 2)));
                  }
                });
                data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
                  if(parseFloat(o[v]) && o[v] > 0) {
                    ++length;
                    let value = parseFloat(o[v]) - data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['평균'][v];
                    total += parseFloat((Math.pow(value, 2)));
                  }
                });
                let result = Math.sqrt(new Decimal(total).div(length - 1));
                data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력']['표준편차'][v] = parseFloat(result);
              }
            })
          })
        } catch (e) {}
      }

      {
        try {
          const pollutants = ['유기물질', '중금속', '기타'];
          pollutants.map(pollutant => {
            Object.keys(data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(v => {
              if(data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][v] === true) {
                let total = 0, length = 0;
                data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
                  if(parseFloat(o[v]) && o[v] > 0) {
                    ++length;
                    total += parseFloat(o[v]);
                  }
                });
                data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
                  if(parseFloat(o[v]) && o[v] > 0) {
                    ++length;
                    total += parseFloat(o[v]);
                  }
                });
                try {
                  data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['평균'][v] = isNaN(new Decimal(total).div(length).toNumber()) ? 0 : new Decimal(total).div(length).toNumber();
                } catch (e) {
                  data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['평균'][v] = 0;
                }
              }
            })
          })

          // 표준 편차
          pollutants.map(pollutant => {
            Object.keys(data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(v => {
              if(data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][v] === true) {
                let total = 0, length = 0;
                data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
                  if(parseFloat(o[v]) && o[v] > 0) {
                    ++length;
                    let value = parseFloat(o[v]) - data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['평균'][v];
                    total += parseFloat((Math.pow(value, 2)));
                  }
                });
                data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
                  if(parseFloat(o[v]) && o[v] > 0) {
                    ++length;
                    let value = parseFloat(o[v]) - data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['평균'][v];
                    total += parseFloat((Math.pow(value, 2)));
                  }
                });
                let result = isNaN(Math.sqrt(new Decimal(total).div(length - 1))) ? 0 : Math.sqrt(new Decimal(total).div(length - 1));
                data['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['표준편차'][v] = parseFloat(result);
              }
            })
          })
        } catch (e) {}
      }

      setAppData(data);
      saveLocalStorage(data);
      event.target.value = '';
    }
    try {
      reader.readAsText(data.files[0]);
    } catch (e) {}
  }


  return (
    <AppContext.Provider value={{
      appData: appData,
      setAppData: (data) => {
        setAppData(data);
        saveLocalStorage(data);
      },
      initialize: () => {
        setAppData(InitialSettingAppData);
        saveLocalStorage(InitialSettingAppData);
      },
      fileDownload: () => {
        const fileData = JSON.stringify(appData);
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "file.json";
        link.href = url;
        link.click();
      },
      fileOpen: () => {
        hiddenFileInput.current.click();
      },
      importExcel: (func) => {

        hiddenExcelFileInput.current.addEventListener('change', (e) => {
          handleExcelChange(e, func)
        }, {once: true});

        hiddenExcelFileInput.current.click();
      }
    }}>
      <Layout>
        <Routes>
          <Route path={'/'} element={<MainPage />} />
          <Route path={'/chemistry/*'} element={<ChemistryRouter />} />
          <Route path={'/literature/*'} element={<LiteratureRouter />} />
          <Route path={'/ecological/*'} element={<EcologicalRouter />} />
          <Route path={'/investigation/*'} element={<InvestigationRouter />} />
          <Route path={'/determining/*'} element={<DeterminingRouter />} />
          <Route path={'/ecotoxicity/*'} element={<EcotoxicityRouter />} />
          <Route path={'/riskecological/*'} element={<RiskecologicalRouter />} />
          <Route path={'/final'} element={<FinalPage />} />
          <Route path={'/integrated'} element={<IntegratedPage />} />
          <Route path={'/finalreport'} element={<FinalReportPage />} />
        </Routes>
        <input
          type="file"
          onChange={e => {
            handleChange(e)
          }}
          ref={hiddenFileInput}
          style={{display:'none'}}
          accept=".json"
        />
        <input
          type="file"
          ref={hiddenExcelFileInput}
          style={{display:'none'}}
          accept=".xlsx"
        />
      <Toaster />
      </Layout>
    </AppContext.Provider>
  );
}

export default App;
