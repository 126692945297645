import toast from "react-hot-toast";

const ToastError = (msg) => {
  toast(msg, {
    duration: 3000,
    position: 'top-center',
    style:{background: '#dc3545', color: '#fff', fontSize: 14, width: 350, fontWeight: 'bold'}
  })
};


const ToastInfo = (msg) => {
  toast(msg, {
    duration: 3000,
    position: 'top-center',
    style:{background: '#cfe2ff', color: '#084298', fontSize: 14, width: 350, fontWeight: 'bold'}
  })
};

export default {
  error: ToastError,
  info: ToastInfo,
}
