import React from 'react';
import {Route, Routes} from "react-router-dom";
import Step1Page from "./Step1Page";
import Step2Page from "./Step2Page";
import Step3Page from "./Step3Page";
import MainPage from "./MainPage";


const Router = ({}) => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<MainPage />} />
        <Route path={'step1/*'} element={<Step1Page />} />
        <Route path={'step2/*'} element={<Step2Page />} />
        <Route path={'step3'} element={<Step3Page />} />
      </Routes>
    </>
  );
};

export default Router;
