import React, {useContext, useEffect, useState} from 'react';
import Side from "components/Side";
import {useLocation, useNavigate} from "react-router-dom";
import {LayoutBox, LayoutContentsInner, LayoutContentsWrap} from "constants/LayoutCons";
import {FormGroup, FormTableTbodyTr, FormTableTd, FormTableTh, FormTableTheadTr, FormWrap} from "constants/FormConstants";
import AppContext from "../contexts/AppContext";
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styled from "styled-components";
import KerasImage from "../util/KerasImage";
import axios from "axios";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GraphGridWrap = styled.div`
  display: grid; grid-template-columns: 1fr 1fr; gap: 20px;
`

export default  ({}) => {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: '시료명',
          font: {
            size: 14,
            weight: 'normal',
            lineHeight: 1.2,
          },
          // padding: {top: 20, left: 0, right: 0, bottom: 0}
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: '토양농도(mg/kg)',
          font: {
            size: 14,
            style: 'normal',
            lineHeight: 1.2
          },
          // padding: {top: 30, left: 0, right: 0, bottom: 0}
        }
      }
    }

  };
  const [대상부지기본정보노출여부, set대상부지기본정보노출여부] = useState(false);
  const appContext = useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const keyArray = ['유기물질', '중금속', '기타'];
  const pollutants = ['유기물질', '중금속', '기타'];
  const standard = {'유기인': 20, '폴리클로리네이티드비페닐': 40, '페놀':22, '벤젠': 32, '톨루엔': 0.1, '에틸벤젠': 0.5, '크실렌': 9, '석유계총탄화수소': 1,
    '트리클로로에틸렌': 2.3, '테트라클로로에틸렌': 3.4, '벤조(a)피렌': 4, 'Cd': 4, 'Cu': 150, 'As': 25, 'Hg': 4, 'Pb': 25, 'Cr6+': 0.4, 'Zn': 350, 'Ni': 150, 'F': 250, '시안화합물': 0.7};


  const [mapUrl, setMapUrl] = useState(appContext.appData['평가단위 결정 지도 url']);
  /*useEffect(() => {
    /!*let sendData = [].concat(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
      return {'Name': o['시료명'], '경도': o['경도'], '위도': o['위도'], 'Cd': o['Cd'], 'Cu': o['Cu'], 'As': o['As'], 'Pb': o['Pb'], 'Zn': o['Zn'], 'Ni': o['Ni']};
    })).concat(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
      return {'Name': o['시료명'], '경도': o['경도'], '위도': o['위도'], 'Cd': o['Cd'], 'Cu': o['Cu'], 'As': o['As'], 'Pb': o['Pb'], 'Zn': o['Zn'], 'Ni': o['Ni']};//o['name']= o['시료명'];
    }));

    axios
      .post('https://api.keras-b.7greenus.com/mapv', sendData)
      .then(({data, status}) => {
        if(status === 200 && data.message === '성공') {
          setMapUrl(data.mapurl);
        }
      }).catch(({response}) => {
      alert(response?.data?.message ? response.data.message : '오류가 발생하였습니다.');
    });*!/

    let tmpDecisionData = [];
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'YES') {
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
    }
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'NO') {
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
    }

    if(tmpDecisionData.length === 0) {
      return;
    }
    let sendData = [].concat(tmpDecisionData.map(o => {
      return {'Name': o['시료명'], '경도': Number(o['경도']), '위도': Number(o['위도']), 'Cd': o['Cd'], 'Cu': o['Cu'], 'As': o['As'], 'Pb': o['Pb'], 'Zn': o['Zn'], 'Ni': o['Ni']};
    }));

    if(sendData.length > 0) {
      axios
        .post('https://api.keras-b.7greenus.com/mapv', sendData)
        .then(({data, status}) => {
          if(status === 200 && data.message === '성공') {
            setMapUrl(data.mapurl);
          }
        }).catch(({response}) => {
        alert(response?.data?.message ? response.data.message : '오류가 발생하였습니다.');
      });
    }

  }, []);*/

  const mapMove = () => {
    const map = new window.ol.Map({
      id: 'map',
      target: 'map',
      renderer: 'canvas',
      view: new window.ol.View({
        center: window.ol.proj.fromLonLat([127.5, 36]),
        zoom: 7,
        minZoom: 6,
        maxZoom: 19,
        projection: "EPSG:3857",
        extent: [13678546.51713, 3834188.8033424, 14854453.760059, 5314661.8558898]
      })
    });

    const markerVectorLayer = new window.ol.layer.Vector({
      source: new window.ol.source.Vector(),
      zIndex: 999
    });
    map.addLayer(markerVectorLayer);

    var scaleControl = new window.ol.control.ScaleLine({units: 'metric'});
    map.addControl(scaleControl);
    var zoomSliderControl = new window.ol.control.ZoomSlider();
    map.addControl(zoomSliderControl);

    // Add layer
    var layer = window.ecvamLayerCreate('ecvam', 'true');
    map.addLayer(layer);

    const addressInput = appContext.appData['문헌조사']['대상부지 기본정보']['주소'];
    if(addressInput) {
      const geocoder = new window.kakao.maps.services.Geocoder();
      geocoder.addressSearch(addressInput, function (result, status) {
        if (status === window.kakao.maps.services.Status.OK) {
          const coords = result[0];
          const lat = parseFloat(coords.y);
          const lng = parseFloat(coords.x);

          if (!isNaN(lat) && !isNaN(lng)) {
            // Transform the coordinates from EPSG:4326 to EPSG:3857 (Web Mercator)
            let transformedCoordinates = window.ol.proj.transform([lng, lat], 'EPSG:4326', 'EPSG:3857');
            map.getView().setCenter(transformedCoordinates);
            map.getView().setZoom(14);

            markerVectorLayer.getSource().clear();

            var style = new window.ol.style.Style({
              image: new window.ol.style.Icon({
                anchor: [0.5, 1],
                scale: 0.8,
                src: 'https://openlayers.org/en/latest/examples/data/icon.png'
              })
            });

            var marker = new window.ol.Feature({
              geometry: new window.ol.geom.Point(transformedCoordinates)
            });
            marker.setStyle(style);

            markerVectorLayer.getSource().addFeature(marker);

          } else {
            alert("Invalid coordinates. Please enter a valid address.");
          }
        } else {
          alert("Address not found. Please enter a valid address.");
        }
      });
    }
  }

  useEffect(() => {
    try {
      mapMove();
    } catch (e) {
      window.onload = () => {
        mapMove();
      }
    }
  }, []);

  const 체크_국토환경성평가지도 = [].concat(Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['대상부지']).filter(k => {
    return appContext.appData['문헌조사']['국토환경성 평가지도']['대상부지'][k]
  }), Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km']).filter(k => {
    return appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km'][k]
  }), Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 5km']).filter(k => {
    return appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 5km'][k]
  }));

  const 체크_생태적가치평가 = [].concat(Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['대상부지']).filter(k => {
    return appContext.appData['문헌조사']['생태적 가치평가']['대상부지'][k]
  }), Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km']).filter(k => {
    return appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km'][k]
  }), Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km']).filter(k => {
    return appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km'][k]
  }));

  const 체크_오염물질 = keyArray.filter(key => {
    return Object.keys(appContext.appData['문헌조사']['오염물질 정보'][key]).filter(name =>
      appContext.appData['문헌조사']['오염물질 정보'][key][name]['checked']
    ).length > 0
  });



  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }

  let 변동계수산정기존조사결과있을때_그래프배열 = {};
  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map((o, i) => {
    pollutants.map(pollutant => {
      Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
        if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true) {
          if( ! o[key]) return;
          if( ! 변동계수산정기존조사결과있을때_그래프배열[key]) { 변동계수산정기존조사결과있을때_그래프배열[key] = [] }
          변동계수산정기존조사결과있을때_그래프배열[key].push({data: Number(o[key]), '시료명': o['시료명']});
        }
      })
    })
  });

  let 변동계수산정추가조사후입력_그래프배열 = {};
  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map((o, i) => {
    pollutants.map(pollutant => {
      Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
        if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true) {
          if( ! o[key]) return;
          if( ! 변동계수산정추가조사후입력_그래프배열[key]) { 변동계수산정추가조사후입력_그래프배열[key] = [] }
          변동계수산정추가조사후입력_그래프배열[key].push({data: Number(o[key]), '시료명': o['시료명']});
        }
      })
    })
  });

  let 변동계수산정기존조사추가조사결과입력_그래프배열 = [];
  Object.keys(변동계수산정기존조사결과있을때_그래프배열).map(key => {
    if( ! 변동계수산정기존조사추가조사결과입력_그래프배열[key]) 변동계수산정기존조사추가조사결과입력_그래프배열[key] = [];
    변동계수산정기존조사결과있을때_그래프배열[key].map(o => {
      변동계수산정기존조사추가조사결과입력_그래프배열[key].push(o);
    })
  });
  Object.keys(변동계수산정추가조사후입력_그래프배열).map(key => {
    if( ! 변동계수산정기존조사추가조사결과입력_그래프배열[key]) 변동계수산정기존조사추가조사결과입력_그래프배열[key] = [];
    변동계수산정추가조사후입력_그래프배열[key].map(o => {
      변동계수산정기존조사추가조사결과입력_그래프배열[key].push(o);
    })
  });

  let 변동계수산정정밀조사실시_그래프배열 = {};
  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map((o, i) => {
    pollutants.map(pollutant => {
      Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
        if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true) {
          if( ! o[key]) return;
          if( ! 변동계수산정정밀조사실시_그래프배열[key]) { 변동계수산정정밀조사실시_그래프배열[key] = [] }
          변동계수산정정밀조사실시_그래프배열[key].push({data: Number(o[key]), '시료명': o['시료명']});
        }
      })
    })
  });

  let 변동계수산정정밀조사추가조사후입력_그래프배열 = {};
  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map((o, i) => {
    pollutants.map(pollutant => {
      Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
        if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true) {
          if( ! o[key]) return;
          if( ! 변동계수산정정밀조사추가조사후입력_그래프배열[key]) { 변동계수산정정밀조사추가조사후입력_그래프배열[key] = [] }
          변동계수산정정밀조사추가조사후입력_그래프배열[key].push({data: Number(o[key]), '시료명': o['시료명']});
        }
      })
    })
  });

  let 변동계수산정정밀조사추가조사후입력결과_그래프배열 = [];
  Object.keys(변동계수산정정밀조사실시_그래프배열).map(key => {
    if( ! 변동계수산정정밀조사추가조사후입력결과_그래프배열[key]) 변동계수산정정밀조사추가조사후입력결과_그래프배열[key] = [];
    변동계수산정정밀조사실시_그래프배열[key].map(o => {
      변동계수산정정밀조사추가조사후입력결과_그래프배열[key].push(o);
    })
  });
  Object.keys(변동계수산정정밀조사추가조사후입력_그래프배열).map(key => {
    if( ! 변동계수산정정밀조사추가조사후입력결과_그래프배열[key]) 변동계수산정정밀조사추가조사후입력결과_그래프배열[key] = [];
    변동계수산정정밀조사추가조사후입력_그래프배열[key].map(o => {
      변동계수산정정밀조사추가조사후입력결과_그래프배열[key].push(o);
    })
  });


  let 토지용도배열 = [];
  appContext.appData['문헌조사']['대상부지 기본정보']['토지용도'].map((토지용도, 순번) => {
    if(토지용도['checked']) {
      토지용도배열.push(토지용도['용도명']);
    }
  });

  let 부지이력배열 = [];
  appContext.appData['문헌조사']['대상부지 기본정보']['부지이력'].map((부지이력, 순번) => {
    if(부지이력['checked']) {
      부지이력배열.push(부지이력['부지명']);
    }
  });

  let 유기물질배열 = [];
  Object.keys(appContext.appData['문헌조사']['대상부지 기본정보']['유기물질']).map((유기물질, index) => {
    if(appContext.appData['문헌조사']['대상부지 기본정보']['유기물질'][유기물질] === true) {
      유기물질배열.push(유기물질);
    }
  });

  let 중금속배열 = [];
  Object.keys(appContext.appData['문헌조사']['대상부지 기본정보']['중금속']).map((중금속, index) => {
    if(appContext.appData['문헌조사']['대상부지 기본정보']['중금속'][중금속] === true) {
      중금속배열.push(중금속);
    }
  });

  let 기타배열 = [];
  Object.keys(appContext.appData['문헌조사']['대상부지 기본정보']['기타']).map((기타, index) => {
    if(appContext.appData['문헌조사']['대상부지 기본정보']['기타'][기타] === true) {
      기타배열.push(기타);
    }
  });

  let 오염물질정보_유기물질배열 = [];
  Object.keys(appContext.appData['문헌조사']['오염물질 정보']['유기물질']).map(유기물질 => {
    if(appContext.appData['문헌조사']['오염물질 정보']['유기물질'][유기물질]['checked'] === true) {
      오염물질정보_유기물질배열.push(유기물질);
    }
  });

  let 오염물질정보_중금속배열 = [];
  Object.keys(appContext.appData['문헌조사']['오염물질 정보']['중금속']).map(중금속 => {
    if(appContext.appData['문헌조사']['오염물질 정보']['중금속'][중금속]['checked'] === true) {
      오염물질정보_중금속배열.push(중금속);
    }
  });

  let 오염물질정보_기타배열 = [];
  Object.keys(appContext.appData['문헌조사']['오염물질 정보']['기타']).map(기타 => {
    if(appContext.appData['문헌조사']['오염물질 정보']['기타'][기타]['checked'] === true) {
      오염물질정보_기타배열.push(기타);
    }
  });

  useEffect(() => {
    if(appContext.appData['문헌조사']['대상부지 기본정보']['주소'] || 토지용도배열.length > 0 || 부지이력배열.length > 0 || 중금속배열.length > 0 || 기타배열.length > 0 ) {
      set대상부지기본정보노출여부(true);
    }
  }, []);

  const 체크_문헌조사 = 대상부지기본정보노출여부 || 체크_국토환경성평가지도.length > 0 || 체크_생태적가치평가.length > 0 || 체크_오염물질.length > 0 || 토지용도배열.length > 0 || 부지이력배열.length > 0 || 유기물질배열.length > 0 ||
    중금속배열.length > 0 || 기타배열.length > 0;

  const 체크_생태위해성평가필요성 = Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행']).filter(k => appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행'][k]).length > 0
    || Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행']).filter(k => appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행'][k]).length > 0;


  let 체크_변동계수산정기존조사결과있을때 = 0
  let 물질배열_변동계수산정기존조사결과있을때 = [];
  pollutants.map(pollutant => {
    Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
      if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key]) {
        물질배열_변동계수산정기존조사결과있을때.push(key);
      }
    });
  });
  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(시료 => {
    if(시료['시료명']) ++체크_변동계수산정기존조사결과있을때;
    물질배열_변동계수산정기존조사결과있을때.map(물질 => {
      if(Number(시료[물질]) > 0) ++체크_변동계수산정기존조사결과있을때;
    });
  });


  let 체크_변동계수산정추가조사후입력 = 0;
  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(시료 => {
    if(시료['시료명']) ++체크_변동계수산정추가조사후입력;
    물질배열_변동계수산정기존조사결과있을때.map(물질 => {
      if(Number(시료[물질]) > 0) ++체크_변동계수산정추가조사후입력;
    });
  });


  let 물질배열_변동계수산정정밀조사실시 = [];
  pollutants.map(pollutant => {
    Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
      if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key]) {
        물질배열_변동계수산정정밀조사실시.push(key);
      }
    });
  });

  let 체크_변동계수산정정밀조사실시 = 0;
  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(시료 => {
    if(시료['시료명']) ++체크_변동계수산정정밀조사실시;
    물질배열_변동계수산정정밀조사실시.map(물질 => {
      if(Number(시료[물질]) > 0) ++체크_변동계수산정정밀조사실시;
    });
  });
  let 체크_변동계수산정정밀조사추가조사후입력 = 0;
  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(시료 => {
    if(시료['시료명']) ++체크_변동계수산정정밀조사추가조사후입력;
    물질배열_변동계수산정정밀조사실시.map(물질 => {
      if(Number(시료[물질]) > 0) ++체크_변동계수산정정밀조사추가조사후입력;
    });
  });

  let 물질배열_대조구역정보 = [];
  pollutants.map(pollutant => {
    Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['오염물질'][pollutant]).map(key => {
      if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['오염물질'][pollutant][key]) {
        물질배열_대조구역정보.push(key);
      }
    });
  });

  let 체크_대조구역정보 = 0;
  appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['시료'].map(시료 => {
    물질배열_대조구역정보.map(물질 => {
      시료['토양농도'].map(토양농도 => {
        if(Number(토양농도[물질]) > 0 || 시료['시료명']) ++체크_대조구역정보;
      })
    });
  });


  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              {
                체크_문헌조사 &&
                <>
                  <h5 className={`font-bold pb-2.5`}>문헌조사</h5>
                  <div className={`px-2.5`}>
                    <FormWrap>

                      <FormGroup>
                        <h5 className={`font-bold`}>1.대상부지 기본정보</h5>
                      </FormGroup>
                      {
                        appContext.appData['문헌조사']['대상부지 기본정보']['주소'] &&
                        <>
                          <FormGroup>
                            <div className={`px-2.5 text-xs`}>
                              <div className={`px-2.5 font-bold`}>주소</div>
                              <div className={`px-4`}>{appContext.appData['문헌조사']['대상부지 기본정보']['주소']}</div>
                            </div>
                          </FormGroup>
                        </>
                      }
                      {
                        토지용도배열.length > 0 &&
                        <>
                          <FormGroup>
                            <div className={`px-2.5 text-xs`}>
                              <div className={`px-2.5 font-bold`}>토지용도</div>
                              <div className={`px-4`}>
                                {토지용도배열.join(', ')}
                              </div>
                            </div>
                          </FormGroup>
                        </>
                      }
                      {
                        부지이력배열.length > 0 &&
                        <>
                          <FormGroup>
                            <div className={`px-2.5 text-xs`}>
                              <div className={`px-2.5 font-bold`}>부지이력</div>
                              <div className={`px-4`}>
                                {부지이력배열.join(', ')}{부지이력배열.join(', ').includes('기타') && `: ${appContext.appData['문헌조사']['대상부지 기본정보']['부지이력기타']}`}
                              </div>
                            </div>
                          </FormGroup>
                        </>
                      }
                      {
                        유기물질배열.length > 0 &&
                        <>
                          <FormGroup>
                            <div className={`px-2.5 text-xs`}>
                              <div className={`px-2.5 font-bold`}>오염물질 종류 - 1.유기물질</div>
                              <div className={`px-4`}>
                                {유기물질배열.join(', ')}
                              </div>
                            </div>
                          </FormGroup>
                        </>
                      }
                      {
                        중금속배열.length > 0 &&
                        <>
                          <FormGroup>
                            <div className={`px-2.5 text-xs`}>
                              <div className={`px-2.5 font-bold`}>오염물질 종류 - 2.중금속</div>
                              <div className={`px-4`}>{중금속배열.join(', ')}</div>
                            </div>
                          </FormGroup>
                        </>
                      }
                      {
                        기타배열.length > 0 &&
                        <>
                          <FormGroup>
                            <div className={`px-2.5 text-xs`}>
                              <div className={`px-2.5 font-bold`}>오염물질 종류 - 3.기타</div>
                              <div className={`px-4`}>{기타배열.join(', ')}</div>
                            </div>
                          </FormGroup>
                        </>
                      }
                      <FormGroup>
                        <div className={`grid grid-cols-2 px-2.5 gap-2.5 text-xs`}>
                          {
                            ((appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지']['image'])) &&
                              <div className="flex flex-col gap-2 flex-1" >
                                <div className={`px-2.5 font-bold`}>지적도등본</div>
                                <div>
                                  <KerasImage
                                    image={appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지']['image']}
                                    cls={`w-full block aspect-[389/401] object-cover cursor-pointer`}
                                    alt={`지적도등본 이미지`}
                                  />
                                </div>
                              </div>
                          }
                          {
                            ((appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지']['image'])) &&
                              <div className="flex flex-col gap-2 flex-1" >
                                <div className={`px-2.5 font-bold`}>수치지형도</div>
                                <div>
                                  <KerasImage
                                    image={appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지']['image']}
                                    cls={`w-full block aspect-[389/401] object-cover cursor-pointer`}
                                    alt={`수치지형도 이미지`}
                                  />
                                </div>
                              </div>
                          }
                          {
                            ((appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지']['image'])) &&
                              <div className="flex flex-col gap-2 flex-1" >
                                <div className={`px-2.5 font-bold`}>항공사진</div>
                                <div>
                                  <KerasImage
                                    image={appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지']['image']}
                                    cls={`w-full block aspect-[389/401] object-cover cursor-pointer`}
                                    alt={`항공사진 이미지`}
                                  />
                                </div>
                              </div>
                          }
                          {
                            ((appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지']['image'])) &&
                              <div className="flex flex-col gap-2 flex-1" >
                                <div className={`px-2.5 font-bold`}>토지(임야)대장등본</div>
                                <div>
                                  <KerasImage
                                    image={appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지']['image']}
                                    cls={`w-full block aspect-[389/401] object-cover cursor-pointer`}
                                    alt={`토지(임야)대장등본 이미지`}
                                  />
                                </div>
                              </div>
                          }
                          {
                            ((appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['image'])) &&
                              <div className="flex flex-col gap-2 flex-1" >
                                <div className={`px-2.5 font-bold`}>토지이용계획</div>
                                <div>
                                  <KerasImage
                                    image={appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['image']}
                                    cls={`w-full block aspect-[389/401] object-cover cursor-pointer`}
                                    alt={`토지이용계획 이미지`}
                                  />
                                </div>
                              </div>
                          }
                        </div>
                      </FormGroup>
                      {/*<FormGroup>
                        <div className={`flex px-2.5 gap-2.5 text-xs`}>
                          <div className="flex flex-col gap-2 flex-1">
                            <div className={`px-2.5 font-bold`}>토지이용계획</div>
                            <div>
                              {
                                (appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['image']) ?
                                  <KerasImage
                                    image={appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['image']}
                                    cls={`w-full block aspect-[389/401] object-cover cursor-pointer`}
                                    alt={`토지이용계획 이미지`}
                                  />
                                  :
                                  <EmptyImage
                                    cls={`w-full aspect-[389/401] bg-[#d9d9d9] flex rounded items-center justify-center`}
                                    txt={`(이미지 없음)`}
                                  />
                              }
                            </div>
                          </div>
                        </div>
                      </FormGroup>*/}

                      {
                        (체크_국토환경성평가지도.length > 0 || appContext.appData['문헌조사']['대상부지 기본정보']['주소']) &&
                        <>
                          <FormGroup>
                            <div className={`font-bold`}>2.생태적 가치평가 - 국토환경성 평가지도</div>
                          </FormGroup>
                          <FormGroup>
                            <div className={`flex px-2.5 gap-2.5 text-xs`}>
                              {
                                appContext.appData['문헌조사']['대상부지 기본정보']['주소'] &&
                                <>
                                  <div className="flex flex-col gap-2 flex-1">
                                    <div id={`map`} className={`h-[350px]`}></div>
                                  </div>
                                </>
                              }
                              <div className="flex flex-col gap-2 flex-1">
                                <div className={`flex gap-2 flex-col`}>
                                  {
                                    Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km']).filter(k => appContext.appData['문헌조사']['국토환경성 평가지도']['대상부지'][k]).length > 0 &&
                                    <>
                                      <div className="font-bold px-4">대상부지 등급</div>
                                      <div className="px-6">
                                        {
                                          Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['대상부지']).map(k => {
                                            return appContext.appData['문헌조사']['국토환경성 평가지도']['대상부지'][k] && k
                                          })
                                        }
                                      </div>
                                    </>
                                  }
                                  {
                                    Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km']).filter(k => appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km'][k]).length > 0 &&
                                    <>
                                      <div className="font-bold px-4">부지 인근 1km 등급</div>
                                      <div className="px-6">
                                        {
                                          Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km']).map(k => {
                                            return appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km'][k] && k
                                          })
                                        }
                                      </div>
                                    </>
                                  }
                                  {
                                    Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km']).filter(k => appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 5km'][k]).length > 0 &&
                                    <>
                                      <div className="font-bold px-4">부지 인근 5km 등급</div>
                                      <div className="px-6">
                                        {
                                          Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 5km']).map(k => {
                                            return appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 5km'][k] && k
                                          })
                                        }
                                      </div>
                                    </>
                                  }
                                  {
                                    appContext.appData['문헌조사']['국토환경성 평가지도']['비고'] &&
                                    <>
                                      <div className="font-bold px-4">비고</div>
                                      <div className="px-6">
                                        <pre>{appContext.appData['문헌조사']['국토환경성 평가지도']['비고']}</pre>
                                      </div>
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </FormGroup>
                        </>
                      }
                      {
                        ((체크_생태적가치평가.length > 0) || (appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지'] && appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지']['image'])) &&
                        <>
                          <FormGroup>
                            <div className={`font-bold`}>3.생태적 가치평가 - 생태/자연도</div>
                          </FormGroup>
                          <FormGroup>
                            <div className={`flex px-2.5 gap-2.5 text-xs`}>
                              {
                                (appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지'] && appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지']['image']) &&
                                  <div className="flex flex-col gap-2 flex-1">
                                    <div>
                                      <KerasImage
                                        image={appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지']['image']}
                                        cls={`w-full block aspect-[389/389] object-cover cursor-pointer`}
                                        alt={`생태/자연도 이미지`}
                                      />
                                    </div>
                                  </div>
                              }
                              <div className="flex flex-col gap-2 flex-1">
                                <div className={`flex gap-2 flex-col`}>
                                  {
                                    Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['대상부지']).filter(k => appContext.appData['문헌조사']['생태적 가치평가']['대상부지'][k]).length > 0 &&
                                    <>
                                      <div className="font-bold px-4">대상부지 등급</div>
                                      <div className="px-6">
                                        {
                                          Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['대상부지']).map(k => {
                                            return appContext.appData['문헌조사']['생태적 가치평가']['대상부지'][k] && k
                                          })
                                        }
                                      </div>
                                    </>
                                  }
                                  {
                                    Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km']).filter(k => appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km'][k]).length > 0 &&
                                    <>
                                      <div className="font-bold px-4">부지 인근 1km 등급</div>
                                      <div className="px-6">
                                        {
                                          Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km']).map(k => {
                                            return appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km'][k] && k
                                          })
                                        }
                                      </div>
                                    </>
                                  }
                                  {
                                    Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km']).filter(k => appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km'][k]).length > 0 &&
                                    <>
                                      <div className="font-bold px-4">부지 인근 5km 등급</div>
                                      <div className="px-6">
                                        {
                                          Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km']).map(k => {
                                            return appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km'][k] && k
                                          })
                                        }
                                      </div>
                                    </>
                                  }
                                  {
                                    appContext.appData['문헌조사']['생태적 가치평가']['비고'] &&
                                    <>
                                      <div className="font-bold px-4">비고</div>
                                      <div className="px-6">
                                        <pre>{appContext.appData['문헌조사']['생태적 가치평가']['비고']}</pre>
                                      </div>
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </FormGroup>
                        </>
                      }

                      {
                        체크_오염물질.length > 0 &&
                        <>
                          <FormGroup>
                            <div className="font-bold">4.오염물질 정보</div>
                          </FormGroup>
                          {
                            오염물질정보_유기물질배열.join(', ') &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>오염물질 종류 - 1.유기물질</div>
                                  <div className={`px-4`}>{오염물질정보_유기물질배열.join(', ')}</div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            오염물질정보_중금속배열.join(', ') &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>오염물질 종류 - 2.중금속</div>
                                  <div className={`px-4`}>{오염물질정보_중금속배열.join(', ')}</div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            오염물질정보_기타배열.join(', ') &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>오염물질 종류 - 3.기타</div>
                                  <div className={`px-4`}>{오염물질정보_기타배열.join(', ')}</div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          <FormGroup>
                            <div className="flex gap-2.5">
                              <div className={`flex-1 bg-white`}>
                                <FormTableTheadTr>
                                  <FormTableTh>오염물질</FormTableTh>
                                  <FormTableTh>95% 상위 신뢰값 (mg/kg)</FormTableTh>
                                  <FormTableTh>국외기준</FormTableTh>
                                  <FormTableTh>판정</FormTableTh>
                                </FormTableTheadTr>
                                {
                                  keyArray.map(key => {
                                    return Object.keys(appContext.appData['문헌조사']['오염물질 정보'][key]).map(name => {
                                      return appContext.appData['문헌조사']['오염물질 정보'][key][name]['checked'] && <FormTableTbodyTr key={name}>
                                        <FormTableTd><span className={`text-xl`}>{appContext.appData['문헌조사']['오염물질 정보'][key][name]['물질명']}</span></FormTableTd>
                                        <FormTableTd>{appContext.appData['문헌조사']['오염물질 정보'][key][name]['신뢰값']}</FormTableTd>
                                        <FormTableTd>{appContext.appData['문헌조사']['오염물질 정보'][key][name]['국외기준']}</FormTableTd>
                                        <FormTableTd>
                                      <span className={appContext.appData['문헌조사']['오염물질 정보'][key][name]['판정'] === '계산전' ? 'text-black' : (appContext.appData['문헌조사']['오염물질 정보'][key][name]['판정'] === '초과' ? 'text-red-500' : 'text-blue-500')}>
                                        {appContext.appData['문헌조사']['오염물질 정보'][key][name]['판정']}
                                      </span>
                                        </FormTableTd>
                                      </FormTableTbodyTr>
                                    })
                                  })
                                }
                              </div>
                            </div>
                          </FormGroup>
                        </>
                      }
                    </FormWrap>
                    <div>
                      <div className={`h-[1px] bg-gray-400 my-5`}></div>
                    </div>
                  </div>
                </>
              }

              {
                체크_생태위해성평가필요성 &&
                <>
                  <h5 className={`font-bold pb-2.5`}>생태위해성평가 필요성</h5>
                  <div className={`px-2.5`}>
                    <FormWrap>
                      <FormGroup>
                        <div className="font-bold px-2.5">1.생태위해성평가가 필요한가?</div>
                      </FormGroup>
                      {
                        Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행']).filter(k => appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행'][k]).length > 0 &&
                        <>
                          <FormGroup>
                            <div className="font-bold px-4 text-xs">1.대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행</div>
                            <div className="px-4 text-xs font-normal">
                              <ul className={`pl-[30px]`}>
                                {
                                  Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행']).map((d, i) => {
                                    return appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행'][d] && <li key={i} className={`list-disc`}>{d}</li>;
                                  })
                                }
                              </ul>
                            </div>
                          </FormGroup>
                        </>
                      }
                      {
                        Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행']).filter(k => appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행'][k]).length > 0 &&
                        <>
                          <FormGroup>
                            <div className="font-bold px-4 text-xs">2.대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행</div>
                            <div className="px-4 text-xs font-normal">
                              <ul className={`pl-[30px]`}>
                                {
                                  Object.keys(appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행']).map((d, i) => {
                                    return appContext.appData['생태위해성평가 필요성']['생태위해성평가']['대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행'][d] && <li key={i} className={`list-disc`}>{d}</li>;
                                  })
                                }

                              </ul>
                            </div>
                          </FormGroup>
                        </>
                      }
                      <FormGroup>
                        <div className="font-bold px-4 text-xs">생태위해성평가가 필요한가?</div>
                        <div className="px-8 text-xs font-normal">
                          {appContext.appData['생태위해성평가 필요성']['생태위해성평가']['생태위해성평가가 필요한가?']}
                        </div>
                      </FormGroup>

                    </FormWrap>
                    <div>
                      <div className={`h-[1px] bg-gray-400 my-2.5`}></div>
                    </div>
                  </div>
                </>
              }

              {
                (appContext.appData['현장사전조사']['대상부지 기본정보']['조사지점'] ||
                  appContext.appData['현장사전조사']['대상부지 기본정보']['부지에 대한 접근성'] ||
                  appContext.appData['현장사전조사']['대상부지 기본정보']['조사 용이성'] ||
                  appContext.appData['현장사전조사']['대상부지 기본정보']['지표수 및 지하수 유무'] ||
                  appContext.appData['현장사전조사']['대상부지 기본정보']['통제 지역 존재 여부'] ||
                  appContext.appData['현장사전조사']['대상부지 기본정보']['주변 토지 및 수계에 대한 정보'] ||
                  appContext.appData['현장사전조사']['대상부지 기본정보']['기타 필요한 사항'])
                && (
                  appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 관속식물상'] ||
                  appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 무척추동물(곤충 등)'] ||
                  appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 양서파충류상'] ||
                  appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 조류상'] ||
                  appContext.appData['현장사전조사']['생태수용체 기본정보']['기타 필요한 사항']
                ) &&
                <>
                  <h5 className={`font-bold pb-2.5`}>현장사전조사</h5>
                  <div className={`px-2.5`}>
                    <FormWrap>
                      {
                        (appContext.appData['현장사전조사']['대상부지 기본정보']['조사지점'] ||
                        appContext.appData['현장사전조사']['대상부지 기본정보']['부지에 대한 접근성'] ||
                        appContext.appData['현장사전조사']['대상부지 기본정보']['조사 용이성'] ||
                        appContext.appData['현장사전조사']['대상부지 기본정보']['지표수 및 지하수 유무'] ||
                        appContext.appData['현장사전조사']['대상부지 기본정보']['통제 지역 존재 여부'] ||
                        appContext.appData['현장사전조사']['대상부지 기본정보']['주변 토지 및 수계에 대한 정보'] ||
                        appContext.appData['현장사전조사']['대상부지 기본정보']['기타 필요한 사항']) &&
                        <>
                          <FormGroup>
                            <div className="font-bold">1.생태위해성평가가 필요한가?</div>
                          </FormGroup>
                          {
                            appContext.appData['현장사전조사']['대상부지 기본정보']['조사지점'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>조사지점</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['대상부지 기본정보']['조사지점']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['대상부지 기본정보']['부지에 대한 접근성'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>부지에 대한 접근성</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['대상부지 기본정보']['부지에 대한 접근성']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['대상부지 기본정보']['조사 용이성'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>조사 용이성</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['대상부지 기본정보']['조사 용이성']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['대상부지 기본정보']['지표수 및 지하수 유무'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>지표수 및 지하수 유무</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['대상부지 기본정보']['지표수 및 지하수 유무']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['대상부지 기본정보']['통제 지역 존재 여부'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>통제 지역 존재 여부</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['대상부지 기본정보']['통제 지역 존재 여부']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['대상부지 기본정보']['주변 토지 및 수계에 대한 정보'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>주변 토지 및 수계에 대한 정보</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['대상부지 기본정보']['주변 토지 및 수계에 대한 정보']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['대상부지 기본정보']['기타 필요한 사항'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>기타 필요한 사항</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['대상부지 기본정보']['기타 필요한 사항']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                        </>
                      }

                      {
                        (
                          appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 관속식물상'] ||
                          appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 무척추동물(곤충 등)'] ||
                          appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 양서파충류상'] ||
                          appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 조류상'] ||
                          appContext.appData['현장사전조사']['생태수용체 기본정보']['기타 필요한 사항']
                        ) &&
                        <>
                          <FormGroup>
                            <div className="font-bold">2.생태수용체 기본정보</div>
                          </FormGroup>
                          {
                            appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 관속식물상'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>주요 관속식물상</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 관속식물상']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 무척추동물(곤충 등)'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>주요 무척추동물(곤충 등)</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 무척추동물(곤충 등)']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 양서파충류상'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>주요 양서파충류상</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 양서파충류상']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 조류상'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>주요 조류상</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 조류상']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 포유류상'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>주요 포유류상</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 포유류상']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 어류상'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>주요 어류상</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['생태수용체 기본정보']['주요 어류상']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                          {
                            appContext.appData['현장사전조사']['생태수용체 기본정보']['기타 필요한 사항'] &&
                            <>
                              <FormGroup>
                                <div className={`px-2.5 text-xs`}>
                                  <div className={`px-2.5 font-bold`}>기타 필요한 사항</div>
                                  <div className={`px-4`}>
                                    <pre>{appContext.appData['현장사전조사']['생태수용체 기본정보']['기타 필요한 사항']}</pre>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          }
                        </>
                      }
                      <div>
                        <div className={`h-[1px] bg-gray-400 my-2.5`}></div>
                      </div>
                    </FormWrap>
                  </div>
                </>
              }


              <h5 className={`font-bold pb-2.5`}>평가범위 결정 및 현장조사 계획수립</h5>
              <div className={`px-2.5`}>
                <FormWrap>
                  {
                    (appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수'] > 0) &&
                    <>
                    <FormGroup>
                      <div className="font-bold">1.평가범위 결정</div>
                    </FormGroup>
                      <FormGroup>
                        <div className={`px-2.5 text-xs`}>
                          <div className={`px-2.5 font-bold`}>대상부지 토양정밀조사 결과가 있는가?</div>
                          <div className={`px-4`}>[Yes] n1(토양정밀 시료개수) = {appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수']}</div>
                        </div>
                      </FormGroup>
                    </>
                  }

                  {
                    appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'YES' &&
                    <>
                    {
                      체크_변동계수산정기존조사결과있을때 > 0 &&
                      <>
                        <FormGroup>
                          <div className="font-bold">2.변동계수 산정-기존조사 결과 있을 때</div>
                        </FormGroup>
                        {
                          appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['추가 시료 개수'] &&
                          <FormGroup>
                            <div className={`px-2.5 text-xs`}>
                              <div className={`px-2.5 font-bold`}>추가 시료채취 필요한가?</div>
                              <div className={`px-4`}>[Yes] 추가 시료개수 : {appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['추가 시료 개수']}</div>
                            </div>
                          </FormGroup>
                        }
                        <FormGroup>
                          <div className={`bg-white text-sm`}>
                            <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                              <div className={`w-[80px] mr-2.5`}>시료명</div>
                              <div className={`w-[200px] flex flex-col border-r`}>
                                <div className={`flex border-b border-[#CDCDCD]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                                </div>
                                <div className={`flex pb-[15px]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                                </div>
                              </div>
                              <div className={`flex-1 overflow-hidden  text-center`}>
                                <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                                <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            {
                              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
                                return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                  <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                  <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                      <div className={`flex-1 gap-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>
                                          {o['위도']}
                                        </div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경사']}</div>
                                      </div>
                                    </div>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경도']}</div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['고도']}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                    <div className="flex gap-1 pl-[8px] py-[5px]">
                                      {
                                        pollutants.map(pollutant => {
                                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                              <div key={key}><div className={`w-[100px] min-h-[30px] break-words break-all`}>{isNaN(o[key]) ? 0 : Number(o[key]).toFixed(3)}</div></div>
                                          })
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              })
                            }
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>평균(m)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['평균'][key] === '' ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['평균'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>표준편차(s)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['표준편차'][key] === '' ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['표준편차'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>변동계수(CV)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['변동계수'][key] === '' ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['변동계수'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>토양정밀조사 시료 개수(n1)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">{appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수']}</div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>기준치민감도(D)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['기준치민감도'][key] === '' ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['기준치민감도'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>통계학적 시료개수(n2)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['통계학적 시료개수'][key] === '' ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['통계학적 시료개수'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>적정 시료 개수</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  <div className={`w-full`}>
                                    <div className="min-h-[30px] break-words break-all w-full flex items-center justify-center">
                                      {appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['적정 시료 개수'] === false ? 'n2중 최대값의 30%, 예 87*0.3 = 26': appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['적정 시료 개수']}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>추가 시료 개수</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  <div className={`w-full`}>
                                    <div className="min-h-[30px] break-words break-all w-full flex items-center justify-center">
                                      {appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['추가 시료 개수'] === false ? 'n1 – 적정시료 개수, 0 (if n1 > 적정시료개수)' : appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['추가 시료 개수']}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <div className={`font-bold text-xs mt-8`}>그래프 시각화</div>
                        </FormGroup>
                        <FormGroup>
                          <GraphGridWrap>
                            {
                              Object.keys(변동계수산정기존조사결과있을때_그래프배열).map((중금속명, 순번) => {
                                return <div key={순번} className="bg-white p-3">
                                  <div className="mb-2 text-xs">{중금속명}</div>
                                  <Bar
                                    options={options}
                                    data={{
                                      labels: 변동계수산정기존조사결과있을때_그래프배열[중금속명].map(o => o['시료명']),
                                      datasets: [
                                        {
                                          data: 변동계수산정기존조사결과있을때_그래프배열[중금속명].map(o => o['data']),
                                          backgroundColor: '#82ca9d',
                                        },
                                      ],
                                    }}
                                  />
                                </div>
                              })
                            }
                          </GraphGridWrap>
                        </FormGroup>
                      </>
                    }
                    {
                      체크_변동계수산정추가조사후입력 > 0 &&
                      <>
                        <FormGroup>
                          <h5 className={`font-bold`}>2-1.변동계수 산정-추가조사 후 입력</h5>
                        </FormGroup>
                        <FormGroup>
                          <div className={`bg-white text-sm`}>
                            <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                              <div className={`w-[80px] mr-2.5`}>시료명</div>
                              <div className={`w-[200px] flex flex-col border-r`}>
                                <div className={`flex border-b border-[#CDCDCD]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                                </div>
                                <div className={`flex pb-[15px]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                                </div>
                              </div>
                              <div className={`flex-1 overflow-hidden  text-center`}>
                                <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                                <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>

                            {
                              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => {
                                return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                  <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                  <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                      <div className={`flex-1 gap-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>
                                          {o['위도']}
                                        </div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경사']}</div>
                                      </div>
                                    </div>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경도']}</div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['고도']}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                    <div className="flex gap-1 pl-[8px] py-[5px]">
                                      {
                                        pollutants.map(pollutant => {
                                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                              <div className={``} key={key}>
                                                <div className={`w-[100px] min-h-[30px] break-words break-all`}>{o[key]}</div>
                                              </div>
                                          })
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              })
                            }

                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>평균(m)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['평균'][key] === false ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['평균'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>표준편차(s)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['표준편차'][key] === false ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['표준편차'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <div className={`font-bold text-xs mt-8`}>그래프 시각화</div>
                        </FormGroup>
                        <FormGroup>
                          <GraphGridWrap>
                            {
                              Object.keys(변동계수산정추가조사후입력_그래프배열).map((중금속명, 순번) => {
                                return <div key={순번} className="bg-white p-3">
                                  <div className="mb-2 text-xs">{중금속명}</div>
                                  <Bar
                                    options={options}
                                    data={{
                                      labels: 변동계수산정추가조사후입력_그래프배열[중금속명].map(o => o['시료명']),
                                      datasets: [
                                        {
                                          data: 변동계수산정추가조사후입력_그래프배열[중금속명].map(o => o['data']),
                                          backgroundColor: '#82ca9d',
                                        },
                                      ],
                                    }}
                                  />
                                </div>
                              })
                            }
                          </GraphGridWrap>
                        </FormGroup>
                      </>
                    }
                    {
                      (체크_변동계수산정기존조사결과있을때 > 0 || 체크_변동계수산정추가조사후입력 > 0) &&
                      <>
                        <FormGroup>
                          <h5 className={`font-bold`}>2-2.변동계수 산정-기존조사+추가조사 결과 입력 페이지</h5>
                        </FormGroup>
                        <FormGroup>
                          <div className={`bg-white text-sm`}>
                            <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                              <div className={`w-[80px] mr-2.5`}>시료명</div>
                              <div className={`w-[200px] flex flex-col border-r`}>
                                <div className={`flex border-b border-[#CDCDCD]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                                </div>
                                <div className={`flex pb-[15px]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                                </div>
                              </div>
                              <div className={`flex-1 overflow-hidden  text-center`}>
                                <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                                <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>

                            {
                              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
                                return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                  <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                  <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                      <div className={`flex-1 gap-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>
                                          {o['위도']}
                                        </div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경사']}</div>
                                      </div>
                                    </div>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경도']}</div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['고도']}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                    <div className="flex gap-1 pl-[8px] py-[5px]">
                                      {
                                        pollutants.map(pollutant => {
                                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                              <div className={``} key={key}>
                                                <div className={`w-[100px] min-h-[30px] break-words break-all`}>{isNaN(o[key]) ? 0 : Number(o[key]).toFixed(3)}</div>
                                              </div>
                                          })
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              })
                            }
                            {
                              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => {
                                return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                  <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                  <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                      <div className={`flex-1 gap-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>
                                          {o['위도']}
                                        </div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경사']}</div>
                                      </div>
                                    </div>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경도']}</div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['고도']}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                    <div className="flex gap-1 pl-[8px] py-[5px]">
                                      {
                                        pollutants.map(pollutant => {
                                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                              <div className={``} key={key}>
                                                <div className={`w-[100px] min-h-[30px] break-words break-all`}>{isNaN(o[key]) ? 0 : Number(o[key]).toFixed(3)}</div>
                                              </div>
                                          })
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              })
                            }


                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>평균(m)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력']['평균'][key] === '' ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력']['평균'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>표준편차(s)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력']['표준편차'][key] === '' ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력']['표준편차'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <div className={`font-bold text-xs mt-8`}>그래프 시각화</div>
                        </FormGroup>
                        <FormGroup>
                          <GraphGridWrap>
                            {
                              Object.keys(변동계수산정기존조사추가조사결과입력_그래프배열).map((중금속명, 순번) => {
                                return <div key={순번} className="bg-white p-3">
                                  <div className="mb-2 text-xs">{중금속명}</div>
                                  <Bar
                                    options={options}
                                    data={{
                                      labels: 변동계수산정기존조사추가조사결과입력_그래프배열[중금속명].map(o => o['시료명']),
                                      datasets: [
                                        {
                                          data: 변동계수산정기존조사추가조사결과입력_그래프배열[중금속명].map(o => o['data']),
                                          backgroundColor: '#82ca9d',
                                        },
                                      ],
                                    }}
                                  />
                                </div>
                              })
                            }
                          </GraphGridWrap>
                        </FormGroup>
                      </>
                    }
                    </>
                  }

                  {
                    appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === "NO" &&
                    <>
                    {
                      체크_변동계수산정정밀조사실시 > 0 &&
                      <>
                        <FormGroup>
                          <h5 className={`font-bold`}>3.변동계수 산정-정밀조사 실시</h5>
                        </FormGroup>
                        <FormGroup>
                          <div className={`bg-white text-sm`}>
                            <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                              <div className={`w-[80px] mr-2.5`}>시료명</div>
                              <div className={`w-[200px] flex flex-col border-r`}>
                                <div className={`flex border-b border-[#CDCDCD]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                                </div>
                                <div className={`flex pb-[15px]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                                </div>
                              </div>
                              <div className={`flex-1 overflow-hidden  text-center`}>
                                <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                                <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>

                            {
                              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
                                return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                  <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                  <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                      <div className={`flex-1 gap-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>
                                          {o['위도']}
                                        </div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경사']}</div>
                                      </div>
                                    </div>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경도']}</div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['고도']}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                    <div className="flex gap-1 pl-[8px] py-[5px]">
                                      {
                                        pollutants.map(pollutant => {
                                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                              <div className={``} key={key}>
                                                <div className={`w-[100px] min-h-[30px] break-words break-all`}>{o[key]}</div>
                                              </div>
                                          })
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              })
                            }


                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>평균(m)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['평균'][key] === false ?
                                                  '계산전' :
                                                  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['평균'][key]
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>표준편차(s)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['표준편차'][key] === false ?
                                                  '계산전' :
                                                  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['표준편차'][key]
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>변동계수(CV)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['변동계수'][key] === false ?
                                                  '계산전' :
                                                  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['변동계수'][key]
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>토양정밀조사 시료 개수(n1)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">{appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수']}</div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>기준치민감도(D)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['기준치민감도'][key] === false ?
                                                  '계산전' :
                                                  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['기준치민감도'][key]
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>통계학적 시료개수(n2)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['통계학적 시료개수'][key] === false ?
                                                  '계산전' :
                                                  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['통계학적 시료개수'][key]
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>적정 시료 개수</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  <div className={`w-full`}>
                                    <div className="min-h-[30px] break-words break-all w-full flex items-center justify-center">
                                      {appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['적정 시료 개수'] || 'n2중 최대값의 30%, 예 87*0.3 = 26'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>추가 시료 개수</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  <div className={`w-full`}>
                                    <div className="min-h-[30px] break-words break-all w-full flex items-center justify-center">
                                      {appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['추가 시료 개수'] === false ? 'n1 – 적정시료 개수, 0 (if n1 > 적정시료개수)': appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['추가 시료 개수']}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <div className={`font-bold text-xs mt-8`}>그래프 시각화</div>
                        </FormGroup>
                        <FormGroup>
                          <GraphGridWrap>
                            {
                              Object.keys(변동계수산정정밀조사실시_그래프배열).map((중금속명, 순번) => {
                                return <div key={순번} className="bg-white p-3">
                                  <div className="mb-2 text-xs">{중금속명}</div>
                                  <Bar
                                    options={options}
                                    data={{
                                      labels: 변동계수산정정밀조사실시_그래프배열[중금속명].map(o => o['시료명']),
                                      datasets: [
                                        {
                                          data: 변동계수산정정밀조사실시_그래프배열[중금속명].map(o => o['data']),
                                          backgroundColor: '#82ca9d',
                                        },
                                      ]
                                  }}
                                  />
                                </div>
                              })
                            }
                          </GraphGridWrap>
                        </FormGroup>
                      </>
                    }
                    {
                      체크_변동계수산정정밀조사추가조사후입력 > 0 &&
                      <>
                        <FormGroup>
                          <h5 className={`font-bold`}>3-1.변동계수 산정-정밀조사 추가조사 후 입력</h5>
                        </FormGroup>
                        <FormGroup>
                          <div className={`bg-white text-sm`}>
                            <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                              <div className={`w-[80px] mr-2.5`}>시료명</div>
                              <div className={`w-[200px] flex flex-col border-r`}>
                                <div className={`flex border-b border-[#CDCDCD]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                                </div>
                                <div className={`flex pb-[15px]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                                </div>
                              </div>
                              <div className={`flex-1 overflow-hidden  text-center`}>
                                <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                                <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>

                            {
                              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
                                return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                  <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                  <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                      <div className={`flex-1 gap-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>
                                          {o['위도']}
                                        </div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경사']}</div>
                                      </div>
                                    </div>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경도']}</div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['고도']}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                    <div className="flex gap-1 pl-[8px] py-[5px]">
                                      {
                                        pollutants.map(pollutant => {
                                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                              <div className={``} key={key}>
                                                <div className={`w-[100px] min-h-[30px] break-words break-all`}>{o[key]}</div>
                                              </div>
                                          })
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              })
                            }


                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>평균(m)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['평균'][key] === '' ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['평균'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>표준편차(s)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['표준편차'][key] === '' ?
                                                  '계산전' :
                                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['표준편차'][key]).toFixed(3)
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <div className={`font-bold text-xs mt-8`}>그래프 시각화</div>
                        </FormGroup>
                        <FormGroup>
                          <GraphGridWrap>
                            {
                              Object.keys(변동계수산정정밀조사추가조사후입력_그래프배열).map((중금속명, 순번) => {
                                return <div key={순번} className="bg-white p-3">
                                  <div className="mb-2 text-xs">{중금속명}</div>
                                  <Bar
                                    options={options}
                                    data={{
                                      labels: 변동계수산정정밀조사추가조사후입력_그래프배열[중금속명].map(o => o['시료명']),
                                      datasets: [
                                        {
                                          data: 변동계수산정정밀조사추가조사후입력_그래프배열[중금속명].map(o => o['data']),
                                          backgroundColor: '#82ca9d',
                                        },
                                      ],
                                    }}
                                  />
                                </div>
                              })
                            }
                          </GraphGridWrap>
                        </FormGroup>
                      </>
                    }
                    {
                      (체크_변동계수산정정밀조사실시 > 0 || 체크_변동계수산정정밀조사추가조사후입력 > 0) &&
                      <>
                        <FormGroup>
                          <h5 className={`font-bold`}>3-2.변동계수 산정-기존조사+추가조사 결과 입력 페이지</h5>
                        </FormGroup>
                        <FormGroup>
                          <div className={`bg-white text-sm`}>
                            <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                              <div className={`w-[80px] mr-2.5`}>시료명</div>
                              <div className={`w-[200px] flex flex-col border-r`}>
                                <div className={`flex border-b border-[#CDCDCD]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                                </div>
                                <div className={`flex pb-[15px]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                                </div>
                              </div>
                              <div className={`flex-1 overflow-hidden  text-center`}>
                                <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                                <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>

                            {
                              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
                                return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                  <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                  <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                      <div className={`flex-1 gap-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>
                                          {o['위도']}
                                        </div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경사']}</div>
                                      </div>
                                    </div>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경도']}</div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['고도']}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                    <div className="flex gap-1 pl-[8px] py-[5px]">
                                      {
                                        pollutants.map(pollutant => {
                                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                              <div className={``} key={key}>
                                                <div className={`w-[100px] min-h-[30px] break-words break-all`}>{o[key]}</div>
                                              </div>
                                          })
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              })
                            }

                            {
                              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
                                return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                  <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                  <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                      <div className={`flex-1 gap-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>
                                          {o['위도']}
                                        </div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경사']}</div>
                                      </div>
                                    </div>
                                    <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['경도']}</div>
                                      </div>
                                      <div className={`flex-1`}>
                                        <div className={`w-[92px] h-[30px] px-1`}>{o['고도']}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                    <div className="flex gap-1 pl-[8px] py-[5px]">
                                      {
                                        pollutants.map(pollutant => {
                                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                              <div className={``} key={key}>
                                                <div className={`w-[100px] min-h-[30px] break-words break-all`}>{o[key]}</div>
                                              </div>
                                          })
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              })
                            }


                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>평균(m)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['평균'][key] === false ?
                                                  '계산전' :
                                                  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['평균'][key]
                                              }
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                            <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                              <div className={`flex-1 text-center h-[30px] flex items-center justify-center`}>표준편차(s)</div>
                              <div className={`flex-[527px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                <div className="flex gap-1 py-[5px] pl-[8px]">
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                          <div className={``} key={key}>
                                            <div className="min-h-[30px] break-words break-all w-[100px] flex items-center justify-center">
                                              {appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['표준편차'][key] === false ?
                                                '계산전' :
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['표준편차'][key]}
                                            </div>
                                          </div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <div className={`font-bold text-xs mt-8`}>그래프 시각화</div>
                        </FormGroup>
                        <FormGroup>
                          <GraphGridWrap>
                            {
                              Object.keys(변동계수산정정밀조사추가조사후입력결과_그래프배열).map((중금속명, 순번) => {
                                return <div key={순번} className="bg-white p-3">
                                  <div className="mb-2 text-xs">{중금속명}</div>
                                  <Bar
                                    options={options}
                                    data={{
                                      labels: 변동계수산정정밀조사추가조사후입력결과_그래프배열[중금속명].map(o => o['시료명']),
                                      datasets: [
                                        {
                                          data: 변동계수산정정밀조사추가조사후입력결과_그래프배열[중금속명].map(o => o['data']),
                                          backgroundColor: '#82ca9d',
                                        },
                                      ],
                                    }}
                                  />
                                </div>
                              })
                            }
                          </GraphGridWrap>
                        </FormGroup>
                      </>
                    }
                    </>
                  }

                  {
                    mapUrl && <>
                      <FormGroup>
                        <h5 className={`font-bold`}>4.평가단위 결정</h5>
                      </FormGroup>
                      <FormGroup>
                        <iframe src={mapUrl} className={`aspect-[4/3]`}></iframe>
                      </FormGroup>
                    </>
                  }

                  {
                    (체크_대조구역정보 > 0) &&
                    <>
                      <FormGroup>
                        <h5 className={`font-bold`}>5.대조구역 정보 입력</h5>
                      </FormGroup>
                      <FormGroup>
                        <div className={`bg-white text-sm`}>
                          <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                            <div className={`w-[80px] mr-2.5`}>시료명</div>
                            <div className={`w-[200px] flex flex-col`}>
                              <div className={`flex border-b border-[#CDCDCD]`}>
                                <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                                <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                              </div>
                              <div className={`flex pb-[15px]`}>
                                <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                                <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                              </div>
                            </div>
                            <div className={`flex-1 overflow-hidden  text-center border-l border-[#CDCDCD]`}>
                              <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                              <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                                {
                                  pollutants.map(pollutant => {
                                    return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['오염물질'][pollutant]).map(key => {
                                      return appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                    })
                                  })
                                }
                              </div>
                            </div>
                          </div>

                          {
                            appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['시료'].map(o => {
                              return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                <div className={`w-[80px] mr-2.5`}>
                                  <div className={`p-2.5 h-[80px] w-[80px]`}>{o['시료명']}</div>
                                </div>
                                <div className={`w-[200px] flex flex-col`}>
                                  <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                    <div className={`flex-1 gap-1`}>
                                      <div className={`w-[92px] h-[30px] px-1`}>{o['위도']}</div>
                                    </div>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[30px] px-1`}>{o['경사']}</div>
                                    </div>
                                  </div>
                                  <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[30px] px-1`}>{o['경도']}</div>
                                    </div>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[30px] px-1`}>{o['고도']}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`flex-1 w-[525px] min-h-[93px] flex items-center overflow-hidden overflow-x-auto scrollbar-hide text-center border-l border-[#CDCDCD] scs`} onScroll={e => onScroll(e)}>
                                  {
                                    o['토양농도'].map((토양농도, 순번) => {
                                      return <div className="flex gap-1 py-[5px]" key={순번}>
                                        {
                                          pollutants.map(pollutant => {
                                            return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['오염물질'][pollutant]).map(key => {
                                              return appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['오염물질'][pollutant][key] === true &&
                                                <div className={``} key={key}>
                                                  <div className={`w-[100px] h-[30px] px-1`}>{토양농도[key]}</div>
                                                </div>
                                            })
                                          })
                                        }
                                      </div>
                                    })
                                  }
                                </div>
                              </div>
                            })
                          }

                          <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                            <div className={`flex-1 text-center`}>평균(m)</div>
                            <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                              <div className="flex gap-1 py-[5px] pl-[8px]">
                                {
                                  pollutants.map(pollutant => {
                                    return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['오염물질'][pollutant]).map(key => {
                                      return appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['오염물질'][pollutant][key] === true &&
                                        <div className={``} key={key}>
                                          <div className={`w-[100px] h-[30px] px-1`}>
                                            {
                                              appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['평균'][key] === false ?
                                                '계산전' :
                                                appContext.appData['평가범위 결정 및 현장조사 계획수립']['대조구역 정보 입력']['평균'][key]
                                            }
                                          </div>
                                        </div>
                                    })
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        {
                          (appContext.appData['대조구역 지점 표기 이미지'] && appContext.appData['대조구역 지점 표기 이미지']['image']) &&
                            <div>
                              <KerasImage
                                image={appContext.appData['대조구역 지점 표기 이미지']['image']}
                                cls={`w-full block aspect-[808/490] object-cover cursor-pointer`}
                                alt={`대조구역 지점 표기 이미지`}
                              />
                            </div>
                        }
                      </FormGroup>
                    </>
                  }
                </FormWrap>
              </div>


            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

