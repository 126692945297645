import React from 'react';
import Integrated from "components/Integrated";

const IntegratedPage = ({}) => {
  return (
    <Integrated />
  );
};

export default IntegratedPage;
