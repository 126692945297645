import React, {useContext} from 'react';
import Side from "components/Side";
import {CheckboxLabel, CheckboxWrap, FormGroup, FormTableTbodyTr, FormTableTd, FormTableTh, FormTableTheadTr, FormWrap} from "../../constants/FormConstants";
import {GetMenuUrl} from "constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import {
  LayoutBox,
  LayoutBoxHeader,
  LayoutBoxHeaderH2,
  LayoutBoxHeaderRight,
  LayoutContentsHeader,
  LayoutContentsInner,
  LayoutContentsWrap,
  LayoutSubTitle
} from "../../constants/LayoutCons";
import AppContext from "../../contexts/AppContext";
import * as lodash from "lodash";


const Step3 = ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const keyArray = ['유기물질', '중금속', '기타'];
  const standard = {'유기인': 20, '폴리클로리네이티드비페닐': 40, '페놀':22, '벤젠': 32, '톨루엔': 0.1, '에틸벤젠': 0.5, '크실렌': 9, '석유계총탄화수소': 1,
    '트리클로로에틸렌': 2.3, '테트라클로로에틸렌': 3.4, '벤조(a)피렌': 4, 'Cd': 4, 'Cu': 150, 'As': 25, 'Hg': 4, 'Pb': 25, 'Cr6+': 0.4, 'Zn': 350, 'Ni': 150, 'F': 250, '시안화합물': 0.7};

  const onChange = (e, key) => {
    const data = lodash.merge({}, appContext.appData, {
      '문헌조사': {
        '오염물질 정보': {
          [key]: Object.assign({}, appContext.appData['문헌조사']['오염물질 정보'][key], {[e.target.value]: {
              'checked': e.target.checked,
              '물질명': e.target.value,
              '신뢰값': '',
              '국외기준': '',
              '판정': '계산전',
            }
          })
        }
      }
    });
    appContext.setAppData(data);
  }

  /*
  key: 유기물질, 중금속, 기타
  name: 물질명
  field: 신뢰값, 국외기준
   */
  const onTextChange = (e, key, name, field) => {
    const copy = Object.assign({}, appContext.appData);

    copy['문헌조사']['오염물질 정보'][key][name][field] = e.target.value; // .replace(/[^-\.0-9]/g,'');
    if( ! e.target.value || isNaN(e.target.value)) {
      copy['문헌조사']['오염물질 정보'][key][name]['판정'] = '계산전';
    } else {
      try {
        if(copy['문헌조사']['오염물질 정보'][key][name]['신뢰값'] && copy['문헌조사']['오염물질 정보'][key][name]['국외기준']) {
          if(copy['문헌조사']['오염물질 정보'][key][name]['신뢰값'] <= copy['문헌조사']['오염물질 정보'][key][name]['국외기준']) {
            copy['문헌조사']['오염물질 정보'][key][name]['판정'] = '미달';
          } else {
            copy['문헌조사']['오염물질 정보'][key][name]['판정'] = '초과';
          }
        }
      } catch (e) {
        copy['문헌조사']['오염물질 정보'][key][name]['판정'] = '계산전';
      }
    }
    appContext.setAppData(copy);
  }

  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <div className={`text-xl mb-2.5`}>기 조사오염농도와 국외 생태보호 기준치 비교 결과</div>
              <FormWrap>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 1.유기물질</LayoutSubTitle>
                  <CheckboxWrap>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`유기인`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['유기인']['checked']}/> 유기인
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`폴리클로리네이티드비페닐`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['폴리클로리네이티드비페닐']['checked']}/> 폴리클로리네이티드비페닐
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`페놀`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['페놀']['checked']}/> 페놀
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`벤젠`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['벤젠']['checked']}/> 벤젠
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`톨루엔`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['톨루엔']['checked']}/> 톨루엔
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`에틸벤젠`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['에틸벤젠']['checked']}/> 에틸벤젠
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`크실렌`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['크실렌']['checked']}/> 크실렌
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`석유계총탄화수소`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['석유계총탄화수소']['checked']}/> 석유계총탄화수소
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`트리클로로에틸렌`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['트리클로로에틸렌']['checked']}/> 트리클로로에틸렌
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`테트라클로로에틸렌`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['테트라클로로에틸렌']['checked']}/> 테트라클로로에틸렌
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" name={'유기물질'} value={`벤조(a)피렌`} onChange={e => onChange(e, '유기물질')} checked={appContext.appData['문헌조사']['오염물질 정보']['유기물질']['벤조(a)피렌']['checked']}/> 벤조(a)피렌
                    </CheckboxLabel>
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 2.중금속</LayoutSubTitle>
                  <CheckboxWrap>
                    <CheckboxLabel>
                      <input type="checkbox" value={`Cd`} name={'중금속'} onChange={e => onChange(e, '중금속')} checked={appContext.appData['문헌조사']['오염물질 정보']['중금속']['Cd']['checked']}/> Cd
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" value={`Cu`} name={'중금속'} onChange={e => onChange(e, '중금속')} checked={appContext.appData['문헌조사']['오염물질 정보']['중금속']['Cu']['checked']}/> Cu
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" value={`As`} name={'중금속'} onChange={e => onChange(e, '중금속')} checked={appContext.appData['문헌조사']['오염물질 정보']['중금속']['As']['checked']}/> As
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" value={`Hg`} name={'중금속'} onChange={e => onChange(e, '중금속')} checked={appContext.appData['문헌조사']['오염물질 정보']['중금속']['Hg']['checked']}/> Hg
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" value={`Pb`} name={'중금속'} onChange={e => onChange(e, '중금속')} checked={appContext.appData['문헌조사']['오염물질 정보']['중금속']['Pb']['checked']}/> Pb
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" value={`Cr6+`} name={'중금속'} onChange={e => onChange(e, '중금속')} checked={appContext.appData['문헌조사']['오염물질 정보']['중금속']['Cr6+']['checked']}/> Cr6+
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" value={`Zn`} name={'중금속'} onChange={e => onChange(e, '중금속')} checked={appContext.appData['문헌조사']['오염물질 정보']['중금속']['Zn']['checked']}/> Zn
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" value={`Ni`} name={'중금속'} onChange={e => onChange(e, '중금속')} checked={appContext.appData['문헌조사']['오염물질 정보']['중금속']['Ni']['checked']}/> Ni
                    </CheckboxLabel>
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 3.기타</LayoutSubTitle>
                  <CheckboxWrap>
                    <CheckboxLabel>
                      <input type="checkbox" value={`F`} name={'기타'} onChange={e => onChange(e, '기타')} checked={appContext.appData['문헌조사']['오염물질 정보']['기타']['F']['checked']}/> F
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <input type="checkbox" value={`시안화합물`} name={'기타'} onChange={e => onChange(e, '기타')} checked={appContext.appData['문헌조사']['오염물질 정보']['기타']['시안화합물']['checked']}/> 시안화합물
                    </CheckboxLabel>
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <div className="flex gap-2.5">
                    <div className={`flex-1 bg-white`}>
                      <FormTableTheadTr>
                        <FormTableTh>오염물질</FormTableTh>
                        <FormTableTh>95% 상위 신뢰값 (mg/kg)</FormTableTh>
                        <FormTableTh>국외기준</FormTableTh>
                        <FormTableTh>판정</FormTableTh>
                      </FormTableTheadTr>
                      {
                        keyArray.map(key => {
                          return Object.keys(appContext.appData['문헌조사']['오염물질 정보'][key]).map(name => {
                            return appContext.appData['문헌조사']['오염물질 정보'][key][name]['checked'] && <FormTableTbodyTr key={name}>
                              <FormTableTd><span className={`text-xl`}>{appContext.appData['문헌조사']['오염물질 정보'][key][name]['물질명']}</span></FormTableTd>
                              <FormTableTd>
                                <input
                                  type="text"
                                  className={`w-full border border-[#CDCDCD] rounded-[5px] h-[30px] p-2`}
                                  value={appContext.appData['문헌조사']['오염물질 정보'][key][name]['신뢰값']}
                                  onChange={(e) => {
                                    onTextChange(e,key, appContext.appData['문헌조사']['오염물질 정보'][key][name]['물질명'], '신뢰값')
                                  }}
                                />
                              </FormTableTd>
                              <FormTableTd>
                                <input
                                  type="text"
                                  className={`w-full border border-[#CDCDCD] rounded-[5px] h-[30px] p-2`}
                                  value={appContext.appData['문헌조사']['오염물질 정보'][key][name]['국외기준']}
                                  onChange={(e) => {
                                    onTextChange(e,key, appContext.appData['문헌조사']['오염물질 정보'][key][name]['물질명'], '국외기준')
                                  }}
                                />
                              </FormTableTd>
                              <FormTableTd>
                              <span className={appContext.appData['문헌조사']['오염물질 정보'][key][name]['판정'] === '계산전' ? 'text-black' : (appContext.appData['문헌조사']['오염물질 정보'][key][name]['판정'] === '초과' ? 'text-[#FF0000]' : 'text-[#0000FF]')}>
                                {appContext.appData['문헌조사']['오염물질 정보'][key][name]['판정']}
                              </span>
                              </FormTableTd>
                            </FormTableTbodyTr>
                          })
                        })
                      }
                    </div>
                    <div className={`w-[181px] bg-white rounded border border-[#CDCDCD] p-2.5`}>
                      <h5 className={`text-sm font-bold`}>국외기준</h5>
                      <ul className={`pl-5`}>
                        {
                          Object.keys(standard).map(key => {
                            return <li className={`list-disc text-sm`} key={key}>{key} - {standard[key]}</li>
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </FormGroup>
              </FormWrap>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

export default Step3;
