import React, {useContext} from 'react';
import Side from "components/Side";
import {GetMenuUrl} from "constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import {LayoutBox, LayoutBoxHeader, LayoutBoxHeaderH2, LayoutBoxHeaderRight, LayoutContentsHeader, LayoutContentsInner, LayoutContentsWrap} from "../../constants/LayoutCons";
import AppContext from "../../contexts/AppContext";
import * as lodash from "lodash";
import Toast from "../../util/Toast";
import Decimal from "decimal.js";

export default  ({}) => {


  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const pollutants = ['유기물질', '중금속', '기타'];

  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }

  const checkPlaces = (value, flagPlace) => {
    if(typeof value !== 'string') {
      value = '' + value;
    }
    if(value.toString().indexOf('.') === -1) {
      return true;
    }
    return flagPlace >= value.split('.')[1].length;
  }

  const fixFloat = (value, place) => {
    const f = Math.pow(10, place);
    if(isNaN(parseFloat(value))) {
      return 0;
    }
    return checkPlaces(value, place) ?  value : parseFloat(Math.ceil(value * f) / f).toFixed(place);
  }

  const onChangeText = (name, key, field, value) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][name]['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립'][name]['시료'].map(o =>
      o.key === key ? {...o, [field]: value} : o
    );
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const onChangeChecked = (name, key, checked) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][name]['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립'][name]['시료'].map(o =>
      o.key === key ? {...o, 'checked': checked} : o
    );
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const calc = () => {
    try {
      // 평균
      pollutants.map(pollutant => {
        Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(v => {
          if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][v] === true) {
            let total = 0, length = 0;
            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
              if(parseFloat(o[v]) && o[v] > 0) {
                ++length;
                total += parseFloat(o[v]);
              }
            });
            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
              if(parseFloat(o[v]) && o[v] > 0) {
                ++length;
                total += parseFloat(o[v]);
              }
            });
            try {
              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['평균'][v] = isNaN(new Decimal(total).div(length).toNumber()) ? 0 : new Decimal(total).div(length).toNumber();
            } catch (e) {
              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['평균'][v] = 0;
            }
          }
        })
      })

      // 표준 편차
      pollutants.map(pollutant => {
        Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(v => {
          if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][v] === true) {
            let total = 0, length = 0;
            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
              if(parseFloat(o[v]) && o[v] > 0) {
                ++length;
                let value = parseFloat(o[v]) - appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['평균'][v];
                total += parseFloat((Math.pow(value, 2)));
              }
            });
            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
              if(parseFloat(o[v]) && o[v] > 0) {
                ++length;
                let value = parseFloat(o[v]) - appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['평균'][v];
                total += parseFloat((Math.pow(value, 2)));
              }
            });
            let result = isNaN(Math.sqrt(new Decimal(total).div(length - 1))) ? 0 : Math.sqrt(new Decimal(total).div(length - 1));
            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['표준편차'][v] = parseFloat(result);
          }
        })
      })

      appContext.setAppData(lodash.merge({}, appContext.appData));
    } catch (e) {
      console.log(e);
      Toast.error('계산 실패!');
    }
  }

  const allCheck = (checked) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => {
      return {...o, checked: checked}
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }


  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <div className={`bg-white text-sm`}>
                <div className="sticky top-0 bg-white">
                  <div className={`flex justify-between py-[6px] px-[20px] border-t border-black`}>
                    <div className="flex gap-[5px]"></div>
                    <button
                      type={'button'}
                      className={`font-normal bg-[#60448D] w-[94px] h-[37px] rounded-[5px] text-[16px] text-white`}
                      onClick={calc}
                    >
                      계산
                    </button>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                    <div className={`mr-2.5`}>
                      <input type="checkbox" onChange={e => allCheck(e.target.checked)}/>
                    </div>
                    <div className={`w-[80px] mr-2.5`}>시료명</div>
                    <div className={`w-[200px] flex flex-col border-r`}>
                      <div className={`flex border-b border-[#CDCDCD]`}>
                        <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                        <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                      </div>
                      <div className={`flex pb-[15px]`}>
                        <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                        <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                      </div>
                    </div>
                    <div className={`flex-1 overflow-hidden  text-center`}>
                      <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                      <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                        {
                          pollutants.map(pollutant => {
                            return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                              return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                            })
                          })
                        }
                      </div>
                    </div>
                  </div>

                </div>

                {
                  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
                    return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                      <div className={`mr-2.5`}>
                        <input
                          type="checkbox"
                          checked={o.checked}
                          onChange={e => onChangeChecked('변동계수 산정-정밀조사 실시', o.key, e.target.checked)}
                        />
                      </div>
                      <div className={`w-[80px] mr-2.5`}>
                        <input
                          type="text"
                          className={`p-2.5 border border-[#CDCDCD] rounded-[5px] h-[80px] w-[80px]`}
                          placeholder={'시료명'}
                          value={o['시료명']}
                          onChange={e => onChangeText('변동계수 산정-정밀조사 실시', o.key, '시료명', e.target.value)}
                        />
                      </div>
                      <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                        <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                          <div className={`flex-1 gap-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'위도'}
                              value={o['위도']}
                              onChange={e => onChangeText('변동계수 산정-정밀조사 실시', o.key, '위도', fixFloat(e.target.value, 5))}
                            />
                          </div>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'경사(%)'}
                              value={o['경사']}
                              onChange={e => onChangeText('변동계수 산정-정밀조사 실시', o.key, '경사', fixFloat(e.target.value, 1))}
                            />
                          </div>
                        </div>
                        <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'경도'}
                              value={o['경도']}
                              onChange={e => onChangeText('변동계수 산정-정밀조사 실시', o.key, '경도', fixFloat(e.target.value, 4))}
                            />
                          </div>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'고도(m)'}
                              value={o['고도']}
                              onChange={e => onChangeText('변동계수 산정-정밀조사 실시', o.key, '고도', parseInt(e.target.value || 0))}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                        <div className="flex gap-1 pl-[8px] py-[5px]">
                          {
                            pollutants.map(pollutant => {
                              return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                  <div className={``} key={key}>
                                    <input
                                      type="text"
                                      className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                      value={o[key]}
                                      onChange={e => onChangeText('변동계수 산정-정밀조사 실시', o.key, key, e.target.value)}
                                    />
                                  </div>
                              })
                            })
                          }
                        </div>
                      </div>
                    </div>
                  })
                }

                {
                  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
                    return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                      <div className={`mr-2.5`}>
                        <input
                          type="checkbox"
                          checked={o.checked}
                          onChange={e => onChangeChecked('변동계수 산정-정밀조사 추가조사 후 입력', o.key, e.target.checked)}
                        />
                      </div>
                      <div className={`w-[80px] mr-2.5`}>
                        <input
                          type="text"
                          className={`p-2.5 border border-[#CDCDCD] rounded-[5px] h-[80px] w-[80px]`}
                          placeholder={'시료명'}
                          value={o['시료명']}
                          onChange={e => onChangeText('변동계수 산정-정밀조사 추가조사 후 입력', o.key, '시료명', e.target.value)}
                        />
                      </div>
                      <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                        <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                          <div className={`flex-1 gap-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'위도'}
                              value={o['위도']}
                              onChange={e => onChangeText('변동계수 산정-정밀조사 추가조사 후 입력', o.key, '위도', fixFloat(e.target.value, 5))}
                            />
                          </div>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'경사(%)'}
                              value={o['경사']}
                              onChange={e => onChangeText('변동계수 산정-정밀조사 추가조사 후 입력', o.key, '경사', fixFloat(e.target.value, 1))}
                            />
                          </div>
                        </div>
                        <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'경도'}
                              value={o['경도']}
                              onChange={e => onChangeText('변동계수 산정-정밀조사 추가조사 후 입력', o.key, '경도', fixFloat(e.target.value, 4))}
                            />
                          </div>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'고도(m)'}
                              value={o['고도']}
                              onChange={e => onChangeText('변동계수 산정-정밀조사 추가조사 후 입력', o.key, '고도', parseInt(e.target.value || 0))}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                        <div className="flex gap-1 pl-[8px] py-[5px]">
                          {
                            pollutants.map(pollutant => {
                              return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                  <div className={``} key={key}>
                                    <input
                                      type="text"
                                      className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                      value={o[key]}
                                      onChange={e => onChangeText('변동계수 산정-정밀조사 추가조사 후 입력', o.key, key, e.target.value)}
                                    />
                                  </div>
                              })
                            })
                          }
                        </div>
                      </div>
                    </div>
                  })
                }


                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>평균(m)</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      {
                        pollutants.map(pollutant => {
                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                              <div className={``} key={key}>
                                <input
                                  type="text"
                                  className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                  readOnly={true}
                                  value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['평균'][key] === false ?
                                    '계산전' :
                                    appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['평균'][key]}/>
                              </div>
                          })
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>표준편차(s)</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      {
                        pollutants.map(pollutant => {
                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                              <div className={``} key={key}>
                                <input
                                  type="text"
                                  className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                  readOnly={true}
                                  value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['표준편차'][key] === false ?
                                    '계산전' :
                                    appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사+추가조사 결과 입력2']['표준편차'][key]}/>
                              </div>
                          })
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

