import React from 'react';
import FinalReport from "components/FinalReport";

const FinalReportPage = ({}) => {
  return (
    <FinalReport />
  );
};

export default FinalReportPage;
