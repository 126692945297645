import React, {useContext, useEffect, useState} from 'react';
import {
  LayoutBox,
  LayoutContentsInner,
  LayoutContentsWrap,
  LayoutSubTitle2
} from "constants/LayoutCons";
import Side from "components/Side";
import {GetMenuUrl, } from "constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "contexts/AppContext";
import IntImg01 from 'img/intImg01.png';
import IntImg02 from 'img/intImg02.png';
import Decimal from "decimal.js";
import * as lodash from "lodash";
import axios from "axios";


export default ({}) => {

  // let 전체데이터 = [];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [mapUrl, setMapUrl] = useState(appContext.appData['통합위해도 산출 지도 url']);
  const [전체데이터, set전체데이터] = useState([]);

  const Eco_plate = appContext.appData['Eco plate']['시료'];
  const Bait_lamina = appContext.appData['Bait-lamina']['시료'];
  const 톡토기_종_풍부도 = appContext.appData['톡토기 종 풍부도']['시료'];
  const 응애_종_풍부도 = appContext.appData['응애 종 풍부도']['시료'];

  const [총농도데이터, set총농도데이터] = useState({});
  const [토양총농도데이터, set토양총농도데이터] = useState({});
  const [CaCl2추출농도, setCaCl2추출농도] = useState({});
  const [HNO3추출농도, setHNO3추출농도] = useState({});
  const [화학평과결과, set화학평과결과] = useState({});
  const [생태위해최종데이터, set생태위해최종데이터] = useState({});

  const 토양독성실험종 = appContext.appData['토양독성실험종'];
  const 토양추출액독성실험종 = appContext.appData['토양추출액독성실험종'];
  const [생태독성최종데이터, set생태독성최종데이터] = useState({});

  const [통합위해도, set통합위해도] = useState({});
  const [최종시료카테고리, set최종시료카테고리] = useState([]);

  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }


  useEffect(() => {
    // 화학위해도
    {
      const SSD기반 = {'Cd': 12, 'Cu': 60, 'As': 56, 'Hg': 490, 'Cr6+': 0, 'Ni': 65, 'Zn': 210, 'Pb': 490};
      // 중금속 총농도
      {
        // 총농도
        Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
          if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
          appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const 토양농도 = o['토양농도'];
            let 총농도 = 0;
            let length = 0;
            토양농도.map((o2, i2) => {
              ++length;
              총농도 = new Decimal(o2[중금속]).plus(총농도);
            });
            if(총농도데이터[중금속] === undefined) {
              총농도데이터[중금속] = {};
            }
            // console.log(총농도, length, 총농도.div(length).toNumber());

            총농도데이터[중금속][시료명] = lodash.merge({'총농도': (총농도.div(length).toNumber()).toFixed(1)}, 총농도데이터[중금속][시료명]);
          });
        });

        // SSD 기반
        Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
          if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
          appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            // console.log(SSD기반[중금속], 중금속);
            총농도데이터[중금속][시료명] = lodash.merge({'SSD 기반': SSD기반[중금속]}, 총농도데이터[중금속][시료명]);
          });
        });

        // SSD 변곡점
        Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
          if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
          appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            총농도데이터[중금속][시료명] = lodash.merge({'SSD 변곡점': 0.4}, 총농도데이터[중금속][시료명]);
          });
        });

        // Toxic
        Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
          if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
          appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const 총농도 = 총농도데이터[중금속][시료명]['총농도'];
            const SSD_기반 = 총농도데이터[중금속][시료명]['SSD 기반'];
            const SSD_변곡점 = 총농도데이터[중금속][시료명]['SSD 변곡점'];
            const Toxic = 1 / (1+Math.exp((Math.log10(SSD_기반) - Math.log10(총농도)) / SSD_변곡점));
            총농도데이터[중금속][시료명] = lodash.merge({'Toxic': isNaN(Toxic) ? 0.000 : Toxic.toFixed(3)}, 총농도데이터[중금속][시료명]);
          });
        });

        // Reference
        Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
          if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
          appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
            if( ! 총농도데이터[중금속]['R1']) return
            const 시료명 = o['시료명'];
            const 중금속Toxic = 총농도데이터[중금속][시료명]['Toxic'];
            const R3Toxic = 총농도데이터[중금속]['R1']['Toxic'];
            const Reference = (중금속Toxic - R3Toxic) / (1 - R3Toxic);
            총농도데이터[중금속][시료명] = lodash.merge({'Reference': Reference.toFixed(3)}, 총농도데이터[중금속][시료명]);
          });
        });

        // 상용로그변환
        Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
          if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
          appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const 상용로그변환 = Math.log10(1 - 총농도데이터[중금속][시료명]['Reference']);
            총농도데이터[중금속][시료명] = lodash.merge({'상용로그변환': 상용로그변환.toFixed(3)}, 총농도데이터[중금속][시료명]);
          });
        });

        // 정수변환
        Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
          if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
          appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const 상용로그변환 = 총농도데이터[중금속][시료명]['상용로그변환'];
            const 정수변환 = 상용로그변환 > 0 ? 0 : 1 - Math.pow(10, 상용로그변환);
            총농도데이터[중금속][시료명] = lodash.merge({'정수변환': 정수변환.toFixed(3)}, 총농도데이터[중금속][시료명]);
          });
        });
      }

      // 토양 중금속 농도
      {
        // 총농도
        Object.keys(appContext.appData['토양 중금속 농도']['중금속']).map(중금속 => {
          if(appContext.appData['토양 중금속 농도']['중금속'][중금속] === false) return;
          appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const 토양농도 = o['토양농도'];
            let 총농도 = 0;
            let length = 0;
            토양농도.map((o2, i2) => {
              ++length;
              총농도 = new Decimal(o2[중금속]).plus(총농도);
            });
            if(토양총농도데이터[중금속] === undefined) {
              토양총농도데이터[중금속] = {};
            }
            토양총농도데이터[중금속][시료명] = lodash.merge({'총농도': (총농도.div(length).toNumber()).toFixed(1)}, 토양총농도데이터[중금속][시료명]);
          });
        })

        // SSD 기반
        Object.keys(appContext.appData['토양 중금속 농도']['중금속']).map(중금속 => {
          if(appContext.appData['토양 중금속 농도']['중금속'][중금속] === false) return;
          appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            토양총농도데이터[중금속][시료명] = lodash.merge({'SSD 기반': SSD기반[중금속]}, 토양총농도데이터[중금속][시료명]);
          });
        });

        // HC 50의 비
        Object.keys(appContext.appData['토양 중금속 농도']['중금속']).map(중금속 => {
          if(appContext.appData['토양 중금속 농도']['중금속'][중금속] === false) return;
          appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const 총농도 = 토양총농도데이터[중금속][시료명]['총농도'];
            const SSD_기반 = 토양총농도데이터[중금속][시료명]['SSD 기반'];
            토양총농도데이터[중금속][시료명] = lodash.merge({'HC 50의 비': 총농도 == 0 ? 0 : (new Decimal(총농도).div(SSD_기반).toNumber()).toFixed(5)}, 토양총농도데이터[중금속][시료명]);
          });
        });

        // Risk number
        Object.keys(appContext.appData['토양 중금속 농도']['중금속']).map(중금속 => {
          if(appContext.appData['토양 중금속 농도']['중금속'][중금속] === false) return;
          appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const HC50의비 = 토양총농도데이터[중금속][시료명]['HC 50의 비'];
            토양총농도데이터[중금속][시료명] = lodash.merge({'Risk number': (1-(1 / (1 + HC50의비))).toFixed(3) }, 토양총농도데이터[중금속][시료명]);
          });
        });

        // Reference
        Object.keys(appContext.appData['토양 중금속 농도']['중금속']).map(중금속 => {
          if(appContext.appData['토양 중금속 농도']['중금속'][중금속] === false) return;
          appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
            if( ! 토양총농도데이터[중금속]['R1']) return
            const 시료명 = o['시료명'];
            const Risk_number = 토양총농도데이터[중금속][시료명]['Risk number'];
            const 기존Risk_number = 토양총농도데이터[중금속]['R1']['Risk number'];
            const Reference = Risk_number < 기존Risk_number ? 0 : (Risk_number - 기존Risk_number) / (1 - 기존Risk_number);

            토양총농도데이터[중금속][시료명] = lodash.merge({'Reference': (Reference).toFixed(3)}, 토양총농도데이터[중금속][시료명]);
          });
        });
      }

      // CaCl2 추출농도
      {
        // 총농도
        Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(중금속 => {
          if(appContext.appData['CaCl2 추출농도']['중금속'][중금속] === false) return;
          appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const 토양농도 = o['토양농도'];
            let 총농도 = 0;
            let length = 0;
            토양농도.map((o2, i2) => {
              ++length;
              총농도 = new Decimal(o2[중금속]).plus(총농도);
            });
            if(CaCl2추출농도[중금속] === undefined) {
              CaCl2추출농도[중금속] = {};
            }
            CaCl2추출농도[중금속][시료명] = lodash.merge({'총농도': (총농도.div(length).toNumber()).toFixed(2)}, CaCl2추출농도[중금속][시료명]);
          });
        })

        // SSD 기반
        Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(중금속 => {
          if(appContext.appData['CaCl2 추출농도']['중금속'][중금속] === false) return;
          appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            CaCl2추출농도[중금속][시료명] = lodash.merge({'SSD 기반': SSD기반[중금속]}, CaCl2추출농도[중금속][시료명]);
          });
        });

        // HC 50의 비
        Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(중금속 => {
          if(appContext.appData['CaCl2 추출농도']['중금속'][중금속] === false) return;
          appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const 총농도 = CaCl2추출농도[중금속][시료명]['총농도'];
            const SSD_기반 = CaCl2추출농도[중금속][시료명]['SSD 기반'];
            CaCl2추출농도[중금속][시료명] = lodash.merge({'HC 50의 비': 총농도 == 0 ? 0 : Number((new Decimal(총농도).div(SSD_기반).toNumber()).toFixed(5))}, CaCl2추출농도[중금속][시료명]);
          });
        });

        // Risk number
        Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(중금속 => {
          if(appContext.appData['CaCl2 추출농도']['중금속'][중금속] === false) return;
          appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const HC50의비 = CaCl2추출농도[중금속][시료명]['HC 50의 비'];
            CaCl2추출농도[중금속][시료명] = lodash.merge({'Risk number': (1-(1 / (1 + HC50의비))).toFixed(3) }, CaCl2추출농도[중금속][시료명]);
          });
        });

        // Reference
        Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(중금속 => {
          if(appContext.appData['CaCl2 추출농도']['중금속'][중금속] === false) return;
          appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
            if( ! CaCl2추출농도[중금속]['R1']) return
            const 시료명 = o['시료명'];
            const Risk_number = CaCl2추출농도[중금속][시료명]['Risk number'];
            const 기존Risk_number = CaCl2추출농도[중금속]['R1']['Risk number'];
            const Reference = Risk_number < 기존Risk_number ? 0 : (Risk_number - 기존Risk_number) / (1 - 기존Risk_number);

            if(isNaN(Reference)) {
              // console.log(Reference, Risk_number, 기존Risk_number, 시료명, 중금속);
            }
            CaCl2추출농도[중금속][시료명] = lodash.merge({'Reference': (Reference).toFixed(3)}, CaCl2추출농도[중금속][시료명]);
          });
        });
      }

      // HNO3 추출농도
      {
        // 총농도
        Object.keys(appContext.appData['HNO3 추출농도']['중금속']).map(중금속 => {
          if(appContext.appData['HNO3 추출농도']['중금속'][중금속] === false) return;
          appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const 토양농도 = o['토양농도'];
            let 총농도 = 0;
            let length = 0;
            토양농도.map((o2, i2) => {
              ++length;
              총농도 = new Decimal(o2[중금속]).plus(총농도);
            });
            if(HNO3추출농도[중금속] === undefined) {
              HNO3추출농도[중금속] = {};
            }
            HNO3추출농도[중금속][시료명] = lodash.merge({'총농도': (총농도.div(length).toNumber()).toFixed(2)}, HNO3추출농도[중금속][시료명]);
          });
        })

        // SSD 기반
        Object.keys(appContext.appData['HNO3 추출농도']['중금속']).map(중금속 => {
          if(appContext.appData['HNO3 추출농도']['중금속'][중금속] === false) return;
          appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            HNO3추출농도[중금속][시료명] = lodash.merge({'SSD 기반': SSD기반[중금속]}, HNO3추출농도[중금속][시료명]);
          });
        });

        // HC 50의 비
        Object.keys(appContext.appData['HNO3 추출농도']['중금속']).map(중금속 => {
          if(appContext.appData['HNO3 추출농도']['중금속'][중금속] === false) return;
          appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const 총농도 = HNO3추출농도[중금속][시료명]['총농도'];
            const SSD_기반 = HNO3추출농도[중금속][시료명]['SSD 기반'];
            HNO3추출농도[중금속][시료명] = lodash.merge({'HC 50의 비': 총농도 == 0 ? 0 : Number((new Decimal(총농도).div(SSD_기반).toNumber()).toFixed(5))}, HNO3추출농도[중금속][시료명]);
          });
        });

        // Risk number
        Object.keys(appContext.appData['HNO3 추출농도']['중금속']).map(중금속 => {
          if(appContext.appData['HNO3 추출농도']['중금속'][중금속] === false) return;
          appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
            const 시료명 = o['시료명'];
            const HC50의비 = HNO3추출농도[중금속][시료명]['HC 50의 비'];
            HNO3추출농도[중금속][시료명] = lodash.merge({'Risk number': (1-(1 / (1 + HC50의비))).toFixed(3) }, HNO3추출농도[중금속][시료명]);
          });
        });

        // Reference
        Object.keys(appContext.appData['HNO3 추출농도']['중금속']).map(중금속 => {
          if(appContext.appData['HNO3 추출농도']['중금속'][중금속] === false) return;
          appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
            if( ! HNO3추출농도[중금속]['R1']) return
            const 시료명 = o['시료명'];
            const Risk_number = HNO3추출농도[중금속][시료명]['Risk number'];
            const 기존Risk_number = HNO3추출농도[중금속]['R1']['Risk number'];
            const Reference = Risk_number < 기존Risk_number ? 0 : (Risk_number - 기존Risk_number) / (1 - 기존Risk_number);

            if(isNaN(Reference)) {
              // console.log(Reference, Risk_number, 기존Risk_number, 시료명, 중금속);
            }
            HNO3추출농도[중금속][시료명] = lodash.merge({'Reference': (Reference).toFixed(3)}, HNO3추출농도[중금속][시료명]);
          });
        });
      }

      // 화학평가결과
      {

        let 시료배열 = [];
        let 중금속배열 = ['Cd', 'Cu', 'As', 'Hg', 'Pb', 'Cr6+', 'Zn', 'Ni'];
        const 데이터카테고리 = ['중금속 분석결과', 'CaCl2 추출농도', 'HNO3 추출농도', '토양 중금속 농도'];
        데이터카테고리.map(카테고리 => {
          appContext.appData[카테고리]['시료'].map((o, i) => {
            시료배열.push(o['시료명']);
          });
        });

        // 중복 제거
        시료배열 = 시료배열.filter((element, index) => {
          return 시료배열.indexOf(element) === index;
        });

        시료배열.map(시료 => {
          let 결과값 = 1;
          중금속배열.map(중금속 => {
            try {
              let 값 = 총농도데이터[중금속][시료]['정수변환'];
              if(Number(값) !== 0) {
                결과값 = (1 - 값) * 결과값;
              }
            } catch (e) {}

            try {
              let 값 = 토양총농도데이터[중금속][시료]['Reference'];
              if(Number(값) !== 0) {
                결과값 = (1 - 값) * 결과값;
              }
            } catch (e) {}

            try {
              let 값 = CaCl2추출농도[중금속][시료]['Reference'];
              if(Number(값) !== 0) {
                결과값 = (1 - 값) * 결과값;
              }
            } catch (e) {}

            try {
              let 값 = HNO3추출농도[중금속][시료]['Reference'];
              if(Number(값) !== 0) {
                결과값 = (1 - 값) * 결과값;
              }
            } catch (e) {}

          });
          화학평과결과[시료] = (1 - 결과값).toFixed(3);
        });
      }
      // console.log('화학평과결과', 화학평과결과);
      set총농도데이터(lodash.merge({}, 총농도데이터));
      set토양총농도데이터(lodash.merge({}, 토양총농도데이터));
      setCaCl2추출농도(lodash.merge({}, CaCl2추출농도));
      setHNO3추출농도(lodash.merge({}, HNO3추출농도));

      set화학평과결과(lodash.merge({}, 화학평과결과));
    }

    // 생태위해
    let 임시생태위해최종데이터 = {'R1': {}, 'Site 1': {}, 'Site 2': {}, 'Site 3': {}, 'Site 4': {}, 'Site 5': {}, 'Site 6': {}};
    {
      ['R1', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((시료명, 시료순번) => {
        if(Bait_lamina[시료순번]) 임시생태위해최종데이터[시료명]['Bait-lamina'] = {'평균': Bait_lamina[시료순번]['%전환']};
        if(톡토기_종_풍부도[시료순번]) 임시생태위해최종데이터[시료명]['톡토기 종 풍부도'] = {'평균': 톡토기_종_풍부도[시료순번]['%전환']};
        if(응애_종_풍부도[시료순번]) 임시생태위해최종데이터[시료명]['응애 종 풍부도'] = {'평균': 응애_종_풍부도[시료순번]['%전환']};
        if(Eco_plate[시료순번]) 임시생태위해최종데이터[시료명]['Eco plate'] = {'평균': Eco_plate[시료순번]['%전환']};
      });


      Object.keys(임시생태위해최종데이터).map(시료명 => {
        let 토탈 = 0, 갯수 = 0;
        Object.keys(임시생태위해최종데이터[시료명]).map(실험명 => {
          ++갯수;
          const 평균 = 임시생태위해최종데이터[시료명][실험명]['평균'];
          const 고정평균 = 임시생태위해최종데이터['R1'][실험명] ? 임시생태위해최종데이터['R1'][실험명]['평균'] : 1;

          const 두번째값 =  new Decimal((평균)).div(고정평균); //((100 - 평균) / 100);
          임시생태위해최종데이터[시료명][실험명] = lodash.merge({'두번째값': 두번째값.toNumber()}, 임시생태위해최종데이터[시료명][실험명]);

          const 세번째값 = 두번째값.toNumber() === 0 || 두번째값.toNumber() > 1 ? 0 : Math.log10(두번째값);

          임시생태위해최종데이터[시료명][실험명] = lodash.merge({'세번째값': 세번째값}, 임시생태위해최종데이터[시료명][실험명]);
          토탈 = new Decimal(토탈).plus(Math.abs(세번째값)).toNumber();
          // console.log(`${실험명} : `, 시료명, '평균', Number(평균) , '고정평균', Number(고정평균), '두번째값', 두번째값.toNumber(), '세번째값', 세번째값, '토탈', 토탈);
        });

        if(Object.keys(임시생태위해최종데이터[시료명]).length > 0) {
          const 종말점수 = 4;
          토탈 = 토탈 * -1;
          임시생태위해최종데이터[시료명] = lodash.merge({'토탈': isNaN(토탈) ? 0 : 토탈}, 임시생태위해최종데이터[시료명]);
          임시생태위해최종데이터[시료명] = lodash.merge({'위해도': (1 - Math.pow(10, 토탈 / 종말점수)).toFixed(3)}, 임시생태위해최종데이터[시료명]);
        } else {
          delete 임시생태위해최종데이터[시료명];
        }
      });
      set생태위해최종데이터(임시생태위해최종데이터);
    }

    // 생태독성
    let 임시생태독성최종데이터 = {'R1': {}, 'Site 1': {}, 'Site 2': {}, 'Site 3': {}, 'Site 4': {}, 'Site 5': {}, 'Site 6': {}};
    {
      ['R1', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((시료명, 시료순번) => {
        토양독성실험종.map((실험, 실험순번) => {
          if(실험['checked'] === false) return
          if(실험['시료'][시료순번]) {
            임시생태독성최종데이터[시료명][실험['실험명']] = {'평균': 실험['시료'][시료순번]['%전환']};
          }
        });
        토양추출액독성실험종.map((실험, 실험순번) => {
          if(실험['checked'] === false) return
          if(실험['시료'][시료순번]) {
            임시생태독성최종데이터[시료명][실험['실험명']] = {'평균': 실험['시료'][시료순번]['%전환']};
          }
        });
      });

      Object.keys(임시생태독성최종데이터).map(시료명 => {
        let 토탈 = 0, 갯수 = 0;
        Object.keys(임시생태독성최종데이터[시료명]).map((실험명, 순번) => {
          // console.log(시료명, 실험명, 평균);
          // 2번값 추출
          const 평균 = Number((Number(임시생태독성최종데이터[시료명][실험명]['평균'])).toFixed(1));
          const 두번째값 =  new Decimal(new Decimal((100 - 평균)).div(100).toNumber());
          임시생태독성최종데이터[시료명][실험명] = lodash.merge({'두번째값': 두번째값.toNumber()}, 임시생태독성최종데이터[시료명][실험명]);

          const 고정두번째값 = 임시생태독성최종데이터['R1'][실험명]['두번째값'];
          const 세번째값 = new Decimal(new Decimal((두번째값 - 고정두번째값)).div((1 - 고정두번째값) === 0 ? 0 : 1 - 고정두번째값).toNumber());
          임시생태독성최종데이터[시료명][실험명] = lodash.merge({'세번째값': 세번째값.toNumber()}, 임시생태독성최종데이터[시료명][실험명]);

          const 네번째값 = 세번째값.toNumber() < 0 ? new Decimal(0) : 세번째값;
          임시생태독성최종데이터[시료명][실험명] = lodash.merge({'네번째값': 네번째값.toNumber()}, 임시생태독성최종데이터[시료명][실험명]);

          let 다섯번째값 = 네번째값.equals(1) ? -1 : Math.log10(1 - 네번째값.toNumber());
          임시생태독성최종데이터[시료명][실험명] = lodash.merge({'다섯번째값':다섯번째값.toFixed(2)}, 임시생태독성최종데이터[시료명][실험명]);

          // if(시료명 === 'Site 1')
          //   console.log(시료명, `${실험명}`, '> ', '평균', 임시생태독성최종데이터[시료명][실험명]['평균'], 평균, '  두번째값', 두번째값.toNumber() , '  두번째고정값', 고정두번째값, '  세번째값',세번째값.toNumber(), '  네번째값',네번째값.toNumber(), '  다섯번째값', 다섯번째값);

          if(다섯번째값 !== -1) {
            ++갯수;
            토탈 += 다섯번째값;
          }
        });
        if(Object.keys(임시생태독성최종데이터[시료명]).length > 0) {
          토탈 = 토탈;
          임시생태독성최종데이터[시료명] = lodash.merge({'토탈': 토탈}, 임시생태독성최종데이터[시료명]);
          임시생태독성최종데이터[시료명] = lodash.merge({'토탈평균': new Decimal(토탈).div(갯수).toNumber()}, 임시생태독성최종데이터[시료명]);
          임시생태독성최종데이터[시료명] = lodash.merge({'총평균': (1 - Math.pow(10, new Decimal(토탈).div(갯수).toNumber())).toFixed(3)}, 임시생태독성최종데이터[시료명]);
        } else {
          delete 임시생태독성최종데이터[시료명];
        }
      });
      set생태독성최종데이터(임시생태독성최종데이터);
    }

    // 통합위해도
    ['R1', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map(시료명 => {
      if(통합위해도[시료명] === undefined) 통합위해도[시료명] = {};
      try {
        통합위해도[시료명]['위해도값'] = new Decimal(화학평과결과[시료명]).plus(임시생태독성최종데이터[시료명]['총평균']).plus(임시생태위해최종데이터[시료명]['위해도']).div(3).toNumber().toFixed(3);
      } catch (e) { }

      try {
        if( ! isNaN(Math.pow(화학평과결과[시료명], 2) + Math.pow(임시생태독성최종데이터[시료명]['총평균'], 2) + Math.pow(임시생태위해최종데이터[시료명]['위해도'], 2))) {
          const 평균 = new Decimal(화학평과결과[시료명]).plus(임시생태독성최종데이터[시료명]['총평균']).plus(임시생태위해최종데이터[시료명]['위해도']).div(3).toNumber();
          통합위해도[시료명]['제곱값'] = Math.pow(화학평과결과[시료명] - 평균, 2) + Math.pow(임시생태독성최종데이터[시료명]['총평균'] - 평균, 2) + Math.pow(임시생태위해최종데이터[시료명]['위해도'] - 평균, 2);
        }
      } catch (e) { }

      try {
        if(! isNaN((Math.sqrt(통합위해도[시료명]['제곱값'] / 2, 2)))) {
          통합위해도[시료명]['표준편차'] = (Math.sqrt(통합위해도[시료명]['제곱값'] / 2, 2));
        }
      } catch (e) { }
      if(Object.keys(통합위해도[시료명]).length === 0) {
        delete 통합위해도[시료명];
      }
    });
    set통합위해도(lodash.merge({}, 통합위해도));

    const max = Math.max(Object.keys(화학평과결과).length, Object.keys(임시생태독성최종데이터).length, Object.keys(임시생태위해최종데이터).length, Object.keys(통합위해도).length);
    let 임시최종시료카테고리 = [];
    ['R1', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((시료명, 순번) => {
      if(max > 순번) 임시최종시료카테고리.push(시료명);

    });
    set최종시료카테고리(임시최종시료카테고리);

    // API
    let tmpDecisionData = [];
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'YES') {
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
    }
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'NO') {
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
    }

    const 전체데이터카테고리갯수 = 임시최종시료카테고리.length < tmpDecisionData.length ? 임시최종시료카테고리.length : tmpDecisionData.length;
    const 전체데이터카테고리 = ['R1', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((시료명, 순번) => {
      if(전체데이터카테고리갯수 > 순번) return 시료명;
    }).filter(o => o !== undefined);

    // console.log('tmpDecisionData', tmpDecisionData);
    // console.log('통합위해도', 통합위해도);
    // console.log('생태위해최종데이터', 생태위해최종데이터);
    전체데이터카테고리.map((시료명, 순번) => {
      전체데이터.push({
        'Name': 시료명,
        '경도': tmpDecisionData[순번] ? Number(tmpDecisionData[순번]['경도']) : 0,
        '위도':  tmpDecisionData[순번] ? Number(tmpDecisionData[순번]['위도']) : 0,
        '화학 위해도': 화학평과결과[시료명] ? 화학평과결과[시료명] : 0,
        '생태독성 위해도': 임시생태독성최종데이터[시료명] ? (임시생태독성최종데이터[시료명]['총평균'] ? 임시생태독성최종데이터[시료명]['총평균'] : 0) : 0,
        '생태 위해도': 임시생태위해최종데이터[시료명] ? (임시생태위해최종데이터[시료명]['위해도'] ? 임시생태위해최종데이터[시료명]['위해도'] : 0) : 0,
        '통합위해도': 통합위해도[시료명] ? (통합위해도[시료명]['위해도값'] ? 통합위해도[시료명]['위해도값'] : 0) : 0,
        '표준편차': 통합위해도[시료명] ? (통합위해도[시료명]['표준편차'] ? 통합위해도[시료명]['표준편차'] : 0) : 0,
      });
    });


    if(전체데이터.length > 0) {
      if(mapUrl === undefined || mapUrl === '') {
        onReload();
      }
    }
    console.log('전체데이터', 전체데이터);
  }, []);

  const onReload = () => {
    axios
      .post('https://api.keras-b.7greenus.com/mapintegrated', 전체데이터)
      .then(({data, status}) => {
        if(status === 200 && data.message === '성공') {
          setMapUrl(data.mapurl);
          appContext.appData['통합위해도 산출 지도 url'] = data.mapurl;
          appContext.setAppData(lodash.merge({}, appContext.appData));
        }
      }).catch(({response}) => {
      alert(response?.data?.message ? response.data.message : '오류가 발생하였습니다.');
    });
  }


  return (
    <div className="container my-7 px-0">
      <div className="my-5">
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <div className="">
                <div className={`bg-white text-sm mb-[10px]`}>
                  <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                    <div className={`w-[203px]`}></div>
                    <div className={`flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs border-l border-[#cdcdcd]`} onScroll={e => onScroll(e)}>
                      {
                        최종시료카테고리.map((시료, 순번) => {
                          return  <div key={순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{시료}</div>
                        })
                      }
                    </div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                    <div className={`w-[203px]`}>화학 위해도</div>
                    <div className={`flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs scrollbar-hide border-l border-[#cdcdcd]`}>
                      {
                        Object.keys(화학평과결과).map((시료명, i) => {
                          return <div key={i} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{isNaN(화학평과결과[시료명]) ? 0 : 화학평과결과[시료명]}</div>
                        })
                      }
                    </div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                    <div className={`w-[203px]`}>생태독성 위해도</div>
                    <div className={`flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs scrollbar-hide border-l border-[#cdcdcd]`}>
                      {
                        Object.keys(생태독성최종데이터).length > 0 && ['R1', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((시료명, i) => {
                          return 생태독성최종데이터[시료명] && <div key={i} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{(isNaN(생태독성최종데이터[시료명]['총평균']) ? 0 : 생태독성최종데이터[시료명]['총평균'])}</div>
                        })
                      }
                    </div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                    <div className={`w-[203px]`}>생태 위해도</div>
                    <div className={`flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs scrollbar-hide border-l border-[#cdcdcd]`}>
                      {
                        Object.keys(생태위해최종데이터).length > 0 && ['R1', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map(시료명 => {
                          return 생태위해최종데이터[시료명] && <div key={시료명} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{생태위해최종데이터[시료명]['위해도']}</div>
                        })
                      }
                    </div>
                  </div>
                  <div className={`w-full p-[12px]`}>
                    <div className={`border-dashed border-gray-300 border-t-[3px]`}></div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                    <div className={`w-[203px]`}>통합위해도</div>
                    <div className={`flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs scrollbar-hide border-l border-[#cdcdcd]`}>
                      {
                        Object.keys(통합위해도).length > 0 && ['R1', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((시료명, i) => {
                          return  통합위해도[시료명] && <div key={i} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{(isNaN(통합위해도[시료명]['위해도값']) ? 0 : 통합위해도[시료명]['위해도값'])}</div>
                        })
                      }
                    </div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                    <div className={`w-[203px]`}>표준편차</div>
                    <div className={`flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs scrollbar-hide border-l border-[#cdcdcd]`}>
                      {
                        Object.keys(통합위해도).length > 0 && ['R1', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((시료명, i) => {
                          if(통합위해도[시료명] && 통합위해도[시료명]['표준편차'] !== undefined) {
                            return <div key={i} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{통합위해도[시료명]['표준편차'].toFixed(3)}</div>
                          } else {
                          }
                        })
                      }
                    </div>
                  </div>
                </div>

                <div className="mb-[10px]">
                  <div className="flex mb-[10px] justify-between">
                    <LayoutSubTitle2>최종 TRIAD 결과 지도위에 삼각형으로 표기</LayoutSubTitle2>

                    <button
                      type={'button'}
                      className={`font-normal bg-[#60448D] w-[123px] h-[37px] rounded-[5px] text-[16px] text-white`}
                      onClick={e => onReload()}
                    >
                      새로고침
                    </button>
                  </div>
                  <div>
                    {
                      mapUrl ? <><iframe src={mapUrl} className={`aspect-[4/3] w-full`}></iframe></>
                        :
                        <><div className={`aspect-[1/1] flex items-center justify-center bg-[#d9d9d9] text-sm`}>데이터 없음</div></>
                    }
                  </div>
                </div>
              </div>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

