import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

const Main = ({}) => {

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    setError('');
    if(id !== 'admin' || password !== 'qwer1234') {
      setError('아이디 비밀번호 확인 후 다시 시도해 주세요.');
      return;
    }
    navigate('/literature/step1');
  }

  return (
    <div className="container">
      <div className="py-[120px]">
        <div className="max-w-[700px] mx-auto">
          <form
            onSubmit={onSubmit}
          >
            <div className={`text-[#60448d] text-[52px] mb-[20px] font-bold text-center`}>KERAS</div>
            <div className="text-center sm:text-[16px] md:text-[36px] font-bold mb-11 ">로그인</div>
            <div className="flex flex-col gap-y-1">
              <div className="flex flex-col gap-y-1 mb-6">
                <p className={`font-bold text-[20px] mb-2 px-[14px]`}>아이디(이메일)</p>
                <div>
                  <input
                    type="text"
                    className="border-[#CDCDCD] border h-[68px] rounded w-full p-3"
                    placeholder="admin"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-y-1">
                <p className={`font-bold text-[20px] mb-2 px-[14px]`}>비밀번호</p>
                <div>
                  <input
                    type="password"
                    className="border-[#CDCDCD] border h-[68px] rounded w-full p-3"
                    placeholder="qwer1234"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              {error && <div className="text-[#FF0000] mt-6">{error}</div>}
            </div>
            <div className="mt-[40px]">
              <button type="submit" className="w-[250px] h-[48px] bg-[#60448d] block rounded text-white text-xl font-bold mx-auto">로그인</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Main;
