import React, {useContext, useEffect, useState} from 'react';
import Side from "components/Side";
import {GetMenuUrl} from "constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import {
  LayoutBox,
  LayoutBoxHeader,
  LayoutBoxHeaderH2,
  LayoutBoxHeaderRight,
  LayoutContentsHeader,
  LayoutContentsInner,
  LayoutContentsWrap,
  LayoutSubTitle
} from "../../constants/LayoutCons";
import {FileInput, FormGroup, FormWrap} from "../../constants/FormConstants";
import Img from 'img/determining01.png';
import AppContext from "../../contexts/AppContext";
import {getBase64} from "../../util/FileUtil";
import * as lodash from "lodash";
import KerasImage from "../../util/KerasImage";
import EmptyImage from "../../util/EmptyImage";
import axios from "axios";

export default  ({}) => {

  const appContext = useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pollutants = ['유기물질', '중금속', '기타'];
  const [mapUrl, setMapUrl] = useState(appContext.appData['평가단위 결정 지도 url']);

/*
  const onDecision = (type) => {
    let tmpDecisionData = [];
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'YES') {
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
    }
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'NO') {
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
        if(o.decisionChecked === true) tmpDecisionData.push(o);
      });
    }

    if(tmpDecisionData.length === 0 && type === 'button') {
      alert('항목 선택 후 이용해주세요');
      return;
    }
    console.log('tmpDecisionData', tmpDecisionData);

    let sendData = [].concat(tmpDecisionData.map(o => {
      return {'Name': o['시료명'], '경도': Number(o['경도']), '위도': Number(o['위도']), 'Cd': o['Cd'], 'Cu': o['Cu'], 'As': o['As'], 'Pb': o['Pb'], 'Zn': o['Zn'], 'Ni': o['Ni']};
    }));

    console.log(appContext.appData['평가단위 결정 지도 url']);

  }
*/

  const setMap = (type) => {
    let tmpDecisionData = [];
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'YES') {
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => tmpDecisionData.push(o));
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => tmpDecisionData.push(o));
    }
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'NO') {
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => tmpDecisionData.push(o));
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => tmpDecisionData.push(o));
    }

    if(tmpDecisionData.length === 0) {
      return;
    }
    console.log('tmpDecisionData', tmpDecisionData);

    let sendData = [].concat(tmpDecisionData.map(o => {
      return {'Name': o['시료명'], '경도': Number(o['경도']), '위도': Number(o['위도']), 'Cd': o['Cd'], 'Cu': o['Cu'], 'As': o['As'], 'Pb': o['Pb'], 'Zn': o['Zn'], 'Ni': o['Ni']};
    }));

    if(sendData.length > 0 && (! appContext.appData['평가단위 결정 지도 url'] || type === 'button')) {
      axios
        .post('https://api.keras-b.7greenus.com/mapv', sendData)
        .then(({data, status}) => {
          if(status === 200 && data.message === '성공') {
            setMapUrl(data.mapurl);
            appContext.appData['평가단위 결정 지도 url'] = data.mapurl;
            appContext.setAppData(lodash.merge({}, appContext.appData));
          }
        }).catch(({response}) => {
        alert(response?.data?.message ? response.data.message : '오류가 발생하였습니다.');
      });
    }
  }

  useEffect(() => {
    setMap('effect');
  }, []);


  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }

  const allCheck = (checked) => {
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'YES') {
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => {
        return {...o, decisionChecked: checked}
      });
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
        return {...o, decisionChecked: checked}
      });
    }

    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'NO') {
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
        return {...o, decisionChecked: checked}
      });
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
        return {...o, decisionChecked: checked}
      });
    }
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const onChangeChecked = (name, key, checked) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][name]['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립'][name]['시료'].map(o =>
      o.key === key ? {...o, decisionChecked: checked} : o
    );
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }


  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <LayoutSubTitle>평가단위 결정</LayoutSubTitle>
              <div className="mb-2.5"></div>
              <FormWrap>
                <FormGroup>
                  {
                    appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'YES' &&
                      <>
                        <div className={`bg-white text-sm`}>
                          <div className="sticky top-0 bg-white">
                            <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                              <div className={`mr-2.5`}>
                                <input type="checkbox" onChange={e => allCheck(e.target.checked)}/>
                              </div>
                              <div className={`w-[80px] mr-2.5`}>시료명</div>
                              <div className={`w-[200px] flex flex-col border-r`}>
                                <div className={`flex border-b border-[#CDCDCD]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                                </div>
                                <div className={`flex pb-[15px]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                                </div>
                              </div>
                              <div className={`flex-1 overflow-hidden  text-center`}>
                                <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                                <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          {
                            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
                              return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                <div className={`mr-2.5`}>
                                  <input
                                    type="checkbox"
                                    checked={o.decisionChecked}
                                    onChange={e => onChangeChecked('변동계수 산정-기존조사 결과 있을 때', o.key, e.target.checked)}
                                  />
                                </div>
                                <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                  <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                    <div className={`flex-1 h-[20px]`}>{o['위도']}</div>
                                    <div className={`flex-1 h-[20px]`}>{o['경사']}</div>
                                  </div>
                                  <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                    <div className={`flex-1 h-[20px]`}>{o['경도']}</div>
                                    <div className={`flex-1 h-[20px]`}>{o['고도']}</div>
                                  </div>
                                </div>
                                <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide scs`} onScroll={e => onScroll(e)}>
                                  <div className="flex gap-1 pl-[8px] py-[5px]">
                                    {
                                      pollutants.map(pollutant => {
                                        return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                          return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                            <div className={``} key={key}>
                                              <div className={`w-[100px] h-[20px] px-1`}>
                                                {o[key]}
                                              </div>
                                            </div>
                                        })
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            })
                          }
                          {
                            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-추가조사 후 입력']['시료'].map(o => {
                              return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                <div className={`mr-2.5`}>
                                  <input
                                    type="checkbox"
                                    checked={o.decisionChecked}
                                    onChange={e => onChangeChecked('변동계수 산정-추가조사 후 입력', o.key, e.target.checked)}
                                  />
                                </div>
                                <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                  <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['위도']}</div>
                                    </div>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['경사']}</div>
                                    </div>
                                  </div>
                                  <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['경도']}</div>
                                    </div>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['고도']}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide scs`} onScroll={e => onScroll(e)}>
                                  <div className="flex gap-1 pl-[8px] py-[5px]">
                                    {
                                      pollutants.map(pollutant => {
                                        return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                          return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                            <div className={``} key={key}>
                                              <div className={`w-[100px] h-[20px]`}>{o[key]}</div>
                                            </div>
                                        })
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            })
                          }
                        </div>
                      </>
                  }
                  {
                    appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'NO' &&
                      <>
                        <div className={`bg-white text-sm`}>
                          <div className="sticky top-0 bg-white">
                            <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                              <div className={`mr-2.5`}>
                                <input type="checkbox" onChange={e => allCheck(e.target.checked)}/>
                              </div>
                              <div className={`w-[80px] mr-2.5`}>시료명</div>
                              <div className={`w-[200px] flex flex-col border-r`}>
                                <div className={`flex border-b border-[#CDCDCD]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                                </div>
                                <div className={`flex pb-[15px]`}>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                                  <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                                </div>
                              </div>
                              <div className={`flex-1 overflow-hidden  text-center`}>
                                <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                                <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                                  {
                                    pollutants.map(pollutant => {
                                      return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                        return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                      })
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          {
                            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['시료'].map(o => {
                              return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                <div className={`mr-2.5`}>
                                  <input
                                    type="checkbox"
                                    checked={o.decisionChecked}
                                    onChange={e => onChangeChecked('변동계수 산정-정밀조사 실시', o.key, e.target.checked)}
                                  />
                                </div>
                                <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                  <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                    <div className={`flex-1 gap-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['위도']}</div>
                                    </div>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['경사']}</div>
                                    </div>
                                  </div>
                                  <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['경도']}</div>
                                    </div>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['고도']}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                  <div className="flex gap-1 pl-[8px] py-[5px]">
                                    {
                                      pollutants.map(pollutant => {
                                        return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                          return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                            <div className={``} key={key}>
                                              <div className={`w-[100px] h-[30px]`}>{o[key]}</div>
                                            </div>
                                        })
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            })
                          }

                          {
                            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 추가조사 후 입력']['시료'].map(o => {
                              return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                <div className={`mr-2.5`}>
                                  <input
                                    type="checkbox"
                                    checked={o.decisionChecked}
                                    onChange={e => onChangeChecked('변동계수 산정-정밀조사 추가조사 후 입력', o.key, e.target.checked)}
                                  />
                                </div>
                                <div className={`w-[80px] mr-2.5`}>{o['시료명']}</div>
                                <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                                  <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                                    <div className={`flex-1 gap-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['위도']}</div>
                                    </div>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['경사']}</div>
                                    </div>
                                  </div>
                                  <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['경도']}</div>
                                    </div>
                                    <div className={`flex-1`}>
                                      <div className={`w-[92px] h-[20px]`}>{o['고도']}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                                  <div className="flex gap-1 pl-[8px] py-[5px]">
                                    {
                                      pollutants.map(pollutant => {
                                        return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant]).map(key => {
                                          return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-정밀조사 실시']['오염물질'][pollutant][key] === true &&
                                            <div className={``} key={key}>
                                              <div className={`w-[100px] h-[20px]`}>{o[key]}</div>
                                            </div>
                                        })
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            })
                          }
                        </div>
                      </>
                  }

                  <div className="mt-[30px]">
                    <div className="flex justify-between mb-2.5">
                      <LayoutSubTitle>지도</LayoutSubTitle>
                      {
                        mapUrl && <>
                          <button
                            type={'button'}
                            className={`font-normal bg-[#60448D] w-[123px] h-[37px] rounded-[5px] text-[16px] text-white`}
                            onClick={e => setMap('button')}
                          >
                            새로고침
                          </button>
                        </>
                      }
                    </div>
                    {
                      mapUrl ? <><iframe src={mapUrl} className={`aspect-[4/3] w-full`}></iframe></>
                        :
                        <><div className={`aspect-[1/1] flex items-center justify-center bg-[#d9d9d9] text-sm`}>데이터 없음</div></>
                    }
                  </div>
                </FormGroup>
              </FormWrap>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

