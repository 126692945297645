import React from 'react';
import Step3 from "components/ecotoxicity/Step3";

const Step3Page = ({}) => {
  return (
    <Step3 />
  );
};

export default Step3Page;
