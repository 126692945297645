import React, {useContext} from 'react';
import {LayoutBox, LayoutContentsInner, LayoutContentsWrap} from "../../constants/LayoutCons";
import Side from "components/Side";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import {TableMinusIcon, TablePlusIcon} from "../../constants/KerasTable";
import * as lodash from "lodash";
import {v4 as uuidv4} from "uuid";

export default ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const 기본항목 = ['R3', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'];

  let 항목최대갯수 = 0;
  const 엑셀데이터처리함수 = (data) => {
    if(Array.isArray(data) === false) return;
    if(data.length === 0) return;
    appContext.appData['식생조사']['시료'] = [];
    let insertData = [];
    data.map(row => {
      if(insertData[row['종명']] === undefined) {
        insertData[row['종명']] = [];
      }
      insertData[row['종명']].push(row['값']);
    });

    Object.keys(insertData).map((시료명) => {
      insertData[시료명].map((데이터, 순번) => {
        insertData[시료명]['데이터'] = lodash.merge({[기본항목[순번]]: 데이터}, insertData[시료명]['데이터'])
        항목최대갯수 = 항목최대갯수 > Object.keys(insertData[시료명]['데이터']).length ? 항목최대갯수 : Object.keys(insertData[시료명]['데이터']).length;
      });
    });

    Object.keys(insertData).map((시료명) => {
      appContext.appData['식생조사']['시료'].push({
          key: uuidv4(),
          'checked': false,
          '종명': 시료명,
          '평균': 0,
          '%전환': 0,
          '데이터': insertData[시료명]['데이터']
        }
      );
    });

    appContext.appData['식생조사']['항목'] = ['R3', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((항목, i) => {
      if(i < 항목최대갯수) return 항목;
    }).filter(o => o !== undefined);

    calc();
  }

  const calc = () => {
    let 분류군수 = {}, 피도합 = {};
    appContext.appData['식생조사']['시료'].map(시료 => {
      const 데이터배열 = 시료['데이터'];
      Object.keys(데이터배열).map(key => {
        if( ! 분류군수[key]) {
          분류군수[key] = 0;
        }
        if( ! 피도합[key]) {
          피도합[key] = 0;
        }
        if(데이터배열[key] > 0) {
          ++분류군수[key];
        }
        피도합[key] = 피도합[key] + Number(데이터배열[key]);
      });
    })
    appContext.appData['식생조사']['분류군수'] = 분류군수;
    appContext.appData['식생조사']['피도합'] = 피도합;

    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }

  return (
    <div className="container my-7 px-0">
      <div className="my-5">
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <div className="">
                <div className={`bg-white text-sm mb-[10px]`}>
                  <div className="sticky top-0 bg-white">
                    <div className={`flex justify-between py-[6px] px-[20px] border-t border-black`}>
                      <div className="flex gap-[5px]">
                        <button
                          type={'button'}
                          className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                          onClick={e => {
                            let data = {};
                            appContext.appData['식생조사']['항목'].map(key => {
                              data[key] = 0;
                            })
                            appContext.appData['식생조사']['시료'].push({
                              key: uuidv4(),
                              'checked': false,
                              '종명': '',
                              '데이터' : data
                            });
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        >
                          행추가
                        </button>
                        <button
                          type={'button'}
                          className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                          onClick={e => {
                            appContext.appData['식생조사']['시료'] = appContext.appData['식생조사']['시료'].filter(o => ! o.checked);
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        >
                          행삭제
                        </button>
                      </div>
                      <button
                        type={'button'}
                        className={`font-normal bg-[#60448D] w-[204px] h-[37px] rounded-[5px] text-[16px] text-white`}
                        onClick={() => {
                          appContext.importExcel(엑셀데이터처리함수);
                        }}
                      >
                        엑셀 데이터 가져오기
                      </button>
                    </div>
                    <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                      <div className={`mr-2.5`}>
                        <input
                          type="checkbox"
                          onClick={e => {
                            appContext.appData['식생조사']['시료'].map(o => {
                              o['checked'] = e.target.checked;
                            });
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        />
                      </div>
                      <div className={`w-[100px] mr-2.5`}>종명</div>
                      <div className={`flex-1 w-[683px] overflow-hidden overflow-x-auto text-center border-l border-[#CDCDCD] scss`} onScroll={e => onScroll(e)}>
                        <div className="flex gap-1 py-[5px] items-center">
                          {
                            appContext.appData['식생조사']['항목'].map((항목, 항목순번) => {
                              return  <div key={항목} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{항목}</div>
                            })
                          }
                          <div className={`flex-[20px] flex-shrink-0 flex-grow-0`}>
                            <img src={TablePlusIcon} alt="" className="cursor-pointer" onClick={e => {
                              let cnt = 0;
                              appContext.appData['식생조사']['항목'].map(항목 => {
                                if(항목 !== 'R3') ++cnt;
                              });
                              appContext.appData['식생조사']['항목'].push(`Site ${cnt + 1}`);
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}/>
                          </div>
                          <div className={`flex-[20px] flex-shrink-0 flex-grow-0`}>
                            <img src={TableMinusIcon} alt="" className="cursor-pointer" onClick={e => {
                              appContext.appData['식생조사']['항목'].splice(appContext.appData['식생조사']['항목'].length - 1, 1);
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}/>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  {
                    appContext.appData['식생조사']['시료'].map((시료, 시료순번) => {
                      return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={시료['key']}>
                        <div className={`mr-2.5`}>
                          <input
                            type="checkbox"
                            checked={시료['checked']}
                            onChange={e => {
                              시료['checked'] = e.target.checked;
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}
                          />
                        </div>
                        <div className={`w-[100px] mr-2.5`}>
                          <input type="text" className="border border-[#CDCDCD] rounded-[5px] w-[100px]" value={시료['종명']} onChange={e => {
                            시료['종명'] = e.target.value;
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}/>
                        </div>
                        <div className={`flex-1 w-[683px] overflow-hidden overflow-x-auto text-center scrollbar-hide border-l border-[#CDCDCD] scs`} onScroll={e => onScroll(e)}>
                          <div className="flex gap-1 pl-[8px] py-2">
                            {
                              appContext.appData['식생조사']['항목'].map(key => {
                                return  <div key={key}><input type="text" className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`} value={시료['데이터'][key] === undefined ? '' : 시료['데이터'][key]} onChange={e => {
                                  appContext.appData['식생조사']['시료'][시료순번]['데이터'][key] = e.target.value;
                                  appContext.setAppData(lodash.merge({}, appContext.appData));

                                  calc();
                                }}/></div>
                              })
                            }
                          </div>
                        </div>
                      </div>
                    })
                  }


                  <div className={`w-full p-[12px]`}>
                    <div className={`border-dashed border-gray-300 border-t-[3px]`}></div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd] border-t`}>
                    <div className={`w-[100px] ml-[24px] mr-2`}>분류군수</div>
                    <div className={`flex-1 w-[683px] overflow-hidden overflow-x-auto text-center scrollbar-hide border-l border-[#CDCDCD]`}>
                      <div className="flex gap-1 pl-[8px] py-2 text-left">
                        {
                          appContext.appData['식생조사']['항목'].map(key => {
                            return  <div key={key}><div className="w-[100px] h-[30px]">{appContext.appData['식생조사']['분류군수'][key]}</div></div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                    <div className={`w-[100px] ml-[24px] mr-2`}>피도합</div>
                    <div className={`flex-1 w-[683px] overflow-hidden overflow-x-auto text-center scrollbar-hide border-l border-[#CDCDCD]`}>
                      <div className="flex gap-1 pl-[8px] py-2 text-left">
                        {
                          appContext.appData['식생조사']['항목'].map(key => {
                            return  <div key={key}><div className="w-[100px] h-[30px]">{appContext.appData['식생조사']['피도합'][key]}</div></div>
                          })
                        }
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

