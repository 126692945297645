import React from 'react';
import {Route, Routes} from "react-router-dom";
import Step1Page from "./Step1Page";
import Step2Page from "./Step2Page";
import Step3Page from "./Step3Page";
import Step4Page from "./Step4Page";
import Step5Page from "./Step5Page";
import Step6Page from "./Step6Page";


const Router = ({}) => {
  return (
    <>
      <Routes>
        <Route path={'step1'} element={<Step1Page />} />
        <Route path={'step2'} element={<Step2Page />} />
        <Route path={'step3'} element={<Step3Page />} />
        <Route path={'step4'} element={<Step4Page />} />
        <Route path={'step5'} element={<Step5Page />} />
        <Route path={'step6'} element={<Step6Page />} />
      </Routes>
    </>
  );
};

export default Router;
