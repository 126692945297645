import React, {useContext, useEffect, useState} from 'react';
import Side from "components/Side";
import {RadioLabel} from "../../constants/FormConstants";
import {GetMenuUrl} from "constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import {LayoutBox, LayoutBoxHeader, LayoutBoxHeaderH2, LayoutBoxHeaderRight, LayoutContentsHeader, LayoutContentsInner, LayoutContentsWrap} from "../../constants/LayoutCons";
import AppContext from "../../contexts/AppContext";
import * as lodash from "lodash";
import Modal from "../../util/Modal";
import DetArrow from 'img/detArrow.png';
import DetPotygon from 'img/detPotygon.png';

const Step1 = ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  const onChange = (e) => {
    const appData = lodash.merge({}, appContext.appData, {
      '평가범위 결정 및 현장조사 계획수립': {
        '평가범위 결정': e.target.value
      }
    });
    appContext.setAppData(appData);
  }

  const checkValidate = () => {
    setError('');
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수'] === '') {
      setError('필수입력');
      return;
    }
    if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수'] === undefined) {
      setError('필수입력');
      return;
    }
    GetMenuUrl(pathname, navigate, appContext.appData);
  }

  const onInputChange = (value) => {
    const appData = lodash.merge({}, appContext.appData, {
      '평가범위 결정 및 현장조사 계획수립': {
        '시료개수': parseInt(value ? value.replace(/[^0-9]/g, "") : 0)
      }
    });
    appContext.setAppData(appData);
  }

  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <div>
                <div className="relative h-[168px] w-[303px] font-bold text-white text-[18px] mx-auto flex items-center text-center justify-center">
                  대상부지 토양정밀조사<br/>
                  결과가 있는가?
                  <div className={`absolute top-0 left-0 right-0 bottom-0 z-[-1]`}>
                    <img src={DetPotygon} alt=""/>
                  </div>
                </div>
                <div className="mx-auto w-[452px]">
                  <img src={DetArrow} alt="" className={``}/>
                </div>
                <div className="flex gap-[88px] relative px-[11px] justify-center">
                  <div className={`flex-[330px] flex-grow-0`}>
                    <div className="">
                      <div className="flex flex-col gap-1">
                        <div className="bg-[#ebe5f5] h-[156px] flex-1 flex items-center justify-center flex-col gap-5 rounded p-2.5">
                          <div className="p-5">
                            <RadioLabel>
                              <input
                                type="radio"
                                name={'a'}
                                value={`YES`}
                                onChange={e => onChange(e)}
                                defaultChecked={appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'YES'}
                                onClick={(e) => setShowModal(true)}
                              />
                              YES
                            </RadioLabel>
                          </div>
                          <div className={`flex gap-2.5 items-center`}>
                            <p>n1 (토양정밀조사 시료개수) =</p>
                            <input type="text" className={`h-[68px] border border-gray-400 rounded p-5 max-w-[89px]`} placeholder={`예)37`} value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수']} readOnly={true}/>
                          </div>
                        </div>
                        <div className="bg-[#60448d] h-[43px] flex-1 flex items-center justify-center flex-col gap-5 rounded py-2 px-4 text-white">
                          2.변동계수 산정-기존조사 결과 있을 때
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`flex-[330px] flex-grow-0`}>
                    <div className="">
                      <div className="flex flex-col gap-1">
                        <div className="bg-[#ebe5f5] h-[156px] flex-1 flex items-center justify-center flex-col gap-5 rounded p-2.5">
                          <div className="p-5">
                            <RadioLabel>
                              <input
                                type="radio"
                                name={'a'}
                                value={`NO`}
                                onChange={e => onChange(e)}
                                defaultChecked={appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'NO'}
                                onClick={(e) => setShowModal(true)}
                              />
                              NO
                            </RadioLabel>
                          </div>
                          <div className={`flex gap-2.5 items-center`}>
                            <p>(토양 정밀 조사 실시 ) =</p>
                            <input type="text" className={`h-[68px] border border-gray-400 rounded p-5 max-w-[89px]`} placeholder={`예)37`} value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수']} readOnly={true}/>
                          </div>
                        </div>
                        <div className="bg-[#60448d] h-[43px] flex-1 flex items-center justify-center flex-col gap-5 rounded py-2 px-4 text-white">
                          3.변동계수 산정-정밀조사 실시
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                showModal &&
                <Modal
                  title={'1.평가범위 결정 - ' + appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정']}
                  width={807}
                  height={319}
                  onClose={() => {
                    setShowModal(false);
                  }}
                >
                  <div className={`p-4`}>
                    {
                      appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'YES' &&
                      <>
                        <div
                          className={`flex items-center gap-1 mb-[24px] justify-center`}
                        >
                          <div>
                            n1 (토양정밀조사 시료개수) =
                            <p className={`h-[24px]`}></p>
                          </div>
                          <div>
                            <input
                              type="text"
                              className={`h-[68px] p-4 rounded border border-gray-300 w-[83px]`}
                              placeholder={`예)37`}
                              value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수']}
                              onChange={e => onInputChange(e.target.value)}
                            />
                            <p className={`text-red-600 h-[24px]`}>{error}</p>
                          </div>
                        </div>
                        <div
                          className={`bg-[#60448D] h-[43px] flex items-center justify-center rounded cursor-pointer text-white`}
                          onClick={() => checkValidate()}
                        >
                          확인 후 [2.변동계수 산정-기존조사 결과 있을 때] 이동
                        </div>
                      </>
                    }
                    {
                      appContext.appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'NO' &&
                      <>
                        <div
                          className={`flex items-center gap-1 mb-[24px] justify-center`}
                        >
                          <div>
                            (토양 정밀 조사 실시 ) =
                            <p className={`h-[24px]`}></p>
                          </div>
                          <div>
                            <input
                              type="text"
                              className={`h-[68px] p-4 rounded border border-gray-300 w-[83px]`}
                              placeholder={`예)37`}
                              value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수']}
                              onChange={e => onInputChange(e.target.value)}
                            />
                            <p className={`text-red-600 h-[24px]`}>{error}</p>
                          </div>
                        </div>
                        <div
                          className={`bg-[#60448D] h-[43px] flex items-center justify-center rounded cursor-pointer text-white`}
                          onClick={() => checkValidate()}
                        >
                          확인 후 [3.변동계수 산정-정밀조사 실시] 이동
                        </div>
                      </>
                    }
                  </div>
                </Modal>
              }
            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

export default Step1;
