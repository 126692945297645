import React from 'react';
import Step1 from "components/determining/Step1";
import Step2Sub1 from "components/determining/Step2Sub1";

export default ({}) => {
  return (
    <Step2Sub1 />
  );
};

