import React, {useContext, useEffect, useState} from 'react';
import {LayoutBox, LayoutBoxHeader, LayoutBoxHeaderH2, LayoutBoxHeaderRight, LayoutContentsHeader, LayoutContentsInner, LayoutContentsWrap} from "../../constants/LayoutCons";
import Side from "components/Side";
import {GetMenuUrl, } from "../../constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import EcArrowDown from 'img/ecArrowDown.png';
import {TableMinusIcon, TablePlusIcon} from "../../constants/KerasTable";
import * as lodash from "lodash";
import Decimal from "decimal.js";

export default ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const 토양독성실험종 = appContext.appData['토양독성실험종'];
  const 토양추출액독성실험종 = appContext.appData['토양추출액독성실험종'];
  const [최종데이터, set최종데이터] = useState({});

  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }
  // console.log(토양추출액독성실험종);

  useEffect(() => {
    let data = {'대조구역': {}, 'Site 1': {}, 'Site 2': {}, 'Site 3': {}, 'Site 4': {}, 'Site 5': {}, 'Site 6': {}};
    ['대조구역', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((시료명, 시료순번) => {
      토양독성실험종.map((실험, 실험순번) => {
        if(실험['checked'] === false) return
        if(실험['시료'][시료순번]) {
          data[시료명][실험['실험명']] = {'평균': 실험['시료'][시료순번]['%전환']};
        }
      });
      토양추출액독성실험종.map((실험, 실험순번) => {
        if(실험['checked'] === false) return
        if(실험['시료'][시료순번]) {
          data[시료명][실험['실험명']] = {'평균': 실험['시료'][시료순번]['%전환']};
        }
      });
    });

    Object.keys(data).map(시료명 => {
      let 토탈 = 0, 갯수 = 0;
      Object.keys(data[시료명]).map((실험명, 순번) => {
        // console.log(시료명, 실험명, 평균);
        // 2번값 추출
        const 평균 = Number((Number(data[시료명][실험명]['평균'])).toFixed(1));
        const 두번째값 =  new Decimal(new Decimal((100 - 평균)).div(100).toNumber());
        data[시료명][실험명] = lodash.merge({'두번째값': 두번째값.toNumber()}, data[시료명][실험명]);

        const 고정두번째값 = data['대조구역'][실험명]['두번째값'];
        const 세번째값 = new Decimal(new Decimal((두번째값 - 고정두번째값)).div((1 - 고정두번째값) === 0 ? 0 : 1 - 고정두번째값).toNumber());
        data[시료명][실험명] = lodash.merge({'세번째값': 세번째값.toNumber()}, data[시료명][실험명]);

        const 네번째값 = 세번째값.toNumber() < 0 ? new Decimal(0) : 세번째값;
        data[시료명][실험명] = lodash.merge({'네번째값': 네번째값.toNumber()}, data[시료명][실험명]);

        let 다섯번째값 = 네번째값.equals(1) ? -1 : Math.log10(1 - 네번째값.toNumber());
        data[시료명][실험명] = lodash.merge({'다섯번째값':다섯번째값.toFixed(2)}, data[시료명][실험명]);

        if(시료명 === 'Site 1')
          console.log(시료명, `${실험명}`, '> ', '평균', data[시료명][실험명]['평균'], 평균, '  두번째값', 두번째값.toNumber() , '  두번째고정값', 고정두번째값, '  세번째값',세번째값.toNumber(), '  네번째값',네번째값.toNumber(), '  다섯번째값', 다섯번째값);

        if(다섯번째값 !== -1) {
          ++갯수;
          토탈 += 다섯번째값;
        }
      });
      if(Object.keys(data[시료명]).length > 0) {
        토탈 = 토탈;
        data[시료명] = lodash.merge({'토탈': 토탈}, data[시료명]);
        data[시료명] = lodash.merge({'토탈평균': new Decimal(토탈).div(갯수).toNumber()}, data[시료명]);
        if(시료명 === 'Site 1') console.log(시료명, '토탈', 토탈, '갯수', 갯수, new Decimal(토탈).div(갯수).toNumber());
        data[시료명] = lodash.merge({'총평균': (1 - Math.pow(10, new Decimal(토탈).div(갯수).toNumber())).toFixed(3)}, data[시료명]);
      }
    });
    console.log(data);
    set최종데이터(data);
  }, []);

  // console.log(최종데이터);


  return (
    <div className="container my-7 px-0">
      <div className="my-5">
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <div className="">
                <div className={`bg-white text-sm mb-[10px]`}>
                  <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                    <div className={`w-[203px]`}></div>
                    <div className="flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs border-l border-[#cdcdcd]" onScroll={e => onScroll(e)}>
                      {
                        Object.keys(최종데이터).map((시료명, 순번) => {
                          return  Object.keys(최종데이터[시료명]).length > 0 && <div key={순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{시료명}</div>
                        })
                      }
                    </div>
                  </div>

                  {
                    토양독성실험종.map((실험, 실험순번) => {
                      const checked = 실험['checked'];
                      if( ! checked) return;
                      return checked && <div key={실험순번} className={`w-full flex px-[12px] items-center border-b border-[#CDCDCD]`}>
                        <div className={`w-[203px]`}>{실험['실험명']}</div>
                        <div className="w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                          <div className="flex gap-1 items-center">
                            {
                              실험['시료'].map((시료, 시료순번) => {
                                if(시료['%전환'] && Number(시료['%전환']) > 0) {
                                  return <div key={시료순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{Number(시료['%전환'])}</div>
                                } else {
                                  return <div key={시료순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>0</div>
                                }
                              })
                            }
                          </div>
                        </div>
                      </div>
                    })
                  }
                  {
                    토양추출액독성실험종.map((실험, 실험순번) => {
                      const checked = 실험['checked'];
                      if( ! checked) return;
                      return checked && <div key={실험순번} className={`w-full flex px-[12px] items-center border-b border-[#CDCDCD]`}>
                        <div className={`w-[203px]`}>{실험['실험명']}</div>
                        <div className="w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l">
                          <div className="flex gap-1 items-center">
                            {
                              실험['시료'].map((시료, 시료순번) => {
                                if(시료['%전환'] && Number(시료['%전환']) > 0) {
                                  return <div key={시료순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{Number(시료['%전환'])}</div>
                                } else {
                                  return <div key={시료순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>0</div>
                                }
                              })
                            }
                          </div>
                        </div>
                      </div>
                    })
                  }

                  <div className={`w-full p-[12px]`}>
                    <div className={`border-dashed border-gray-300 border-t-[3px]`}></div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#CDCDCD]`}>
                    <div className={`w-[203px]`}>생태독성 위해도</div>
                    <div className="w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l">
                      <div className="flex gap-1 py-[5px] items-center">
                        {
                          Object.keys(최종데이터).length > 0 && ['대조구역', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((시료명, i) => {
                            return  <div key={i} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{(최종데이터[시료명]['총평균'])}</div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

