import React, {useContext} from 'react';
import Side from "components/Side";
import {GetMenuUrl, 대조구역변동계수시료데이터, 토양농도} from "constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import {LayoutBox, LayoutContentsInner, LayoutContentsWrap, LayoutSubTitle} from "../../constants/LayoutCons";
import {CheckboxLabel, CheckboxWrap, FileInput, FormGroup, FormWrap} from "../../constants/FormConstants";
import AppContext from "../../contexts/AppContext";
import * as lodash from "lodash";
import {v4 as uuidv4} from "uuid";
import Toast from "../../util/Toast";
import {TableMinusIcon, TablePlusIcon} from "../../constants/KerasTable";
import {getBase64} from "../../util/FileUtil";
import KerasImage from "../../util/KerasImage";
import EmptyImage from "../../util/EmptyImage";
import axios from "axios";

export default  ({}) => {


  const appContext = useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pollutants = ['유기물질', '중금속', '기타'];

  const title = '대조구역 정보 입력';

  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }

  const checkPlaces = (value, flagPlace) => {
    if(typeof value !== 'string') {
      value = '' + value;
    }
    if(value.toString().indexOf('.') === -1) {
      return true;
    }
    return flagPlace >= value.split('.')[1].length;
  }

  const fixFloat = (value, place) => {
    const f = Math.pow(10, place);
    if(isNaN(parseFloat(value))) {
      return 0;
    }
    return checkPlaces(value, place) ?  value : parseFloat(Math.ceil(value * f) / f).toFixed(place);
  }

  const check = (type, key, checked) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질'][type][key] = checked;
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }


  const onChangeText = (key, field, value) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].map(o =>
      o.key === key ? {...o, [field]: value} : o
    );
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const onChangeText2 = (key, field, value, 순번) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].map(o =>{
      if(o.key === key) {
        o['토양농도'].map((o2, i) => {
          console.log(o2);
          if(i === 순번) {
            o2[field] = value;
          }
        })
      }
    });
    console.log(appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료']);
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const onChangeChecked = (key, checked) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].map(o =>
      o.key === key ? {...o, 'checked': checked} : o
    );
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const addTd = () => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].push({
      key: uuidv4(),
      ...대조구역변동계수시료데이터
    },);
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const removeTd = () => {
    try {
      let flag = false;
      appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].map(o => {
        if(o.checked === true) flag = true;
      })
      if(flag) {
        appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].filter(o => o.checked === false);
        appContext.setAppData(lodash.merge({}, appContext.appData));
      } else {
        Toast.error('삭제 할 항목을 선택 후 행삭제해 주세요.');
      }
    } catch (e) {
      Toast.error('행삭제 실패');
    }
  }

  const calc = () => {
    try {
      // 평균
      let length = 0

      appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].map(o => {
        length += o['토양농도'].length;
      });

      pollutants.map(pollutant => {
        Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질'][pollutant]).map(v => {
          if(appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질'][pollutant][v] === true) {
            let total = 0;
            appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].map(o => {
              o['토양농도'].map(o2 => {
                if(parseFloat(o2[v])) {
                  total += parseFloat(o2[v]);
                }
              })
            });
            appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['평균'][v] = fixFloat(total / length, 2);
          }
        })
      })

      appContext.setAppData(lodash.merge({}, appContext.appData));
    } catch (e) {
      console.log(e);
      Toast.error('계산 실패!');
    }
  }

  const allCheck = (checked) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].map(o => {
      return {...o, checked: checked}
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const 토양농도추가 = (key) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].map(o => {
      if(o.key === key) {
        o['토양농도'].push(토양농도)
      }
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const 토양농도삭제 = (key, 순번) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].map(o => {
      if(o.key === key) {
        o['토양농도'] = o['토양농도'].filter((b, i) => {
          return i !== 순번;
        })
      }
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }


  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <FormWrap>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 1.유기물질</LayoutSubTitle>
                  <CheckboxWrap>
                    {
                      Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질']['유기물질']).map(key => {
                        return <React.Fragment key={key}>
                          <CheckboxLabel>
                            <input type="checkbox" checked={appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질']['유기물질'][key]} name={'유기물질'} value={key} onChange={e => check('유기물질', key, e.target.checked)}/> {key}
                          </CheckboxLabel>
                        </React.Fragment>
                      })
                    }
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 2.중금속</LayoutSubTitle>
                  <CheckboxWrap>
                    {
                      Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질']['중금속']).map(key => {
                        return <React.Fragment key={key}>
                          <CheckboxLabel>
                            <input type="checkbox" checked={appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질']['중금속'][key]} name={'중금속'} value={key} onChange={e => check('중금속', key, e.target.checked)}/> {key}
                          </CheckboxLabel>
                        </React.Fragment>
                      })
                    }
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 3.기타</LayoutSubTitle>
                  <CheckboxWrap>
                    {
                      Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질']['기타']).map(key => {
                        return <React.Fragment key={key}>
                          <CheckboxLabel>
                            <input type="checkbox" checked={appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질']['기타'][key]} name={'기타'} value={key} onChange={e => check('기타', key, e.target.checked)}/> {key}
                          </CheckboxLabel>
                        </React.Fragment>
                      })
                    }
                  </CheckboxWrap>
                </FormGroup>
              </FormWrap>

              <div className={`bg-white text-sm`}>
                <div className={`flex justify-between py-[6px] px-[20px] border-t border-black`}>
                  <div className="flex gap-[5px]">
                    <button
                      type={'button'}
                      className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                      onClick={addTd}
                    >
                      행추가
                    </button>
                    <button
                      type={'button'}
                      className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                      onClick={removeTd}
                    >
                      행삭제
                    </button>
                  </div>
                  <button
                    type={'button'}
                    className={`font-normal bg-[#60448D] w-[94px] h-[37px] rounded-[5px] text-[16px] text-white`}
                    onClick={calc}
                  >
                    계산
                  </button>
                </div>
                <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                  <div className={`mr-2.5`}>
                    <input type="checkbox" onChange={e => allCheck(e.target.checked)}/>
                  </div>
                  <div className={`w-[80px] mr-2.5`}>시료명</div>
                  <div className={`w-[200px] flex flex-col`}>
                    <div className={`flex border-b border-[#CDCDCD]`}>
                      <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                      <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                    </div>
                    <div className={`flex pb-[15px]`}>
                      <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                      <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                    </div>
                  </div>
                  <div className={`flex-1 overflow-hidden  text-center border-l border-[#CDCDCD]`}>
                    <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                    <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                      <div className={`flex-[30px] flex-shrink-0 flex-grow-0`}></div>
                      {
                        pollutants.map(pollutant => {
                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질'][pollutant]).map(key => {
                            return appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                          })
                        })
                      }
                    </div>
                  </div>
                </div>

                {
                  appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['시료'].map(o => {
                    return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                      <div className={`mr-2.5`}>
                        <input
                          type="checkbox"
                          checked={o.checked}
                          onChange={e => onChangeChecked(o.key, e.target.checked)}
                        />
                      </div>
                      <div className={`w-[80px] mr-2.5`}>
                        <input
                          type="text"
                          className={`p-2.5 border border-[#CDCDCD] rounded-[5px] h-[80px] w-[80px]`}
                          placeholder={'시료명'}
                          value={o['시료명']}
                          onChange={e => onChangeText(o.key, '시료명', e.target.value)}
                        />
                      </div>
                      <div className={`w-[200px] flex flex-col`}>
                        <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                          <div className={`flex-1 gap-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'위도'}
                              value={o['위도']}
                              onChange={e => onChangeText(o.key, '위도', fixFloat(e.target.value, 5))}
                            />
                          </div>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'경사(%)'}
                              value={o['경사']}
                              onChange={e => onChangeText(o.key, '경사', fixFloat(e.target.value, 1))}
                            />
                          </div>
                        </div>
                        <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'경도'}
                              value={o['경도']}
                              onChange={e => onChangeText(o.key, '경도', fixFloat(e.target.value, 4))}
                            />
                          </div>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'고도(m)'}
                              value={o['고도']}
                              onChange={e => onChangeText(o.key, '고도', parseInt(e.target.value || 0))}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`flex-1 min-h-[93px] flex items-center w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center border-l border-[#CDCDCD] scs`} onScroll={e => onScroll(e)}>
                        <div className="flex flex-col gap-1 pl-[8px] py-[5px]">
                          {
                            o['토양농도'].map((토양농도, 순번) => {
                              return <div className="flex gap-1 py-[5px] items-center" key={순번}>
                                <div className={`flex-[20px] flex-shrink-0 flex-grow-0`}>
                                  {
                                    순번 === 0 ?
                                      <img src={TablePlusIcon} alt="" className="cursor-pointer" onClick={e => 토양농도추가(o.key)}/> :
                                      <img src={TableMinusIcon} alt="" className="cursor-pointer" onClick={e => 토양농도삭제(o.key, 순번)}/>
                                  }
                                </div>
                                {
                                  pollutants.map(pollutant => {
                                    return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질'][pollutant]).map(key => {
                                      return appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질'][pollutant][key] === true &&
                                        <div className={``} key={key}>
                                          <input
                                            type="text"
                                            className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                            value={토양농도[key]}
                                            onChange={e => onChangeText2(o.key, key, fixFloat(e.target.value, 1), 순번)}
                                          />
                                        </div>
                                    })
                                  })
                                }
                              </div>
                            })
                          }
                        </div>
                      </div>
                    </div>
                  })
                }

                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>평균(m)</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      <div className={`flex-[20px] flex-shrink-0 flex-grow-0`}></div>
                      {
                        pollutants.map(pollutant => {
                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질'][pollutant]).map(key => {
                            return appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['오염물질'][pollutant][key] === true &&
                              <div className={``} key={key}>
                                <input
                                  type="text"
                                  className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                  readOnly={true}
                                  value={appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['평균'][key] === false ?
                                    '계산전' :
                                    appContext.appData['평가범위 결정 및 현장조사 계획수립'][title]['평균'][key]}/>
                              </div>
                          })
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>



              <div className="pt-[10px]">
                <FormWrap>
                  <FormGroup>
                    <LayoutSubTitle>대조구역</LayoutSubTitle>
                  </FormGroup>
                  <FormGroup>
                    <FileInput
                      accept={'image/*'}
                      fileName={appContext.appData['대조구역 지점 표기 이미지'] && appContext.appData['대조구역 지점 표기 이미지']['name']}
                      onChange={e => {
                        const file = e.target.files[0];
                        const formData = new FormData();
                        formData.append("file", file);
                        axios({
                          method:'post',
                          url:'https://api.keras-b.7greenus.com/uploads',
                          data: formData,
                        }).then(({data, status}) => {
                          if(status === 200 && data.message === '성공') {
                            if(appContext.appData['대조구역 지점 표기 이미지'] === undefined) {
                              appContext.appData['대조구역 지점 표기 이미지'] = {};
                            }
                            appContext.appData['대조구역 지점 표기 이미지']['image'] = data.uploadurl;
                            appContext.appData['대조구역 지점 표기 이미지']['name'] = file.name;
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }
                        }).catch(err => {
                          console.log(err);
                        })
                      }}
                      onDelete={e => {
                        appContext.appData['대조구역 지점 표기 이미지']['image'] = '';
                        appContext.appData['대조구역 지점 표기 이미지']['name'] = '';
                        appContext.setAppData(lodash.merge({}, appContext.appData));
                      }}
                    />
                    {
                      (appContext.appData['대조구역 지점 표기 이미지'] && appContext.appData['대조구역 지점 표기 이미지']['image']) ?
                        <KerasImage
                          image={appContext.appData['대조구역 지점 표기 이미지']['image']}
                          cls={`w-full block mt-6 aspect-[828/419] object-cover cursor-pointer`}
                          alt={`토지이용계획 이미지`}
                        />
                        :
                        <EmptyImage
                          cls={`w-full mt-6 aspect-[828/419] bg-[#d9d9d9] flex rounded items-center justify-center`}
                          txt={`(이미지 없음)`}
                        />
                    }
                  </FormGroup>
                </FormWrap>
              </div>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

