import React, {useContext} from 'react';
import {LayoutBox, LayoutBoxHeader, LayoutBoxHeaderH2, LayoutBoxHeaderRight, LayoutContentsHeader, LayoutContentsInner, LayoutContentsWrap} from "../../constants/LayoutCons";
import Side from "components/Side";
import {GetMenuUrl, } from "../../constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import EcArrowDown from 'img/ecArrowDown.png';
import {TableMinusIcon, TablePlusIcon} from "../../constants/KerasTable";
import * as lodash from "lodash";
import Decimal from "decimal.js";
import {v4 as uuidv4} from "uuid";

export default ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const 엑셀데이터처리함수 = (data) => {
    if(Array.isArray(data) === false) return;
    if(data.length === 0) return;
    appContext.appData['톡토기 종 풍부도']['시료'] = [];
    let insertData = [];
    data.map(row => {
      if(insertData[row['con']] === undefined) {
        insertData[row['con']] = [];
      }
      insertData[row['con']].push([row['값']]);
    });
    Object.keys(insertData).map((시료명) => {
      console.log('insertData[시료명]', insertData[시료명]);
      appContext.appData['톡토기 종 풍부도']['시료'].push({
          key: uuidv4(),
          'checked': false,
          '시료명': 시료명,
          '평균': 0,
          '%전환': 0,
          '데이터': insertData[시료명]
        }
      );
    });
    calc();
  }


  const calc = () => {
    const 시료데이터 = appContext.appData['톡토기 종 풍부도']['시료'];
    시료데이터.map(시료 => {
      const 데이터배열 = 시료['데이터'];
      let total = 0, length = 데이터배열.length;
      데이터배열.map(데이터 => {
        데이터.map((num, i) => {
          try {
            total = new Decimal(num).plus(total).toNumber();
          } catch (e) {}
        });
      });
      시료['평균'] = new Decimal(total).div(length).toNumber().toFixed(2);

      const 대조구역평균 = 시료데이터[0]['평균'];
      시료['%전환'] = new Decimal(시료['평균']).div(대조구역평균).mul(100).toNumber().toFixed(2);
      if(new Decimal(시료['평균']).div(대조구역평균).mul(100).equals(0)) {
        시료['%전환'] = 0.001;
      }
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const fixFloat = (value, place) => {
    const f = Math.pow(10, place);
    if(isNaN(parseFloat(value))) {
      return 0;
    }
    return checkPlaces(value, place) ?  value : parseFloat(Math.ceil(value * f) / f).toFixed(place);
  }

  const checkPlaces = (value, flagPlace) => {
    if(typeof value !== 'string') {
      value = '' + value;
    }
    if(value.toString().indexOf('.') === -1) {
      return true;
    }
    return flagPlace >= value.split('.')[1].length;
  }

  return (
    <div className="container my-7 px-0">
      <div className="my-5">
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>

              <div className="">
                <div className={`bg-white text-sm mb-[10px]`}>
                  <div className="sticky top-0 bg-white">
                    <div className={`flex justify-between py-[6px] px-[20px] border-t border-black`}>
                      <div className="flex gap-[5px]">
                        <button
                          type={'button'}
                          className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                          onClick={e => {
                            appContext.appData['톡토기 종 풍부도']['시료'].push({
                              key: uuidv4(),
                              'checked': false,
                              '시료명': '',
                              '평균': 0,
                              '%전환': 0,
                              '데이터': [
                                [0],
                              ]
                            });
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        >
                          행추가
                        </button>
                        <button
                          type={'button'}
                          className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                          onClick={e => {
                            appContext.appData['톡토기 종 풍부도']['시료'] = appContext.appData['톡토기 종 풍부도']['시료'].filter(o => ! o.checked);
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        >
                          행삭제
                        </button>
                      </div>
                      <button
                        type={'button'}
                        className={`font-normal bg-[#60448D] w-[204px] h-[37px] rounded-[5px] text-[16px] text-white`}
                        onClick={() => {
                          appContext.importExcel(엑셀데이터처리함수);
                        }}
                      >
                        엑셀 데이터 가져오기
                      </button>
                    </div>
                    <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                      <div className={`mr-2.5`}>
                        <input
                          type="checkbox"
                          onClick={e => {
                            appContext.appData['톡토기 종 풍부도']['시료'].map(o => {
                              o['checked'] = e.target.checked;
                            });
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        />
                      </div>
                      <div className={`w-[80px] mr-2.5`}>톡토기 종 <br/>풍부도</div>
                      <div className={`w-[80px] mr-2.5`}>평균</div>
                      <div className={`w-[80px] mr-2.5`}>%전환</div>
                      <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center border-l border-[#CDCDCD]`}>
                        <div className="flex gap-1 pl-[8px] py-2 items-center">
                          <div className={`flex-[20px] flex-shrink-0 flex-grow-0`}></div>
                          <div className={`flex-[100px] flex-shrink-0 flex-grow-0`}>
                            Abundance (ind m-2)<br/>Collembola
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  {
                    appContext.appData['톡토기 종 풍부도']['시료'].map((시료, 시료순번) => {
                      return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={시료['key']}>
                        <div className={`mr-2.5`}>
                          <input
                            type="checkbox"
                            checked={시료['checked']}
                            onChange={e => {
                              시료['checked'] = e.target.checked;
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}
                          />
                        </div>
                        <div className={`w-[80px] mr-2.5`}>
                          <input
                            type="text"
                            className="border border-[#CDCDCD] rounded-[5px] w-[80px]"
                            value={시료['시료명']}
                            onChange={e => {
                              appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['시료명'] = e.target.value;
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}
                            style={{height: `${appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['데이터'].length * 30}px`}}
                          />
                        </div>
                        <div className={`w-[80px] mr-2.5`}>
                          <input
                            type="text"
                            className="border border-[#CDCDCD] rounded-[5px] w-[80px]"
                            value={시료['평균']}
                            onChange={e => {
                              appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['평균'] = e.target.value;
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}
                            style={{height: `${appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['데이터'].length * 30}px`}}
                          />
                        </div>
                        <div className={`w-[80px] mr-2.5`}>
                          <input
                            type="text"
                            className="border border-[#CDCDCD] rounded-[5px] w-[80px]"
                            value={시료['%전환']}
                            onChange={e => {
                              appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['%전환'] = e.target.value;
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}
                            style={{height: `${appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['데이터'].length * 30}px`}}
                          />
                        </div>
                        <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center border-l border-[#CDCDCD]`}>
                          <div className="flex flex-col gap-1 pl-[8px] py-2">
                            {
                              appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['데이터'].map((데이터, 데이터순번) => {
                                return <div className="flex w-[497px] gap-1 py-[5px] items-center" key={데이터순번}>
                                  <div className={`flex-[20px] flex-shrink-0 flex-grow-0`}>
                                    {
                                      데이터순번 === 0 ? <img src={TablePlusIcon} alt="" className="cursor-pointer" onClick={e => {
                                          appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['데이터'].push([0]);
                                          appContext.setAppData(lodash.merge({}, appContext.appData));
                                        }}/>
                                        : <img src={TableMinusIcon} alt="" className="cursor-pointer" onClick={e => {
                                          appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['데이터'].splice(데이터순번, 1);
                                          appContext.setAppData(lodash.merge({}, appContext.appData));
                                        }}/>
                                    }
                                  </div>
                                  {
                                    데이터.length > 0 && 데이터.map((num, i) => {
                                      return <div key={i}><input type="text" className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`} value={num} onChange={e => {
                                        // appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['데이터'][데이터순번][i] = e.target.value.replace(/[^-0-9]/g, '');
                                        appContext.appData['톡토기 종 풍부도']['시료'][시료순번]['데이터'][데이터순번][i] = e.target.value;
                                        appContext.setAppData(lodash.merge({}, appContext.appData));

                                        calc();
                                      }}/></div>
                                    })
                                  }
                                </div>
                              })
                            }
                          </div>
                        </div>
                      </div>
                    })
                  }
                </div>
              </div>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

