import React, {useContext, useEffect, useState} from 'react';
import Side from "components/Side";
import {FileInput, FormGroup, FormHalf, FormWrap, RadioColWrap, RadioLabel,} from "../../constants/FormConstants";
import LiteratureImg05 from 'img/literatureImg05.png';
import {GetMenuUrl} from "constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import {
  LayoutBox,
  LayoutBoxHeader,
  LayoutBoxHeaderH2,
  LayoutBoxHeaderRight,
  LayoutContentsHeader,
  LayoutContentsInner,
  LayoutContentsWrap,
  LayoutSubTitle
} from "../../constants/LayoutCons";
import AppContext from "../../contexts/AppContext";
import * as lodash from "lodash";

import {Map, MapMarker} from "react-kakao-maps-sdk"


const Step2 = ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const mapMove = () => {
    const map = new window.ol.Map({
      id: 'map',
      target: 'map',
      renderer: 'canvas',
      view: new window.ol.View({
        center: window.ol.proj.fromLonLat([127.5, 36]),
        zoom: 7,
        minZoom: 6,
        maxZoom: 19,
        projection: "EPSG:3857",
        extent: [13678546.51713, 3834188.8033424, 14854453.760059, 5314661.8558898]
      })
    });


    const markerVectorLayer = new window.ol.layer.Vector({
      source: new window.ol.source.Vector(),
      zIndex: 999
    });

    map.addLayer(markerVectorLayer);

    var scaleControl = new window.ol.control.ScaleLine({units: 'metric'});
    map.addControl(scaleControl);
    var zoomSliderControl = new window.ol.control.ZoomSlider();
    map.addControl(zoomSliderControl);

    // Add layer
    var layer = window.ecvamLayerCreate('ecvam', 'true');
    map.addLayer(layer);

    const addressInput = appContext.appData['문헌조사']['대상부지 기본정보']['주소'];
    if(addressInput) {
      const geocoder = new window.kakao.maps.services.Geocoder();
      geocoder.addressSearch(addressInput, function (result, status) {
        if (status === window.kakao.maps.services.Status.OK) {
          const coords = result[0];
          const lat = parseFloat(coords.y);
          const lng = parseFloat(coords.x);

          if (!isNaN(lat) && !isNaN(lng)) {
            // Transform the coordinates from EPSG:4326 to EPSG:3857 (Web Mercator)
            let transformedCoordinates = window.ol.proj.transform([lng, lat], 'EPSG:4326', 'EPSG:3857');
            map.getView().setCenter(transformedCoordinates);
            map.getView().setZoom(14);

            markerVectorLayer.getSource().clear();

            var style = new window.ol.style.Style({
              image: new window.ol.style.Icon({
                anchor: [0.5, 1],
                scale: 0.8,
                src: 'https://openlayers.org/en/latest/examples/data/icon.png'
              })
            });

            var marker = new window.ol.Feature({
              geometry: new window.ol.geom.Point(transformedCoordinates)
            });
            marker.setStyle(style);

            markerVectorLayer.getSource().addFeature(marker);

          } else {
            alert("Invalid coordinates. Please enter a valid address.");
          }
        } else {
          alert("Address not found. Please enter a valid address.");
        }
      });
    }
  }

  useEffect(() => {
    console.log(1);
    try {
      console.log(2);
      mapMove();
    } catch (e) {
      console.log(e);
      window.onload = () => {
        console.log(4);
        mapMove();
      }
    }
  }, []);


  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <FormWrap>
                <LayoutSubTitle>평가지도</LayoutSubTitle>
                <FormHalf>
                  <div className={`flex-1`}>
                    <FormWrap>
                      <FormGroup>
                        <div id={`map`} className={`h-[854px]`}></div>
                      </FormGroup>
                    </FormWrap>
                  </div>
                  <div className={`flex-1`}>
                    <FormWrap>
                      <FormGroup>
                        <LayoutSubTitle>대상부지</LayoutSubTitle>
                        <RadioColWrap>
                          {
                            Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['대상부지']).map(k => {
                              return <RadioLabel key={k}>
                                <input type="radio" name={'a'} checked={appContext.appData['문헌조사']['국토환경성 평가지도']['대상부지'][k]} onChange={e => {
                                  Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['대상부지']).map(k2 => {
                                    if(k === k2) {
                                      appContext.appData['문헌조사']['국토환경성 평가지도']['대상부지'][k2] = true;
                                    } else {
                                      appContext.appData['문헌조사']['국토환경성 평가지도']['대상부지'][k2] = false;
                                    }
                                  });
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }}/> {k}
                              </RadioLabel>
                            })
                          }
                        </RadioColWrap>
                      </FormGroup>
                      <FormGroup>
                        <LayoutSubTitle>부지 인근 1km</LayoutSubTitle>
                        <RadioColWrap>
                          {
                            Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km']).map(k => {
                              return <RadioLabel key={k}>
                                <input type="radio" name={'b'} checked={appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km'][k]} onChange={e => {
                                  Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km']).map(k2 => {
                                    if(k === k2) {
                                      appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km'][k2] = true;
                                    } else {
                                      appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 1km'][k2] = false;
                                    }
                                  });
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }}/> {k}
                              </RadioLabel>
                            })
                          }
                        </RadioColWrap>
                      </FormGroup>
                      <FormGroup>
                        <LayoutSubTitle>부지 인근 5km</LayoutSubTitle>
                        <RadioColWrap>
                          {
                            Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 5km']).map(k => {
                              return <RadioLabel key={k}>
                                <input type="radio" name={'c'} checked={appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 5km'][k]} onChange={e => {
                                  Object.keys(appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 5km']).map(k2 => {
                                    if(k === k2) {
                                      appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 5km'][k2] = true;
                                    } else {
                                      appContext.appData['문헌조사']['국토환경성 평가지도']['부지 인근 5km'][k2] = false;
                                    }
                                  });
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }}/> {k}
                              </RadioLabel>
                            })
                          }
                        </RadioColWrap>
                      </FormGroup>
                      <FormGroup>
                        <LayoutSubTitle>비고</LayoutSubTitle>
                        <div>
                          <textarea
                            placeholder={`(특이사항 직접입력, 예) 국립공원 및 생태민감 지역 기술))`}
                            className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                            value={appContext.appData['문헌조사']['국토환경성 평가지도']['비고']}
                            onChange={e => {
                              appContext.appData['문헌조사']['국토환경성 평가지도']['비고'] = e.target.value;
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}
                          ></textarea>
                        </div>
                      </FormGroup>
                    </FormWrap>
                  </div>
                </FormHalf>
              </FormWrap>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

export default Step2;
