import React, {useContext} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import {LayoutBox, LayoutBoxHeader, LayoutBoxHeaderH2, LayoutBoxHeaderRight, LayoutContentsHeader, LayoutContentsInner, LayoutContentsWrap} from "../../constants/LayoutCons";
import Side from "components/Side";
import {GetMenuUrl} from "../../constants/MenuCons";
import {CheckboxLabel, CheckboxWrap, RadioColWrap, RadioLabel, RadioWrap} from "../../constants/FormConstants";
import * as lodash from "lodash";

export default ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const 토양독성실험종 = appContext.appData['토양독성실험종'];
  const 토양추출액독성실험종 = appContext.appData['토양추출액독성실험종'];

  return (
    <div className="container my-7 px-0">
      <div className="my-5">
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <div className="mb-2">생태독성실험종</div>
              <div className="mb-[10px]">
                <CheckboxWrap>
                  {
                    토양독성실험종.map((실험, 순번) => {
                      return <CheckboxLabel key={순번}>
                        <input type="checkbox" name={'c'} checked={실험['checked']} onChange={e => {
                          실험['checked'] = e.target.checked;
                          appContext.setAppData(lodash.merge({}, appContext.appData));
                        }}/> {실험['실험명']}
                      </CheckboxLabel>
                    })
                  }
                </CheckboxWrap>
              </div>

              <div className="mb-2">토양추출액독성실험종</div>
              <div className="mb-[10px]">
                <CheckboxWrap>
                  {
                    토양추출액독성실험종.map((실험, 순번) => {
                      return <CheckboxLabel key={순번}>
                        <input type="checkbox" name={'c'} checked={실험['checked']} onChange={e => {
                          실험['checked'] = e.target.checked;
                          appContext.setAppData(lodash.merge({}, appContext.appData));
                        }}/> {실험['실험명']}
                      </CheckboxLabel>
                    })
                  }
                </CheckboxWrap>
              </div>
            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

