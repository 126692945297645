import React from 'react';
import {Route, Routes} from "react-router-dom";
import Step1 from "components/ecotoxicity/Step1";

const Step1Page = ({}) => {
  return (
    <Routes>
      <Route path="" element={<Step1 />} />
      <Route path=":num" element={<Step1 />} />
    </Routes>
  );
};

export default Step1Page;
