import React, {useContext} from 'react';
import Side from "components/Side";
import {FileInput, FormGroup, FormHalf, FormWrap, RadioColWrap, RadioLabel,} from "../../constants/FormConstants";
import {useLocation, useNavigate} from "react-router-dom";
import {LayoutBox, LayoutContentsInner, LayoutContentsWrap, LayoutSubTitle} from "../../constants/LayoutCons";
import AppContext from "../../contexts/AppContext";
import * as lodash from "lodash";
import {getBase64} from "../../util/FileUtil";
import KerasImage from "../../util/KerasImage";
import EmptyImage from "../../util/EmptyImage";
import axios from "axios";

const Step3 = ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <FormWrap>
                <LayoutSubTitle>생태/자연도</LayoutSubTitle>
                <FormHalf>
                  <div className={`flex-1`}>
                    <FormWrap>
                      <FormGroup>
                        <FileInput
                          accept={'image/*'}
                          fileName={appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지'] && appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지']['name']}
                          onChange={e => {
                            const file = e.target.files[0];
                            const formData = new FormData();
                            formData.append("file", file);
                            axios({
                              method:'post',
                              url:'https://api.keras-b.7greenus.com/uploads',
                              data: formData,
                            }).then(({data, status}) => {
                              if(status === 200 && data.message === '성공') {
                                if(appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지'] === undefined) {
                                  appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지'] = {};
                                }
                                appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지']['image'] = data.uploadurl;
                                appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지']['name'] = file.name;
                                appContext.setAppData(lodash.merge({}, appContext.appData));
                              }
                            }).catch(err => {
                              console.log(err);
                            })
                          }}
                          onDelete={e => {
                            appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지']['image'] = '';
                            appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지']['name'] = '';
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        />
                        {
                          (appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지'] && appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지']['image']) ?
                            <KerasImage
                              image={appContext.appData['문헌조사']['생태적 가치평가']['생태/자연도 이미지']['image']}
                              cls={`w-full block mt-6 aspect-[406/766] object-cover cursor-pointer`}
                              alt={`토지이용계획 이미지`}
                            />
                            :
                            <EmptyImage
                              cls={`w-full mt-6 aspect-[406/766] bg-[#d9d9d9] flex rounded items-center justify-center`}
                              txt={`(이미지 없음)`}
                            />
                        }
                      </FormGroup>
                    </FormWrap>
                  </div>
                  <div className={`flex-1`}>
                    <FormWrap>
                      <FormGroup>
                        <LayoutSubTitle>대상부지</LayoutSubTitle>
                        <RadioColWrap>
                          {
                            Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['대상부지']).map(k => {
                              return <RadioLabel key={k}>
                                <input type="radio" name={'a'} checked={appContext.appData['문헌조사']['생태적 가치평가']['대상부지'][k]} onChange={e => {
                                  Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['대상부지']).map(k2 => {
                                    if(k === k2) {
                                      appContext.appData['문헌조사']['생태적 가치평가']['대상부지'][k2] = true;
                                    } else {
                                      appContext.appData['문헌조사']['생태적 가치평가']['대상부지'][k2] = false;
                                    }
                                  });
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }}/> {k}
                              </RadioLabel>
                            })
                          }
                        </RadioColWrap>
                      </FormGroup>
                      <FormGroup>
                        <LayoutSubTitle>부지 인근 1km</LayoutSubTitle>
                        <RadioColWrap>
                          {
                            Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km']).map(k => {
                              return <RadioLabel key={k}>
                                <input type="radio" name={'b'} checked={appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km'][k]} onChange={e => {
                                  Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km']).map(k2 => {
                                    if(k === k2) {
                                      appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km'][k2] = true;
                                    } else {
                                      appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 1km'][k2] = false;
                                    }
                                  });
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }}/> {k}
                              </RadioLabel>
                            })
                          }
                        </RadioColWrap>
                      </FormGroup>
                      <FormGroup>
                        <LayoutSubTitle>부지 인근 5km</LayoutSubTitle>
                        <RadioColWrap>
                          {
                            Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km']).map(k => {
                              return <RadioLabel key={k}>
                                <input type="radio" name={'c'} checked={appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km'][k]} onChange={e => {
                                  Object.keys(appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km']).map(k2 => {
                                    if(k === k2) {
                                      appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km'][k2] = true;
                                    } else {
                                      appContext.appData['문헌조사']['생태적 가치평가']['부지 인근 5km'][k2] = false;
                                    }
                                  });
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }}/> {k}
                              </RadioLabel>
                            })
                          }
                        </RadioColWrap>
                      </FormGroup>
                      <FormGroup>
                        <LayoutSubTitle>비고</LayoutSubTitle>
                        <div>
                          <textarea
                            placeholder={`(특이사항 직접입력, 예) 국립공원 및 생태민감 지역 기술))`}
                            className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                            value={appContext.appData['문헌조사']['생태적 가치평가']['비고']}
                            onChange={e => {
                              appContext.appData['문헌조사']['생태적 가치평가']['비고'] = e.target.value;
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}
                          />
                        </div>
                      </FormGroup>
                    </FormWrap>
                  </div>
                </FormHalf>
              </FormWrap>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

export default Step3;
