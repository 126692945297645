import React from 'react';
import Step2 from "components/literature/Step2";

const Step2Page = ({}) => {
  return (
    <Step2 />
  );
};

export default Step2Page;
