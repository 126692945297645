import React from 'react';
import Step1 from "components/investigation/Step1";

const Step1Page = ({}) => {
  return (
    <Step1 />
  );
};

export default Step1Page;
