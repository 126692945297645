import React, {useContext, useEffect, useState} from 'react';
import SideDot from 'img/sideDot.png';
import SideInnerInnerArrow from "img/side-inner-inner-arrow.png";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {SideData} from "../constants/MenuCons";
import AppContext from "../contexts/AppContext";
import SideTitleHeaderIcon from 'img/sideTitleHeaderIcon.png';
import SideArrowP from 'img/sideArrowP.png';

const SideWrap = styled.div``

const Side = () => {

  const appContext = useContext(AppContext);
  const { pathname } = useLocation();
  const [basicActive, setBasicActive] = useState(false);
  const [ecological, setEcological] = useState(false);
  const navigate = useNavigate();

  const 토양독성실험종 = appContext.appData['토양독성실험종'];
  const 토양추출액독성실험종 = appContext.appData['토양추출액독성실험종'];

  useEffect(() => {
    if(pathname.split('/').length > 0) {
      const firstPath = pathname.split('/')[1];
      switch (firstPath) {
        case 'literature':
        case 'ecological':
        case 'determining':
        case 'investigation':
        case 'final':
          setBasicActive(true);
          break;
        case 'chemistry':
        case 'ecotoxicity':
        case 'riskecological':
        case 'integrated':
        case 'finalreport':
          setEcological(true);
          break;
      }
    }
  }, [pathname]);

  return (
    <SideWrap className="w-[350px] rounded py-[14px] px-[26px] text-[#444444] text-[18px]">
      <h2 className="text-[24px] font-bold mb-2 cursor-pointer text-[#60448D] flex items-center gap-2" onClick={() => {
        navigate(`/literature/step1`);
      }}><img src={SideTitleHeaderIcon} alt="" className={`w-[12px] h-[29px]`}/> 기초조사</h2>
      {
        basicActive && <>
          <ul className="flex flex-col gap-2">
            {
              Object.keys(SideData['기초조사']).map(key => {
                return <li key={key}>
                  <div className="flex items-start">
                    <img src={SideArrowP} alt="" className="w-[24px] h-[24px]"/>
                    <span onClick={e => {
                      if(SideData['기초조사'][key]['sub']) {
                        navigate(`/${key}/step1`);
                      } else {
                        navigate(`/${key}`);
                      }
                    }} className={`cursor-pointer font-bold ${pathname.includes(key) > 0 ? 'text-[#60448D]' : 'gsdf'}`}>{SideData['기초조사'][key]['name']}</span>
                  </div>
                  {
                    SideData['기초조사'][key]?.sub &&
                    <ul className="mt-1 pl-11 flex flex-col gap-1 list-decimal">
                      {
                        Object.keys(SideData['기초조사'][key]['sub']).map(subKey => {
                          const sub = SideData['기초조사'][key]['sub'];
                          return <li key={subKey}>
                            <div className="flex gap-2 cursor-pointer items-start">
                              <span onClick={e => {
                                navigate(`/${key}/${subKey}`);
                              }} className={`whitespace-pre ${pathname === `/${key}/${subKey}` ? 'font-bold text-[#60448D] underline' : ''}`}>{sub[subKey].name}</span>
                            </div>
                            {
                              SideData['기초조사'][key]['sub'][subKey]?.sub &&
                              <ul className="mt-1 flex flex-col gap-1">
                                {
                                  Object.keys(SideData['기초조사'][key]['sub'][subKey].sub).map(subSubKey => {
                                    return <li key={subSubKey}>
                                      <div className="flex gap-2 cursor-pointer items-center">
                                        <img src={SideDot} alt="" style={{width: 3}}/>
                                        <span onClick={e => {
                                          navigate(`/${key}/${subKey}/${subSubKey}`);
                                        }} className={`${pathname === `/${key}/${subKey}/${subSubKey}` ? 'font-bold text-[#60448D]' : ''}`}>{SideData['기초조사'][key]['sub'][subKey].sub[subSubKey].name}</span>
                                      </div>
                                    </li>
                                  })
                                }
                              </ul>
                            }
                          </li>
                        })
                      }
                    </ul>
                  }
                </li>
              })
            }
          </ul>
        </>
      }
      <div className="mt-7"></div>
      <h2 className="text-[24px] font-bold mb-2.5 cursor-pointer text-[#60448D] flex items-center gap-2" onClick={() => {
        navigate(`/chemistry/step1`);
      }}><img src={SideTitleHeaderIcon} alt="" className={`w-[12px] h-[29px]`}/> 생태위해성평가</h2>
      {
        ecological && <>
          <ul className="flex flex-col gap-2">
            {
              Object.keys(SideData['생태위해성평가']).map(key => {
                return <li key={key}>
                  <div className="flex items-start">
                    <img src={SideArrowP} alt="" className="w-[24px] h-[24px]"/>
                    <span onClick={e => {
                      if(SideData['생태위해성평가'][key]['sub'] && key !== 'ecotoxicity') {
                        navigate(`/${key}/step1`);
                      } else {
                        navigate(`/${key}`);
                      }
                    }} className={`cursor-pointer font-bold ${pathname.includes(key) > 0 ? 'text-[#60448D]' : 'gsdf'}`}>{SideData['생태위해성평가'][key]['name']}</span>
                  </div>
                  {
                    SideData['생태위해성평가'][key]?.sub &&
                    <ul className="mt-1 pl-7 flex flex-col gap-1">
                      {
                        Object.keys(SideData['생태위해성평가'][key]['sub']).map(subKey => {
                          const sub = SideData['생태위해성평가'][key]['sub'];
                          return <li key={subKey}>
                            <div className="flex gap-2 cursor-pointer items-start">
                              <span onClick={e => {
                                navigate(`/${key}/${subKey}`);
                              }} className={`${pathname.includes(`/${key}/${subKey}`) > 0 ? 'font-bold text-[#60448D]' : ''}`}>{sub[subKey].name}</span>
                            </div>
                            {
                              key === 'ecotoxicity' && subKey === 'step1' &&
                              <ul className="mt-1 pl-7 flex flex-col gap-1">
                              {
                                토양독성실험종.map((실험, 순번) => {
                                  return 실험['checked'] && <li key={순번}>
                                    <div className="flex gap-2 cursor-pointer items-center">
                                      <img src={SideDot} alt="" style={{width: 3}}/>
                                      <span onClick={e => {
                                        navigate(`/${key}/${subKey}/${순번}`);
                                      }} className={`${pathname === `/${key}/${subKey}/${순번}` ? 'font-bold text-[#60448D]' : ''}`}>{실험['실험명']}</span>
                                    </div>
                                  </li>
                                })
                              }
                              </ul>
                            }
                            {
                              key === 'ecotoxicity' && subKey === 'step2' &&
                              <ul className="mt-1 pl-7 flex flex-col gap-1">
                              {
                                토양추출액독성실험종.map((실험, 순번) => {
                                  return 실험['checked'] && <li key={순번}>
                                    <div className="flex gap-2 cursor-pointer items-center">
                                      <img src={SideDot} alt="" style={{width: 3}}/>
                                      <span onClick={e => {
                                        navigate(`/${key}/${subKey}/${순번}`);
                                      }} className={`${pathname === `/${key}/${subKey}/${순번}` ? 'font-bold text-[#60448D]' : ''}`}>{실험['실험명']}</span>
                                    </div>
                                  </li>
                                })
                              }
                              </ul>
                            }
                            {
                              SideData['생태위해성평가'][key]['sub'][subKey]?.sub &&
                              <ul className="mt-1 pl-7 flex flex-col gap-1">
                                {
                                  Object.keys(SideData['생태위해성평가'][key]['sub'][subKey].sub).map(subSubKey => {
                                    return <li key={subSubKey}>
                                      <div className="flex gap-2 cursor-pointer items-start">
                                        <img src={SideInnerInnerArrow} alt="" className="w-[20px] h-[20px] mt-0.5"/>
                                        <span onClick={e => {
                                          navigate(`/${key}/${subKey}/${subSubKey}`);
                                        }} className={`${pathname === `/${key}/${subKey}/${subSubKey}` ? 'font-bold' : ''}`}>{SideData['생태위해성평가'][key]['sub'][subKey].sub[subSubKey].name}</span>
                                      </div>
                                    </li>
                                  })
                                }
                              </ul>
                            }
                          </li>
                        })
                      }
                    </ul>
                  }
                </li>
              })
            }
          </ul>
        </>
      }


    </SideWrap>
  );
};

export default Side;
