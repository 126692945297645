import React from 'react';

const EmptyImage = ({cls = '', txt = ''}) => {
  return (
    <div className={`${cls}`}>
      {txt}
    </div>
  );
};

export default EmptyImage;
