import React from 'react';
import {Route, Routes} from "react-router-dom";
import Step1Page from "./Step1Page";
import Step2Page from "./Step2Page";
import Step3Page from "./Step3Page";
import Step4Page from "./Step4Page";
import Step5Page from "./Step5Page";
import Step2Sub1Page from "./Step2Sub1Page";
import Step2Sub2Page from "./Step2Sub2Page";
import Step3Sub1Page from "./Step3Sub1Page";
import Step3Sub2Page from "./Step3Sub2Page";


const Router = ({}) => {
  return (
    <>
      <Routes>
        <Route path={'step1'} element={<Step1Page />} />
        <Route path={'step2'} element={<Step2Page />} />
        <Route path={'step3'} element={<Step3Page />} />
        <Route path={'step4'} element={<Step4Page />} />
        <Route path={'step5'} element={<Step5Page />} />
        <Route path={'step2/sub1'} element={<Step2Sub1Page />} />
        <Route path={'step2/sub2'} element={<Step2Sub2Page />} />
        <Route path={'step3/sub1'} element={<Step3Sub1Page />} />
        <Route path={'step3/sub2'} element={<Step3Sub2Page />} />
      </Routes>
    </>
  );
};

export default Router;
