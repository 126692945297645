import React from 'react';
import Close from "../img/addressClose.png";

const Modal = ({children, onClose, title, width = 500, height = 500}) => {
  return (
    <div className={`fixed top-0 left-0 right-0 bottom-0`}>
      <div
        className={`flex flex-col absolute top-1/2 left-1/2 bg-white z-10 translate-x-[-50%] translate-y-[-50%] rounded border border-gray-200`}
        style={{width: width, height: height}}
      >
        <h2 className={`p-4 text-[20px] font-bold flex justify-between items-center border-b border-[#DEE2E6]`}>
          {title}
          <div className={`p-1 cursor-pointer`} onClick={() => {
            onClose(false)
          }}>
            <img src={Close} alt="" className={`w-[16px] h-[16px]`}/>
          </div>
        </h2>
        <div className={`flex-1 p-4`}>
          {children}
        </div>
      </div>
      <div className={`fixed top-0 left-0 right-0 bottom-0 bg-white opacity-60`} />
    </div>
  );
};

export default Modal;
