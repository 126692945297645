import React from 'react';
import Step2 from "components/investigation/Step2";

const Step1Page = ({}) => {
  return (
    <Step2 />
  );
};

export default Step1Page;
