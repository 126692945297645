import React, {useContext, useEffect, useState} from 'react';
import {
  LayoutBox,
  LayoutBoxHeader,
  LayoutBoxHeaderH2,
  LayoutBoxHeaderRight, LayoutContentsHeader, LayoutContentsInner,
  LayoutContentsWrap
} from "../../constants/LayoutCons";
import Side from "components/Side";
import {GetMenuUrl} from "../../constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import {CheckboxLabel, CheckboxWrap, FormGroup, FormTableTbodyTr, FormTableTd, FormTableTh, FormTableTheadTr, FormWrap} from "../../constants/FormConstants";
import CellPlusIcon from 'img/cellPlusIcon.png';
import CellMinusIcon from 'img/cellMinusIcon.png';
import {TableMinusIcon, TablePlusIcon} from "../../constants/KerasTable";
import Decimal from "decimal.js";
import * as lodash from "lodash";

export default ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [총농도데이터, set총농도데이터] = useState({});
  const [토양총농도데이터, set토양총농도데이터] = useState({});
  const [CaCl2추출농도, setCaCl2추출농도] = useState({});
  const [HNO3추출농도, setHNO3추출농도] = useState({});
  const [화학평과결과, set화학평과결과] = useState({});

  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }

  useEffect(() => {
    const SSD기반 = {'Cd': 12, 'Cu': 60, 'As': 56, 'Hg': 490, 'Cr6+': 0, 'Ni': 65, 'Zn': 210, 'Pb': 490};
    let 첫번째시료명 = '';
    // 중금속 총농도
    {
      // 총농도
      Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
        if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
        appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          if(i === 0) {
            첫번째시료명 = 시료명;
          }
          const 토양농도 = o['토양농도'];
          let 총농도 = 0;
          let length = 0;
          토양농도.map((o2, i2) => {
            ++length;
            총농도 = new Decimal(o2[중금속]).plus(총농도);
          });
          if(총농도데이터[중금속] === undefined) {
            총농도데이터[중금속] = {};
          }

          총농도데이터[중금속][시료명] = lodash.merge({'총농도': (총농도.div(length).toNumber()).toFixed(2)}, 총농도데이터[중금속][시료명]);
        });
      });

      // SSD 기반
      Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
        if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
        appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          // console.log(SSD기반[중금속], 중금속);
          총농도데이터[중금속][시료명] = lodash.merge({'SSD 기반': SSD기반[중금속]}, 총농도데이터[중금속][시료명]);
        });
      });

      // SSD 변곡점
      Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
        if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
        appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          총농도데이터[중금속][시료명] = lodash.merge({'SSD 변곡점': 0.4}, 총농도데이터[중금속][시료명]);
        });
      });

      // Toxic
      Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
        if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
        appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const 총농도 = 총농도데이터[중금속][시료명]['총농도'];
          const SSD_기반 = 총농도데이터[중금속][시료명]['SSD 기반'];
          const SSD_변곡점 = 총농도데이터[중금속][시료명]['SSD 변곡점'];
          const Toxic = 1 / (1+Math.exp((Math.log10(SSD_기반) - Math.log10(총농도)) / SSD_변곡점));
          총농도데이터[중금속][시료명] = lodash.merge({'Toxic': isNaN(Toxic) ? 0.000 : Toxic.toFixed(3)}, 총농도데이터[중금속][시료명]);
        });
      });

      // Reference
      Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
        if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
        appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          if( ! 총농도데이터[중금속][시료명]['Toxic'] || ! 총농도데이터[중금속][첫번째시료명]) {
            return;
          }
          const 중금속Toxic = 총농도데이터[중금속][시료명]['Toxic'];
          const R3Toxic = 총농도데이터[중금속][첫번째시료명]['Toxic'];
          const Reference = (중금속Toxic - R3Toxic) / (1 - R3Toxic);
          총농도데이터[중금속][시료명] = lodash.merge({'Reference': Reference.toFixed(3)}, 총농도데이터[중금속][시료명]);
        });
      });

      // 상용로그변환
      Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
        if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
        appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const 상용로그변환 = Math.log10(1 - 총농도데이터[중금속][시료명]['Reference']);
          총농도데이터[중금속][시료명] = lodash.merge({'상용로그변환': 상용로그변환.toFixed(3)}, 총농도데이터[중금속][시료명]);
        });
      });

      // 정수변환
      Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(중금속 => {
        if(appContext.appData['중금속 분석결과']['중금속'][중금속] === false) return;
        appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const 상용로그변환 = 총농도데이터[중금속][시료명]['상용로그변환'];
          const 정수변환 = 상용로그변환 > 0 ? 0 : 1 - Math.pow(10, 상용로그변환);
          총농도데이터[중금속][시료명] = lodash.merge({'정수변환': 정수변환.toFixed(3)}, 총농도데이터[중금속][시료명]);
        });
      });
    }

    // 토양 중금속 농도
    {
      // 총농도
      Object.keys(appContext.appData['토양 중금속 농도']['중금속']).map(중금속 => {
        if(appContext.appData['토양 중금속 농도']['중금속'][중금속] === false) return;
        appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const 토양농도 = o['토양농도'];
          let 총농도 = 0;
          let length = 0;
          토양농도.map((o2, i2) => {
            ++length;
            총농도 = new Decimal(o2[중금속]).plus(총농도);
          });
          if(토양총농도데이터[중금속] === undefined) {
            토양총농도데이터[중금속] = {};
          }
          토양총농도데이터[중금속][시료명] = lodash.merge({'총농도': (총농도.div(length).toNumber().toFixed(2))}, 토양총농도데이터[중금속][시료명]);
        });
      })

      // SSD 기반
      Object.keys(appContext.appData['토양 중금속 농도']['중금속']).map(중금속 => {
        if(appContext.appData['토양 중금속 농도']['중금속'][중금속] === false) return;
        appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          토양총농도데이터[중금속][시료명] = lodash.merge({'SSD 기반': Number(SSD기반[중금속])}, 토양총농도데이터[중금속][시료명]);
        });
      });

      // HC 50의 비
      Object.keys(appContext.appData['토양 중금속 농도']['중금속']).map(중금속 => {
        if(appContext.appData['토양 중금속 농도']['중금속'][중금속] === false) return;
        appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const 총농도 = 토양총농도데이터[중금속][시료명]['총농도'];
          const SSD_기반 = 토양총농도데이터[중금속][시료명]['SSD 기반'];
          토양총농도데이터[중금속][시료명] = lodash.merge({'HC 50의 비': 총농도 == 0 ? 0 : Number((new Decimal(총농도).div(SSD_기반).toNumber()).toFixed(5))}, 토양총농도데이터[중금속][시료명]);
        });
      });

      // Risk number
      Object.keys(appContext.appData['토양 중금속 농도']['중금속']).map(중금속 => {
        if(appContext.appData['토양 중금속 농도']['중금속'][중금속] === false) return;
        appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const HC50의비 = 토양총농도데이터[중금속][시료명]['HC 50의 비'];
          토양총농도데이터[중금속][시료명] = lodash.merge({'Risk number': (1-(1 / (1 + HC50의비))).toFixed(3) }, 토양총농도데이터[중금속][시료명]);
        });
      });

      // Reference
      Object.keys(appContext.appData['토양 중금속 농도']['중금속']).map(중금속 => {
        if(appContext.appData['토양 중금속 농도']['중금속'][중금속] === false) return;
        appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
          if( ! 토양총농도데이터[중금속][첫번째시료명]) return
          const 시료명 = o['시료명'];
          const Risk_number = 토양총농도데이터[중금속][시료명]['Risk number'];
          const 기존Risk_number = 토양총농도데이터[중금속][첫번째시료명]['Risk number'];
          const Reference = Risk_number < 기존Risk_number ? 0 : (Risk_number - 기존Risk_number) / (1 - 기존Risk_number);


          // console.log('b', Risk_number, 기존Risk_number);
          if(isNaN(Reference)) {
            console.log(Reference, Risk_number, 기존Risk_number, 시료명, 중금속);

          }
          토양총농도데이터[중금속][시료명] = lodash.merge({'Reference': (Reference).toFixed(3)}, 토양총농도데이터[중금속][시료명]);
        });
      });
    }

    // CaCl2 추출농도
    {
      // 총농도
      Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(중금속 => {
        if(appContext.appData['CaCl2 추출농도']['중금속'][중금속] === false) return;
        appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const 토양농도 = o['토양농도'];
          let 총농도 = 0;
          let length = 0;
          토양농도.map((o2, i2) => {
            ++length;
            총농도 = new Decimal(o2[중금속]).plus(총농도);
          });
          if(CaCl2추출농도[중금속] === undefined) {
            CaCl2추출농도[중금속] = {};
          }
          CaCl2추출농도[중금속][시료명] = lodash.merge({'총농도': (총농도.div(length).toNumber()).toFixed(2)}, CaCl2추출농도[중금속][시료명]);
        });
      })

      // SSD 기반
      Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(중금속 => {
        if(appContext.appData['CaCl2 추출농도']['중금속'][중금속] === false) return;
        appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          CaCl2추출농도[중금속][시료명] = lodash.merge({'SSD 기반': Number(SSD기반[중금속])}, CaCl2추출농도[중금속][시료명]);
        });
      });

      // HC 50의 비
      Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(중금속 => {
        if(appContext.appData['CaCl2 추출농도']['중금속'][중금속] === false) return;
        appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const 총농도 = CaCl2추출농도[중금속][시료명]['총농도'];
          const SSD_기반 = CaCl2추출농도[중금속][시료명]['SSD 기반'];
          CaCl2추출농도[중금속][시료명] = lodash.merge({'HC 50의 비': 총농도 == 0 ? 0 : Number((new Decimal(총농도).div(SSD_기반).toNumber()).toFixed(5))}, CaCl2추출농도[중금속][시료명]);
        });
      });

      // Risk number
      Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(중금속 => {
        if(appContext.appData['CaCl2 추출농도']['중금속'][중금속] === false) return;
        appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const HC50의비 = CaCl2추출농도[중금속][시료명]['HC 50의 비'];
          CaCl2추출농도[중금속][시료명] = lodash.merge({'Risk number': (1-(1 / (1 + HC50의비))).toFixed(3) }, CaCl2추출농도[중금속][시료명]);
        });
      });

      // Reference
      Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(중금속 => {
        if(appContext.appData['CaCl2 추출농도']['중금속'][중금속] === false) return;
        appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
          if(! CaCl2추출농도[중금속][첫번째시료명]) return;
          const 시료명 = o['시료명'];
          const Risk_number = CaCl2추출농도[중금속][시료명]['Risk number'];
          const 기존Risk_number = CaCl2추출농도[중금속][첫번째시료명]['Risk number'];
          const Reference = Risk_number < 기존Risk_number ? 0 : (Risk_number - 기존Risk_number) / (1 - 기존Risk_number);

          // console.log(Reference, Risk_number, 기존Risk_number, 시료명, 중금속);
          // console.log('a', Risk_number, 기존Risk_number);
          if(isNaN(Reference)) {

          }
          CaCl2추출농도[중금속][시료명] = lodash.merge({'Reference': (Reference).toFixed(3)}, CaCl2추출농도[중금속][시료명]);
        });
      });
    }

    // HNO3 추출농도
    {
      // 총농도
      Object.keys(appContext.appData['HNO3 추출농도']['중금속']).map(중금속 => {
        if(appContext.appData['HNO3 추출농도']['중금속'][중금속] === false) return;
        appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const 토양농도 = o['토양농도'];
          let 총농도 = 0;
          let length = 0;
          토양농도.map((o2, i2) => {
            ++length;
            총농도 = new Decimal(o2[중금속]).plus(총농도);
          });
          if(HNO3추출농도[중금속] === undefined) {
            HNO3추출농도[중금속] = {};
          }
          HNO3추출농도[중금속][시료명] = lodash.merge({'총농도': (총농도.div(length).toNumber()).toFixed(2)}, HNO3추출농도[중금속][시료명]);
        });
      })

      // SSD 기반
      Object.keys(appContext.appData['HNO3 추출농도']['중금속']).map(중금속 => {
        if(appContext.appData['HNO3 추출농도']['중금속'][중금속] === false) return;
        appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          HNO3추출농도[중금속][시료명] = lodash.merge({'SSD 기반': SSD기반[중금속]}, HNO3추출농도[중금속][시료명]);
        });
      });

      // HC 50의 비
      Object.keys(appContext.appData['HNO3 추출농도']['중금속']).map(중금속 => {
        if(appContext.appData['HNO3 추출농도']['중금속'][중금속] === false) return;
        appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const 총농도 = HNO3추출농도[중금속][시료명]['총농도'];
          const SSD_기반 = HNO3추출농도[중금속][시료명]['SSD 기반'];
          HNO3추출농도[중금속][시료명] = lodash.merge({'HC 50의 비': 총농도 == 0 ? 0 : Number((new Decimal(총농도).div(SSD_기반).toNumber()).toFixed(5))}, HNO3추출농도[중금속][시료명]);
        });
      });

      // Risk number
      Object.keys(appContext.appData['HNO3 추출농도']['중금속']).map(중금속 => {
        if(appContext.appData['HNO3 추출농도']['중금속'][중금속] === false) return;
        appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
          const 시료명 = o['시료명'];
          const HC50의비 = HNO3추출농도[중금속][시료명]['HC 50의 비'];
          HNO3추출농도[중금속][시료명] = lodash.merge({'Risk number': (1-(1 / (1 + HC50의비))).toFixed(3) }, HNO3추출농도[중금속][시료명]);
        });
      });

      // Reference
      Object.keys(appContext.appData['HNO3 추출농도']['중금속']).map(중금속 => {
        if(appContext.appData['HNO3 추출농도']['중금속'][중금속] === false) return;
        appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
          if( ! HNO3추출농도[중금속][첫번째시료명]) return;
          const 시료명 = o['시료명'];
          const Risk_number = HNO3추출농도[중금속][시료명]['Risk number'];
          const 기존Risk_number = HNO3추출농도[중금속][첫번째시료명]['Risk number'];
          const Reference = Risk_number < 기존Risk_number ? 0 : (Risk_number - 기존Risk_number) / (1 - 기존Risk_number);

          if(isNaN(Reference)) {
            console.log(Reference, Risk_number, 기존Risk_number, 시료명, 중금속);

          }
          HNO3추출농도[중금속][시료명] = lodash.merge({'Reference': (Reference).toFixed(3)}, HNO3추출농도[중금속][시료명]);
        });
      });
    }

    // 화학평가결과
    {
      let 시료배열 = [];
      let 중금속배열 = ['Cd', 'Cu', 'As', 'Hg', 'Pb', 'Cr6+', 'Zn', 'Ni'];
      const 데이터카테고리 = ['중금속 분석결과', 'CaCl2 추출농도', 'HNO3 추출농도', '토양 중금속 농도'];
      데이터카테고리.map(카테고리 => {
        appContext.appData[카테고리]['시료'].map((o, i) => {
          시료배열.push(o['시료명']);
        });
      });

      // 중복 제거
      시료배열 = 시료배열.filter((element, index) => {
        return 시료배열.indexOf(element) === index;
      });

      시료배열.map(시료 => {
        let 결과값 = 1;
        중금속배열.map(중금속 => {

          try {
            let 값 = 총농도데이터[중금속][시료]['정수변환'];
            if(Number(값) !== 0) {
              결과값 = (1 - 값) * 결과값;
            }
          } catch (e) {}

          try {
            let 값 = 토양총농도데이터[중금속][시료]['Reference'];
            if(Number(값) !== 0) {
              결과값 = (1 - 값) * 결과값;
            }
          } catch (e) {}

          try {
            let 값 = CaCl2추출농도[중금속][시료]['Reference'];
            if(Number(값) !== 0) {
              결과값 = (1 - 값) * 결과값;
            }
          } catch (e) {}

          try {
            let 값 = HNO3추출농도[중금속][시료]['Reference'];
            if(Number(값) !== 0) {
              결과값 = (1 - 값) * 결과값;
            }
          } catch (e) {}

        });
        화학평과결과[시료] = (1 - 결과값).toFixed(3);
      });
    }

    set총농도데이터(lodash.merge({}, 총농도데이터));
    set토양총농도데이터(lodash.merge({}, 토양총농도데이터));
    setCaCl2추출농도(lodash.merge({}, CaCl2추출농도));
    setHNO3추출농도(lodash.merge({}, HNO3추출농도));
    set화학평과결과(lodash.merge({}, 화학평과결과));
  }, []);

  // console.log('총농도데이터', 총농도데이터);


  return (
    <div className="container my-7 px-0">
      <div className="my-5">
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <div>
                <div className={`mb-[10px]`}>중금속 총농도</div>
                {
                  Object.keys(총농도데이터).map(중금속 => {
                    // console.log(총농도데이터[중금속]);
                    return <React.Fragment key={중금속}>
                      <div className={`bg-white text-sm mb-[10px]`}>
                        <div className={`w-full flex px-[12px] items-center border-t-[2px] border-b border-black`}>
                          <div className={`w-[200px]`}>{중금속} 총농도(mg/kg)</div>
                          <div className="flex w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{o['시료명']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                          <div className={`w-[200px]`}>① {중금속} 총농도(mg/kg)</div>
                          <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex gap-1 py-[5px]">
                              {
                                appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{총농도데이터[중금속][o['시료명']]['총농도']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                          <div className={`w-[200px]`}>② {중금속} 위해도</div>
                          <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex gap-1 py-[5px]">
                              {
                                appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{isNaN(총농도데이터[중금속][o['시료명']]['정수변환']) ? 0 : 총농도데이터[중금속][o['시료명']]['정수변환']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>

                        {/*<div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>② SSD 기반 HC 50</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{총농도데이터[중금속][o['시료명']]['SSD 기반']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>③ SSD 변곡점 기울기 (=β)</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{총농도데이터[중금속][o['시료명']]['SSD 변곡점']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>④ Toxic pressure (=1/(1+exp^((log HC50-log 농도/β))</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{총농도데이터[중금속][o['시료명']]['Toxic']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>④ Toxic pressure</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{총농도데이터[중금속][o['시료명']]['Reference']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>⑥ 상용로그 변환 (=log (1-X))</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{총농도데이터[중금속][o['시료명']]['상용로그변환']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>*/}

                      </div>
                    </React.Fragment>
                  })
                }

                {/*{
                state 없이 했던 로직
                  Object.keys(appContext.appData['중금속 분석결과']['중금속']).map(key => {
                    return appContext.appData['중금속 분석결과']['중금속'][key] === true && <React.Fragment key={key}>
                      <div className={`bg-white text-sm mb-[10px]`}>
                        <div className={`w-full flex gap-[10px] p-[12px] font-bold items-center border-t border-b border-black`}>
                          <div className={`w-[200px]`}>{key} 총농도(mg/kg)</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{o['시료명']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>① {key} 총농도(mg/kg)</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['중금속 분석결과']['시료'].map((o, i) => {
                                  const 토양농도 = o['토양농도'];
                                  let 총농도 = 0;
                                  토양농도.map((o2, i2) => {
                                    총농도 = new Decimal(o2[key]).plus(총농도);
                                  })
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{총농도.toNumber()}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>② {key} 위해도</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                [0.000, 0.000, 0.000, 0.006, 0.049, 0.044, 0.000].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{o}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  })
                }*/}

              </div>

              <div className={`mt-[24px]`}>
                <div className={`mb-[24px]`}>증류수를 이용한 토양추출액 내 중금속 농도 결과</div>
                {
                  Object.keys(토양총농도데이터).map(중금속 => {
                    // console.log(토양총농도데이터[중금속]);
                    return <React.Fragment key={중금속}>
                      <div className={`bg-white text-sm mb-[10px]`}>
                        <div className={`w-full flex px-[12px] items-center border-t-[2px] border-b border-black`}>
                          <div className={`w-[200px]`}>증류수를 이용한 토양추출액 내 {중금속} 농도 (mg/kg)</div>
                          <div className="flex w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{o['시료명']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                          <div className={`w-[200px]`}>① 추출액 내 {중금속} 농도 (mg/kg)</div>
                          <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{토양총농도데이터[중금속][o['시료명']]['총농도']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        {/*<div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>② SSD 기반 HC 50</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{토양총농도데이터[중금속][o['시료명']]['SSD 기반']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>③ 추출액 농도와 HC 50의 비</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{토양총농도데이터[중금속][o['시료명']]['HC 50의 비']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>④ Risk number 산출</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{토양총농도데이터[중금속][o['시료명']]['Risk number']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>*/}
                        <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                          <div className={`w-[200px]`}>{중금속} 위해도</div>
                          <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['토양 중금속 농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{토양총농도데이터[중금속][o['시료명']]['Reference']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  })
                }
              </div>

              <div className={`mt-[24px]`}>
                <div className={`mb-[24px]`}>0.001 M CaCl2를 이용한 토양추출액 내 중금속 농도 결과 </div>
                {
                  Object.keys(CaCl2추출농도).map(중금속 => {
                    // console.log(CaCl2추출농도[중금속]);
                    return <React.Fragment key={중금속}>
                      <div className={`bg-white text-sm mb-[10px]`}>
                        <div className={`w-full flex px-[12px] items-center border-t-[2px] border-b border-black`}>
                          <div className={`w-[200px]`}>0.001 M CaCl2를 이용한 토양추출액 내 {중금속} 농도 (mg/kg)</div>
                          <div className="flex w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{o['시료명']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                          <div className={`w-[200px]`}>① 추출액 내 {중금속} 농도 (mg/kg)</div>
                          <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{CaCl2추출농도[중금속][o['시료명']]['총농도']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        {/*<div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>② SSD 기반 HC 50</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{CaCl2추출농도[중금속][o['시료명']]['SSD 기반']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>③ 추출액 농도와 HC 50의 비</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{CaCl2추출농도[중금속][o['시료명']]['HC 50의 비']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>④ Risk number 산출</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{CaCl2추출농도[중금속][o['시료명']]['Risk number']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>*/}
                        <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                          <div className={`w-[200px]`}>{중금속} 위해도</div>
                          <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['CaCl2 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{CaCl2추출농도[중금속][o['시료명']]['Reference']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  })
                }
              </div>

              <div className={`mt-[24px]`}>
                <div className={`mb-[24px]`}>0.43 M HNO3를 이용한 토양추출액 내 중금속 농도 결과</div>
                {
                  Object.keys(HNO3추출농도).map(중금속 => {
                    // console.log(HNO3추출농도[중금속]);
                    return <React.Fragment key={중금속}>
                      <div className={`bg-white text-sm mb-[10px]`}>
                        <div className={`w-full flex px-[12px] items-center border-t-[2px] border-b border-black`}>
                          <div className={`w-[200px]`}>0.43 M HNO3를 이용한 토양추출액 내 {중금속} 농도 (mg/kg)</div>
                          <div className="flex w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{o['시료명']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                          <div className={`w-[200px]`}>① 추출액 내 {중금속} 농도 (mg/kg)</div>
                          <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{HNO3추출농도[중금속][o['시료명']]['총농도']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        {/*<div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>② SSD 기반 HC 50</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{HNO3추출농도[중금속][o['시료명']]['SSD 기반']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>③ 추출액 농도와 HC 50의 비</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{HNO3추출농도[중금속][o['시료명']]['HC 50의 비']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className={`w-full flex gap-[10px] p-[12px] items-center border-b border-gray-300`}>
                          <div className={`w-[200px]`}>④ Risk number 산출</div>
                          <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{HNO3추출농도[중금속][o['시료명']]['Risk number']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>*/}
                        <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                          <div className={`w-[200px]`}>{중금속} 위해도</div>
                          <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                            <div className="flex w-[443px] gap-1 py-[5px]">
                              {
                                appContext.appData['HNO3 추출농도']['시료'].map((o, i) => {
                                  return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{HNO3추출농도[중금속][o['시료명']]['Reference']}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  })
                }
              </div>






              <div className={`mt-[24px]`}>
                <div className="mb-[10px]">화학 위해도</div>
                <div className={`bg-white text-sm mb-[10px] p-[10px] border-b border-gray-300`}>
                  <div className={`border-t-[3px] border-dashed border-gray-400 `}></div>
                  <div className={`w-full flex gap-[10px] p-[12px] items-center`}>
                    <div className={`w-[200px] text-sm`}>
                      화학 위해도<br/>
                      <div className="text-xs">(=1-(1-reference 보정값)1 ×… (1-reference 보정값)n)</div>
                    </div>
                    <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                      <div className="flex w-[443px] gap-1 py-[5px]">
                        {
                          Object.keys(화학평과결과).map((시료명, i) => {
                            return <div key={i} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{isNaN(화학평과결과[시료명]) ? 0 : 화학평과결과[시료명]}</div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

