import React, {useContext, useState} from 'react';
import Side from "components/Side";
import {CheckboxLabel, CheckboxWrap, FileInput, FormGroup, FormWrap, RadioLabel, RadioWrap} from "../../constants/FormConstants";
import {useLocation, useNavigate} from "react-router-dom";
import {LayoutBox, LayoutContentsInner, LayoutContentsWrap, LayoutSubTitle} from "../../constants/LayoutCons";
import DaumPost from "../../util/DaumPost";
import * as lodash from "lodash";
import AppContext from "../../contexts/AppContext";
import {getBase64} from "../../util/FileUtil";
import EmptyImage from "../../util/EmptyImage";
import KerasImage from "../../util/KerasImage";
import axios from "axios";

const Step1 = ({}) => {

  const appContext = useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeAddress, setActiveAddress] = useState(false);

  const onAddress = () => {
    setActiveAddress(true);
  }

  const setAddress = (address) => {
    const appData = lodash.merge({}, appContext.appData, {
      '문헌조사': {
        '대상부지 기본정보': {
          '주소': address
        }
      }
    });
    appContext.setAppData(appData);
  }

  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <FormWrap>
                <FormGroup>
                  <LayoutSubTitle>주소</LayoutSubTitle>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      className="h-[68px] w-full rounded border border-gray-400 p-3"
                      placeholder={'충정북도 제천시 수산면 서곡리 366'}
                      value={appContext.appData['문헌조사']['대상부지 기본정보']['주소']}
                      readOnly={true}
                    />
                    <button className="rounded w-[142px] bg-gray-300 border border-gray-400" onClick={onAddress}>주소검색</button>
                  </div>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>토지용도</LayoutSubTitle>
                  <CheckboxWrap>
                    {
                      appContext.appData['문헌조사']['대상부지 기본정보']['토지용도'].map((토지용도, 토지용도순번) => {
                        return <CheckboxLabel key={토지용도순번}>
                          <input type="checkbox" name={`land`} checked={토지용도['checked']} onChange={e => {
                            토지용도['checked'] = e.target.checked;
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}/>
                          {토지용도['용도명']}
                        </CheckboxLabel>
                      })
                    }
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>부지이력</LayoutSubTitle>
                  <RadioWrap>
                    {
                      appContext.appData['문헌조사']['대상부지 기본정보']['부지이력'].map((o, i) => {
                        return <RadioLabel key={i}>
                          <input type="radio" name={`history`} checked={o['checked']} onChange={e => {
                            appContext.appData['문헌조사']['대상부지 기본정보']['부지이력'].map((o2, i2) => {
                              if(i === i2) {
                                appContext.appData['문헌조사']['대상부지 기본정보']['부지이력'][i2]['checked'] = true;
                              } else {
                                appContext.appData['문헌조사']['대상부지 기본정보']['부지이력'][i2]['checked'] = false;
                              }
                            });
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}/>
                          {o['부지명']}
                          {o['부지명'] === '기타' &&
                            <input
                              type="text"
                              className={`rounded-full border border-gray-400 px-4`}
                              placeholder={`입력`}
                              value={appContext.appData['문헌조사']['대상부지 기본정보']['부지이력기타']}
                              onChange={e => {
                                appContext.appData['문헌조사']['대상부지 기본정보']['부지이력기타'] = e.target.value;
                                appContext.setAppData(lodash.merge({}, appContext.appData));

                          }}/>}
                        </RadioLabel>
                      })
                    }
                  </RadioWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 1.유기물질</LayoutSubTitle>
                  <CheckboxWrap>
                    {
                      Object.keys(appContext.appData['문헌조사']['대상부지 기본정보']['유기물질']).map(k => {
                        return <CheckboxLabel key={k}>
                          <input type="checkbox" name={`history`} checked={appContext.appData['문헌조사']['대상부지 기본정보']['유기물질'][k]}
                                 onChange={e => {
                                   appContext.appData['문헌조사']['대상부지 기본정보']['유기물질'][k] = e.target.checked;
                                   appContext.setAppData(lodash.merge({}, appContext.appData));
                                 }}
                          />
                          {k}
                        </CheckboxLabel>
                      })
                    }
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 2.중금속</LayoutSubTitle>
                  <CheckboxWrap>
                    {
                      Object.keys(appContext.appData['문헌조사']['대상부지 기본정보']['중금속']).map(k => {
                        return <CheckboxLabel key={k}>
                          <input type="checkbox" name={`pollutants`} checked={appContext.appData['문헌조사']['대상부지 기본정보']['중금속'][k]}
                                 onChange={e => {
                                   appContext.appData['문헌조사']['대상부지 기본정보']['중금속'][k] = e.target.checked;
                                   appContext.setAppData(lodash.merge({}, appContext.appData));
                                 }}
                          />
                          {k}
                        </CheckboxLabel>
                      })
                    }
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 3.기타</LayoutSubTitle>
                  <CheckboxWrap>
                    {
                      Object.keys(appContext.appData['문헌조사']['대상부지 기본정보']['기타']).map(k => {
                        return <CheckboxLabel key={k}>
                          <input type="checkbox" name={`history`} checked={appContext.appData['문헌조사']['대상부지 기본정보']['기타'][k]}
                                 onChange={e => {
                                   appContext.appData['문헌조사']['대상부지 기본정보']['기타'][k] = e.target.checked;
                                   appContext.setAppData(lodash.merge({}, appContext.appData));
                                 }}
                          />
                          {k}
                        </CheckboxLabel>
                      })
                    }
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <div className="flex gap-2">
                    <div className={`flex-1 flex flex-col gap-2`}>
                      <LayoutSubTitle>지적도등본</LayoutSubTitle>
                      <div>
                        <FileInput
                          accept={'image/*'}
                          fileName={appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지']['name']}
                          onChange={e => {
                            const file = e.target.files[0];
                            const formData = new FormData();
                            formData.append("file", file);
                            axios({
                              method:'post',
                              url:'https://api.keras-b.7greenus.com/uploads',
                              data: formData,
                            }).then(({data, status}) => {
                                if(status === 200 && data.message === '성공') {
                                  if(appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지'] === undefined) {
                                    appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지'] = {};
                                  }
                                  appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지']['image'] = data.uploadurl;
                                  appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지']['name'] = file.name;
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }
                              }).catch(err => {
                              console.log(err);
                            })
                          }}
                          onDelete={e => {
                            appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지']['image'] = '';
                            appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지']['name'] = '';
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        />
                        {
                          (appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지']['image']) ?
                            <KerasImage
                              image={appContext.appData['문헌조사']['대상부지 기본정보']['지적도등본 이미지']['image']}
                              cls={`w-full block mt-6 aspect-[409/422] object-cover cursor-pointer`}
                              alt={`지적도등본 이미지`}
                            />
                            :
                            <EmptyImage
                              cls={`w-full mt-6 aspect-[409/422] bg-[#d9d9d9] flex rounded items-center justify-center`}
                              txt={`(이미지 없음)`}
                            />
                        }
                      </div>
                    </div>
                    <div className={`flex-1 flex flex-col gap-2`}>
                      <LayoutSubTitle>수치지형도</LayoutSubTitle>
                      <div>
                        <FileInput
                          accept={'image/*'}
                          fileName={appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지']['name']}
                          onChange={e => {
                            const file = e.target.files[0];
                            const formData = new FormData();
                            formData.append("file", file);
                            axios({
                              method:'post',
                              url:'https://api.keras-b.7greenus.com/uploads',
                              data: formData,
                            }).then(({data, status}) => {
                              if(status === 200 && data.message === '성공') {
                                if(appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지'] === undefined) {
                                  appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지'] = {};
                                }
                                appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지']['image'] = data.uploadurl;
                                appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지']['name'] = file.name;
                                appContext.setAppData(lodash.merge({}, appContext.appData));
                              }
                            }).catch(err => {
                              console.log(err);
                            })
                          }}
                          onDelete={e => {
                            appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지']['image'] = '';
                            appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지']['name'] = '';
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        />
                        {
                          (appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지']['image']) ?
                            <KerasImage
                              image={appContext.appData['문헌조사']['대상부지 기본정보']['수치지형도 이미지']['image']}
                              cls={`w-full block mt-6 aspect-[409/422] object-cover cursor-pointer`}
                              alt={`수치지형도 이미지`}
                            />
                            :
                            <EmptyImage
                              cls={`w-full mt-6 aspect-[409/422] bg-[#d9d9d9] flex rounded items-center justify-center`}
                              txt={`(이미지 없음)`}
                            />
                        }
                      </div>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="flex gap-2">
                    <div className={`flex-1 flex flex-col gap-2`}>
                      <LayoutSubTitle>항공사진</LayoutSubTitle>
                      <div>
                        <FileInput
                          accept={'image/*'}
                          fileName={appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지']['name']}
                          onChange={e => {
                            const file = e.target.files[0];
                            const formData = new FormData();
                            formData.append("file", file);
                            axios({
                              method:'post',
                              url:'https://api.keras-b.7greenus.com/uploads',
                              data: formData,
                            }).then(({data, status}) => {
                              if(status === 200 && data.message === '성공') {
                                if(appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지'] === undefined) {
                                  appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지'] = {};
                                }
                                appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지']['image'] = data.uploadurl;
                                appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지']['name'] = file.name;
                                appContext.setAppData(lodash.merge({}, appContext.appData));
                              }
                            }).catch(err => {
                              console.log(err);
                            })
                          }}
                          onDelete={e => {
                            appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지']['image'] = '';
                            appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지']['name'] = '';
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        />
                        {
                          (appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지']['image']) ?
                            <KerasImage
                              image={appContext.appData['문헌조사']['대상부지 기본정보']['항공사진 이미지']['image']}
                              cls={`w-full block mt-6 aspect-[409/422] object-cover cursor-pointer`}
                              alt={`항공사진 이미지`}
                            />
                            :
                            <EmptyImage
                              cls={`w-full mt-6 aspect-[409/422] bg-[#d9d9d9] flex rounded items-center justify-center`}
                              txt={`(이미지 없음)`}
                            />
                        }
                      </div>
                    </div>
                    <div className={`flex-1 flex flex-col gap-2`}>
                      <LayoutSubTitle>토지(임야)대장등본</LayoutSubTitle>
                      <div>
                        <FileInput
                          accept={'image/*'}
                          fileName={appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지']['name']}
                          onChange={e => {
                            const file = e.target.files[0];
                            const formData = new FormData();
                            formData.append("file", file);
                            axios({
                              method:'post',
                              url:'https://api.keras-b.7greenus.com/uploads',
                              data: formData,
                            }).then(({data, status}) => {
                              if(status === 200 && data.message === '성공') {
                                if(appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지'] === undefined) {
                                  appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지'] = {};
                                }
                                appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지']['image'] = data.uploadurl;
                                appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지']['name'] = file.name;
                                appContext.setAppData(lodash.merge({}, appContext.appData));
                              }
                            }).catch(err => {
                              console.log(err);
                            })
                          }}
                          onDelete={e => {
                            appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지']['image'] = '';
                            appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지']['name'] = '';
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        />
                        {
                          (appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지']['image']) ?
                            <KerasImage
                              image={appContext.appData['문헌조사']['대상부지 기본정보']['토지(임야)대장등본 이미지']['image']}
                              cls={`w-full block mt-6 aspect-[409/422] object-cover cursor-pointer`}
                              alt={`토지(임야)대장등본 이미지`}
                            />
                            :
                            <EmptyImage
                              cls={`w-full mt-6 aspect-[409/422] bg-[#d9d9d9] flex rounded items-center justify-center`}
                              txt={`(이미지 없음)`}
                            />
                        }
                      </div>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="flex gap-2">
                    <div className={`flex-1 flex flex-col gap-2`}>
                      <LayoutSubTitle>토지이용계획</LayoutSubTitle>
                      <div>
                        <FileInput
                          accept={'image/*'}
                          fileName={appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['name']}
                          onChange={e => {
                            const file = e.target.files[0];
                            const formData = new FormData();
                            formData.append("file", file);
                            axios({
                              method:'post',
                              url:'https://api.keras-b.7greenus.com/uploads',
                              data: formData,
                            }).then(({data, status}) => {
                              if(status === 200 && data.message === '성공') {
                                if(appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지'] === undefined) {
                                  appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지'] = {};
                                }
                                appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['image'] = data.uploadurl;
                                appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['name'] = file.name;
                                appContext.setAppData(lodash.merge({}, appContext.appData));
                              }
                            }).catch(err => {
                              console.log(err);
                            })
                          }}
                          onDelete={e => {
                            appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['image'] = '';
                            appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['name'] = '';
                            appContext.setAppData(lodash.merge({}, appContext.appData));
                          }}
                        />
                        {
                          (appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지'] && appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['image']) ?
                            <KerasImage
                              image={appContext.appData['문헌조사']['대상부지 기본정보']['토지이용계획 이미지']['image']}
                              cls={`w-full block mt-6 aspect-[409/422] object-cover cursor-pointer`}
                              alt={`토지이용계획 이미지`}
                            />
                            :
                            <EmptyImage
                              cls={`w-full mt-6 aspect-[409/422] bg-[#d9d9d9] flex rounded items-center justify-center`}
                              txt={`(이미지 없음)`}
                            />
                        }
                      </div>
                    </div>
                    <div className={`flex-1 flex flex-col gap-2`}></div>
                  </div>
                </FormGroup>
              </FormWrap>
            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
      {activeAddress &&
        <DaumPost
          setActiveAddress={setActiveAddress}
          setAddress={setAddress}
        />
      }
    </div>
  );
};

export default Step1;
