import React from 'react';
import Final from "components/Final";

const FinalPage = ({}) => {
  return (
    <Final />
  );
};

export default FinalPage;
