import React, {useState} from 'react';
import CloseImage from 'img/kerasCloseImage.png';

const KerasImage = ({image, cls, alt}) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      <img
        src={image}
        className={`${cls}`}
        alt={alt}
        onClick={e => setIsShow(true)}
      />
      {
        isShow &&
        <div className={`fixed top-0 left-0 bottom-0 right-0 z-30`}>
          <div className={`fixed top-[50%] left-[50%] z-10 translate-y-[-50%] translate-x-[-50%]`}>
            <div
              className={`flex justify-end mb-2`}
            >
              <img
                src={CloseImage}
                alt=""
                className={`w-[24px] cursor-pointer`}
                onClick={e => setIsShow(false)}
              />
            </div>
            <img
              src={image}
              className={`max-w-[500px] max-h-[500px] object-contain`}
            />
          </div>
          <div
            className={`fixed top-0 left-0 bottom-0 right-0 bg-white opacity-60`}
            onClick={e => setIsShow(false)}
          />
        </div>
      }
    </>
  );
};

export default KerasImage;
