import React, {useContext, useEffect} from 'react';
import {LayoutBox, LayoutBoxHeader, LayoutBoxHeaderH2, LayoutBoxHeaderRight, LayoutContentsHeader, LayoutContentsInner, LayoutContentsWrap} from "../../constants/LayoutCons";
import Side from "components/Side";
import {GetMenuUrl, 대조구역변동계수시료데이터, 중금속분석토양농도} from "../../constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import {CheckboxLabel, CheckboxWrap, FormGroup, FormWrap, RadioLabel, RadioWrap} from "../../constants/FormConstants";
import * as lodash from "lodash";
import {v4 as uuidv4} from "uuid";
import Toast from "../../util/Toast";
import {TableMinusIcon, TablePlusIcon} from "../../constants/KerasTable";
const Decimal = require('decimal.js');

export default ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const 엑셀데이터처리함수 = (data) => {
    // 1.중금속 분석결과
    if(Array.isArray(data) === false) return;
    if(data.length === 0) return;
    // console.log('엑셀데이터처리함수', data);
    appContext.appData['CaCl2 추출농도']['시료'] = [];
    data.map(row => {
      appContext.appData['CaCl2 추출농도']['시료'].push({
        key: uuidv4(),
        'checked': false,
        '시료명': row['시료명'],
        '위도': row['위도'],
        '경사': row['경사'],
        '경도': row['경도'],
        '고도': row['고도'],
        '토양농도': [
          {'Cd': row['Cd'], 'Cu': row['Cu'], 'As': row['As'], 'Hg': row['Hg'], 'Pb': row['Pb'], 'Cr6+': row['Cr6+'], 'Zn': row['Zn'], 'Ni': row['Ni'],}
        ]
      });
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }


  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }

  const onChangeText2 = (key, field, value, 순번) => {
    appContext.appData['CaCl2 추출농도']['시료'].map(o =>{
      if(o.key === key) {
        o['토양농도'].map((o2, i) => {
          if(i === 순번) {
            o2[field] = value;
          }
        })
      }
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const addTd = () => {
    appContext.appData['CaCl2 추출농도']['시료'].push({
      key: uuidv4(),
      'checked': false,
      '시료명': '시료명',
      '위도': '36.975710',
      '경사': '20',
      '경도': '128.179965',
      '고도': '1005',
      '토양농도': [
        {'Cd': 0, 'Cu': 0, 'As': 0, 'Hg': 0, 'Pb': 0, 'Cr6+': 0, 'Zn': 0, 'Ni': 0,}
      ]
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const removeTd = () => {
    try {
      let flag = false;
      appContext.appData['CaCl2 추출농도']['시료'].map(o => {
        if(o.checked === true) flag = true;
      })
      if(flag) {
        appContext.appData['CaCl2 추출농도']['시료'] = appContext.appData['CaCl2 추출농도']['시료'].filter(o => o.checked === false);
        appContext.setAppData(lodash.merge({}, appContext.appData));
      } else {
        Toast.error('삭제 할 항목을 선택 후 행삭제해 주세요.');
      }
    } catch (e) {
      Toast.error('행삭제 실패');
    }
  }

  const checkPlaces = (value, flagPlace) => {
    if(typeof value !== 'string') {
      value = '' + value;
    }
    if(value.toString().indexOf('.') === -1) {
      return true;
    }
    return flagPlace >= value.split('.')[1].length;
  }

  const fixFloat = (value, place) => {
    const f = Math.pow(10, place);
    if(isNaN(parseFloat(value))) {
      return 0;
    }
    return checkPlaces(value, place) ?  value : parseFloat(Math.ceil(value * f) / f).toFixed(place);
  }

  const check = (key, checked) => {
    appContext.appData['CaCl2 추출농도']['중금속'][key] = checked;
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const onChangeChecked = (key, checked) => {
    appContext.appData['CaCl2 추출농도']['시료'] = appContext.appData['CaCl2 추출농도']['시료'].map(o =>
      o.key === key ? {...o, 'checked': checked} : o
    );
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const onChangeText = (key, field, value) => {
    appContext.appData['CaCl2 추출농도']['시료'] = appContext.appData['CaCl2 추출농도']['시료'].map(o =>
      o.key === key ? {...o, [field]: value} : o
    );
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const 토양농도추가 = (key) => {
    appContext.appData['CaCl2 추출농도']['시료'].map(o => {
      if(o.key === key) {
        o['토양농도'].push({'Cd': 0, 'Cu': 0, 'As': 0, 'Hg': 0, 'Pb': 0, 'Cr6+': 0, 'Zn': 0, 'Ni': 0,});
      }
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const 토양농도삭제 = (key, 순번) => {
    appContext.appData['CaCl2 추출농도']['시료'].map(o => {
      if(o.key === key) {
        o['토양농도'] = o['토양농도'].filter((b, i) => {
          return i !== 순번;
        })
      }
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const allCheck = (checked) => {
    appContext.appData['CaCl2 추출농도']['시료'] = appContext.appData['CaCl2 추출농도']['시료'].map(o => {
      return {...o, checked: checked}
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }


  const calc = () => {
    try {
      appContext.appData['CaCl2 추출농도']['평균값 계산'] = [];
      appContext.appData['CaCl2 추출농도']['시료'].map(o => {
        let 토양농도s = o['토양농도'];
        let 결과값 = {'시료명': o.시료명};
        Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(k => {
          let length = 0;
          토양농도s.map(토양농도 => {
            if(appContext.appData['CaCl2 추출농도']['중금속'][k] === true && 토양농도[k] > 0) {
              ++length;
              결과값[k] = parseFloat(결과값[k] || 0) + parseFloat(토양농도[k]);
            }
          })
          if(length > 0) {
            결과값[k] = new Decimal(결과값[k] || 0).div(length).toNumber().toFixed(2);
          } else {
            결과값[k] = 0;
          }
        });
        appContext.appData['CaCl2 추출농도']['평균값 계산'].push({...결과값, key: uuidv4()});
        // console.log(o, 결과값);
      });
      appContext.appData['CaCl2 추출농도']['평균값 계산 여부'] = true;
      appContext.setAppData(lodash.merge({}, appContext.appData));
    } catch (e) {
      console.log(e);
      Toast.error('계산 실패!');
    }
  }


  return (
    <div className="container my-7 px-0">
      <div className="my-5">
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <FormWrap>
                <FormGroup>
                  <div>생물이용가능농도 - 0.001 M CaCl2</div>
                  <RadioWrap>
                    <RadioLabel>
                      <input type="radio" value={`유`} name={'유무'} checked={appContext.appData['CaCl2 추출농도']['유무'] === '유'} onChange={e => {
                        appContext.appData['CaCl2 추출농도']['유무'] = '유';
                        appContext.setAppData(lodash.merge({}, appContext.appData));
                      }}/> 유
                    </RadioLabel>
                    <CheckboxLabel>
                      <input type="radio" value={`무`} name={'유무'} checked={appContext.appData['CaCl2 추출농도']['유무'] === '무'} onChange={e => {
                        appContext.appData['CaCl2 추출농도']['유무'] = '무';
                        appContext.setAppData(lodash.merge({}, appContext.appData));
                      }}/> 무
                    </CheckboxLabel>
                  </RadioWrap>
                </FormGroup>

                {
                  appContext.appData['CaCl2 추출농도']['유무'] === '유' &&
                  <>
                    <FormGroup>
                      <CheckboxWrap>
                        {
                          Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(key => {
                            return <CheckboxLabel key={key}>
                              <input
                                type="checkbox"
                                value={`Cd`}
                                name={'중금속'}
                                checked={appContext.appData['CaCl2 추출농도']['중금속'][key]}
                                onChange={e => check(key, e.target.checked)}
                              /> {key}
                            </CheckboxLabel>
                          })
                        }
                      </CheckboxWrap>
                    </FormGroup>
                    <div className={`bg-white text-sm`}>
                      <div className="sticky top-0 bg-white">
                        <div className={`flex justify-between py-[6px] px-[20px] border-t border-black`}>
                          <div className="flex gap-[5px]">
                            <button
                              type={'button'}
                              className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                              onClick={() => addTd()}
                            >
                              행추가
                            </button>
                            <button
                              type={'button'}
                              className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                              onClick={() => removeTd()}
                            >
                              행삭제
                            </button>
                          </div>

                          <div className="flex gap-[5px]">
                            <button
                              type={'button'}
                              className={`font-normal bg-[#60448D] w-[204px] h-[37px] rounded-[5px] text-[16px] text-white`}
                              onClick={() => {
                                appContext.importExcel(엑셀데이터처리함수);
                              }}
                            >
                              엑셀 데이터 가져오기
                            </button>
                            <button
                              type={'button'}
                              className={`font-normal bg-[#60448D] w-[142px] h-[37px] rounded-[5px] text-[16px] text-white`}
                              onClick={() => calc()}
                            >
                              평균값 계산
                            </button>
                          </div>
                        </div>
                        <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                          <div className={`mr-2.5`}>
                            <input type="checkbox" onChange={e => allCheck(e.target.checked)}/>
                          </div>
                          <div className={`w-[80px] mr-2.5`}>시료명</div>
                          <div className={`flex-1 overflow-hidden text-center border-[#cdcdcd] border-l`}>
                            <div className="flex w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                              <div className={`flex-[20px] flex-shrink-0 flex-grow-0`}></div>
                              {
                                Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(key => {
                                  return appContext.appData['CaCl2 추출농도']['중금속'][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                })
                              }
                            </div>
                          </div>
                        </div>

                      </div>

                      {
                        appContext.appData['CaCl2 추출농도']['시료'].map(o => {
                          return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                            <div className={`mr-2.5`}>
                              <input
                                type="checkbox"
                                checked={o.checked}
                                onChange={e => onChangeChecked(o.key, e.target.checked)}
                              />
                            </div>
                            <div className={`w-[80px] mr-2.5 py-2`}>
                              <input
                                type="text"
                                className={`p-2.5 border border-[#CDCDCD] rounded-[5px] w-[80px]`}
                                placeholder={'시료명'}
                                value={o['시료명']}
                                style={{height: `${o['토양농도'].length * 30}px`}}
                                onChange={e => onChangeText(o.key, '시료명', e.target.value)}
                              />
                            </div>
                            <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                              {
                                o['토양농도'].map((토양농도, 순번) => {
                                  return <div className="flex gap-1 py-[5px]" key={순번}>
                                    <div className={`flex-[20px] flex-shrink-0 flex-grow-0`}>
                                      {
                                        순번 === 0 ?
                                          <img src={TablePlusIcon} alt="" className="cursor-pointer" onClick={e => 토양농도추가(o.key)}/> :
                                          <img src={TableMinusIcon} alt="" className="cursor-pointer" onClick={e => 토양농도삭제(o.key, 순번)}/>
                                      }
                                    </div>
                                    {
                                      Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(key => {
                                        return appContext.appData['CaCl2 추출농도']['중금속'][key] === true &&
                                          <div className={``} key={key}>
                                            <input
                                              type="text"
                                              className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                              value={토양농도[key]}
                                              onChange={e => onChangeText2(o.key, key, e.target.value, 순번)}
                                            />
                                          </div>
                                      })
                                    }
                                  </div>
                                })
                              }
                            </div>
                          </div>
                        })
                      }
                    </div>
                    {
                      appContext.appData['CaCl2 추출농도']['평균값 계산 여부'] &&
                      <>
                        <div className="mt-[34px] text-[16px]">평균값 계산</div>
                        <div className={`bg-white text-sm`}>
                          <div className={`w-full flex px-[12px] items-center border-t-[2px] border-b border-black`}>
                            <div className={`w-[100px]`}>시료명</div>
                            <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                              <div className="flex w-[443px] gap-1 py-[5px]">
                                {
                                  Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(key => {
                                    return appContext.appData['CaCl2 추출농도']['중금속'][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                                  })
                                }
                              </div>
                            </div>
                          </div>
                          {
                            appContext.appData['CaCl2 추출농도']['평균값 계산'].map(o => {
                              return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                                <div className={`w-[100px]`}>{o['시료명']}</div>
                                <div className="w-[711px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-[#cdcdcd] border-l" onScroll={e => onScroll(e)}>
                                  <div className="flex gap-1 py-[5px]">
                                    {
                                      Object.keys(appContext.appData['CaCl2 추출농도']['중금속']).map(key => {
                                        return appContext.appData['CaCl2 추출농도']['중금속'][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center`}>{o[key]}</div>
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            })
                          }
                        </div>
                      </>
                    }
                  </>
                }

              </FormWrap>


            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

