import React, {useEffect, useRef} from "react";
import KerasDeleteImage from 'img/kerasDeleteImage.png';

export const FormGroup = ({children}) => {
  return <div className={`flex flex-col gap-2`}>{children}</div>
}
export const FormWrap = ({children}) => {
  return <div className={`flex flex-col gap-2.5`}>{children}</div>
}
export const CheckboxWrap = ({children}) => {
  return <div className={`p-5 flex gap-2.5 flex-wrap`}>{children}</div>
}
export const CheckboxColWrap = ({children}) => {
  return <div className={`p-5 flex gap-2.5 flex-wrap flex-col`}>{children}</div>
}
export const CheckboxLabel = ({children}) => {
  return <label className={`flex gap-2 cursor-pointer`}>{children}</label>
}
export const RadioWrap = ({children}) => {
  return <div className={`p-5 flex gap-2.5 flex-wrap`}>{children}</div>
}
export const RadioColWrap = ({children}) => {
  return <div className={`p-5 flex gap-2.5 flex-wrap flex-col`}>{children}</div>
}
export const RadioLabel = ({children}) => {
  return <label className={`flex gap-2 cursor-pointer`}>{children}</label>
}
export const FormHalf = ({children}) => {
  return <div className={`flex gap-4`}>{children}</div>
}
export const FormTableTheadTr = ({children}) => {
  return <div className={`border-t border-b border-black px-2 flex`}>{children}</div>
}
export const FormTableTbodyTr = ({children}) => {
  return <div className={`flex items-center px-2 border-b border-gray-300`}>{children}</div>
}
export const FormTableTh = ({children}) => {
  return <div className={`font-bold text-sm py-4 px-2 flex-1`}>{children}</div>
}
export const FormTableTd = ({children, className}) => {
  return <div className={`text-sm py-4 px-2 flex-1 ${className ? className : ''}`}>{children}</div>
}

export const FileInput = ({children, fileName, accept = '*', onChange = function (){}, onDelete = function (){}}) => {

  return <>
    <label className="block bg-white rounded border border-gray-300 cursor-pointer relative overflow-hidden">
      <div className={`flex w-full text-[16px] `}>
        <div className={`mr-4 py-2 px-4 rounded-bl rounded-tl border-0 text-[16px] font-normal bg-[#ebe5f5]`}>파일찾기</div>
        <div className="flex flex-1 items-center">
          {fileName ? fileName : '파일을 선택해주세요.'}
        </div>
      </div>
      <input
        type="file"
        accept={accept}
        // className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-bl file:rounded-tl file:border-0 file:text-sm file:font-normal file:bg-gray-300 hover:file:bg-gray-400"
        className={`w-0 h-0 absolute top-[-300px] left-[-300px]`}
        onChange={e => onChange(e)}
      />
      {
        fileName &&
        <>
          <div
            className={`p-2 absolute top-[50%] right-[4px] translate-y-[-50%] z-20`}
            onClick={e => {
              e.preventDefault();
              onDelete();
            }}
          >
            <img
              src={KerasDeleteImage}
              alt=""
              className={`w-[14px] `}
            />
          </div>
        </>
      }
    </label>
    <p className={`text-[#444444] text-[14px] mt-[10px] pl-[21px]`}>이미지 파일(jpg, png, gif)만 업로드할 수 있습니다.</p>
  </>
}
