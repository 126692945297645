import React, {useContext, useEffect, useState} from 'react';
import {LayoutBox, LayoutBoxHeader, LayoutBoxHeaderH2, LayoutBoxHeaderRight, LayoutContentsHeader, LayoutContentsInner, LayoutContentsWrap} from "../../constants/LayoutCons";
import Side from "components/Side";
import {GetMenuUrl, } from "../../constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import EcArrowDown from 'img/ecArrowDown.png';
import {TableMinusIcon, TablePlusIcon} from "../../constants/KerasTable";
import Decimal from "decimal.js";
import * as lodash from "lodash";

export default ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const Eco_plate = appContext.appData['Eco plate']['시료'];
  const Bait_lamina = appContext.appData['Bait-lamina']['시료'];
  const 톡토기_종_풍부도 = appContext.appData['톡토기 종 풍부도']['시료'];
  const 응애_종_풍부도 = appContext.appData['응애 종 풍부도']['시료'];
  const [최종데이터, set최종데이터] = useState({});

  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }

  useEffect(() => {
    let data = {'대조구역': {}, 'Site 1': {}, 'Site 2': {}, 'Site 3': {}, 'Site 4': {}, 'Site 5': {}, 'Site 6': {}};
    ['대조구역', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map((시료명, 시료순번) => {
      if(Bait_lamina[시료순번]) data[시료명]['Bait-lamina'] = {'평균': Bait_lamina[시료순번]['%전환']};
      if(톡토기_종_풍부도[시료순번]) data[시료명]['톡토기 종 풍부도'] = {'평균': 톡토기_종_풍부도[시료순번]['%전환']};
      if(응애_종_풍부도[시료순번]) data[시료명]['응애 종 풍부도'] = {'평균': 응애_종_풍부도[시료순번]['%전환']};
      if(Eco_plate[시료순번]) data[시료명]['Eco plate'] = {'평균': Eco_plate[시료순번]['%전환']};
    });


    Object.keys(data).map(시료명 => {
      let 토탈 = 0, 갯수 = 0;
      Object.keys(data[시료명]).map(실험명 => {
        ++갯수;
        const 평균 = data[시료명][실험명]['평균'];
        const 고정평균 = data['대조구역'][실험명] ? data['대조구역'][실험명]['평균'] : 1;

        const 두번째값 =  new Decimal((평균)).div(고정평균); //((100 - 평균) / 100);
        data[시료명][실험명] = lodash.merge({'두번째값': 두번째값.toNumber()}, data[시료명][실험명]);

        const 세번째값 = 두번째값.toNumber() === 0 || 두번째값.toNumber() > 1 ? 0 : Math.log10(두번째값);

        data[시료명][실험명] = lodash.merge({'세번째값': 세번째값}, data[시료명][실험명]);
        토탈 = new Decimal(토탈).plus(Math.abs(세번째값)).toNumber();
        console.log(`${실험명} : `, 시료명, '평균', Number(평균) , '고정평균', Number(고정평균), '두번째값', 두번째값.toNumber(), '세번째값', 세번째값, '토탈', 토탈);
      });
      console.log(토탈);
      if(Object.keys(data[시료명]).length > 0) {
        const 종말점수 = 4;
        토탈 = 토탈 * -1;
        data[시료명] = lodash.merge({'토탈': isNaN(토탈) ? 0 : 토탈}, data[시료명]);
        data[시료명] = lodash.merge({'위해도': (1 - Math.pow(10, 토탈 / 종말점수)).toFixed(3)}, data[시료명]);
      }
    });
    console.log(data);
    set최종데이터(data);
  }, [])

  return (
    <div className="container my-7 px-0">
      <div className="my-5">
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <div className="">
                <div className={`bg-white text-sm mb-[10px]`}>
                  <div className="sticky top-0 bg-white">
                    <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                      <div className={`w-[203px]`}></div>
                      <div className={`flex-1 overflow-hidden overflow-x-auto scrollbar-hide scs`}>
                        <div className="flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs border-l border-[#cdcdcd]" onScroll={e => onScroll(e)}>
                          {
                            Object.keys(최종데이터).map((시료명, 순번) => {
                              return  Object.keys(최종데이터[시료명]).length > 0 &&<div key={순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{시료명}</div>
                            })
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#CDCDCD]`}>
                    <div className={`w-[203px]`}>Bait-lamina</div>
                    <div className="flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-l border-[#cdcdcd]">
                      <div className="flex gap-1 py-[5px] items-center">
                        {
                          Bait_lamina.map((시료, 시료순번) => {
                            return <div key={시료순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{시료['%전환']}</div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#CDCDCD]`}>
                    <div className={`w-[203px]`}>Eco plate</div>
                    <div className="flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-l border-[#cdcdcd]">
                      <div className="flex gap-1 py-[5px] items-center">
                        {
                          Eco_plate.map((시료, 시료순번) => {
                            return <div key={시료순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{시료['%전환']}</div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#CDCDCD]`}>
                    <div className={`w-[203px]`}>톡토기 종풍부도</div>
                    <div className="flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-l border-[#cdcdcd]">
                      <div className="flex gap-1 py-[5px] items-center">
                        {
                          톡토기_종_풍부도.map((시료, 시료순번) => {
                            return <div key={시료순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{시료['%전환']}</div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#CDCDCD]`}>
                    <div className={`w-[203px]`}>응애 종풍부도</div>
                    <div className="flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-l border-[#cdcdcd]">
                      <div className="flex gap-1 py-[5px] items-center">
                        {
                          응애_종_풍부도.map((시료, 시료순번) => {
                            return <div key={시료순번} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{시료['%전환']}</div>
                          })
                        }
                      </div>
                    </div>
                  </div>

                  <div className={`w-full p-[12px]`}>
                    <div className={`border-dashed border-gray-300 border-t-[3px]`}></div>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-b border-[#CDCDCD]`}>
                    <div className={`w-[203px]`}>생태위해도</div>
                    <div className="flex w-[620px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scrollbar-hide scs border-l border-[#cdcdcd]">
                      <div className="flex gap-1 py-[5px] items-center">
                        {
                          Object.keys(최종데이터).length > 0 && ['대조구역', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'].map(시료명 => {
                            return <div key={시료명} className={`flex-[100px] flex-shrink-0 flex-grow-0`}>{최종데이터[시료명]['위해도']}</div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

