import React from 'react';

const Footer = ({}) => {
  return (
    <div className={'container'}>
      <div className="border-t border-dashed text-center w-[600px] mx-auto pt-5 pb-5">
        <div className="">
          <div className={`mb-5`}>
            <div className="font-bold text-2xl text-[#60448d]">KERAS</div>
            <p className="text-[12px]">오염부지 토양생태 위해성평가 프로그램</p>
          </div>
          <div className={`text-xs`}>
            <div className="flex gap-1 justify-center mb-2">
              <span>TEL 1588 - 1234 (평일 09:00 ~ 18:00)</span>
              <span>/</span>
              <span>E-MAIL help@abcd.com</span>
            </div>
            <div className="flex gap-1 justify-center mb-2">
              <span>서울시 광진구 능동로 120 건국대학교 연구실</span>
            </div>
            <div className="flex gap-1 justify-center">
              <span>이용약관</span>
              <span>｜</span>
              <span>개인정보처리방침</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
