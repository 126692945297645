import React, {useContext} from 'react';
import Side from "components/Side";
import {FormGroup, FormWrap,} from "../../constants/FormConstants";
import {GetMenuUrl} from "constants/MenuCons";
import {useLocation, useNavigate} from "react-router-dom";
import {
  LayoutBox,
  LayoutBoxHeader,
  LayoutBoxHeaderH2,
  LayoutBoxHeaderRight,
  LayoutContentsHeader,
  LayoutContentsInner,
  LayoutContentsWrap,
  LayoutSubTitle
} from "../../constants/LayoutCons";
import AppContext from "../../contexts/AppContext";
import * as lodash from "lodash";

const Step1 = ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <FormWrap>
                <FormGroup>
                  <LayoutSubTitle>조사지점</LayoutSubTitle>
                  <input
                    type={`text`}
                    className={`w-full p-2 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['대상부지 기본정보']['조사지점']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['대상부지 기본정보']['조사지점'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                      }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>부지에 대한 접근성</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['대상부지 기본정보']['부지에 대한 접근성']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['대상부지 기본정보']['부지에 대한 접근성'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>조사 용이성</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['대상부지 기본정보']['조사 용이성']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['대상부지 기본정보']['조사 용이성'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>지표수 및 지하수 유무</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['대상부지 기본정보']['지표수 및 지하수 유무']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['대상부지 기본정보']['지표수 및 지하수 유무'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>통제 지역 존재 여부</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['대상부지 기본정보']['통제 지역 존재 여부']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['대상부지 기본정보']['통제 지역 존재 여부'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>주변 토지 및 수계에 대한 정보</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['대상부지 기본정보']['주변 토지 및 수계에 대한 정보']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['대상부지 기본정보']['주변 토지 및 수계에 대한 정보'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>기타 필요한 사항</LayoutSubTitle>
                  <textarea
                    className={`w-full p-5 border border-gray-400 rounded-[10px]`}
                    value={appContext.appData['현장사전조사']['대상부지 기본정보']['기타 필요한 사항']}
                    onChange={e=> {
                      appContext.appData['현장사전조사']['대상부지 기본정보']['기타 필요한 사항'] = e.target.value;
                      appContext.setAppData(lodash.merge({}, appContext.appData));
                    }}
                  />
                </FormGroup>

              </FormWrap>
            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

export default Step1;
