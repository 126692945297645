import React from 'react';
import Main from "../components/Main";

const MainPage = ({}) => {
  return (
    <Main>

    </Main>
  );
};

export default MainPage;
