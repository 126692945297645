import React from 'react';
import Step2 from "components/ecotoxicity/Step2";
import {Route, Routes} from "react-router-dom";
import Step2_1 from "components/ecotoxicity/Step2_1";

const Step2Page = ({}) => {
  return (
    <Routes>
      <Route path="" element={<Step2 />} />
      <Route path="1" element={<Step2_1 />} />
      <Route path=":num" element={<Step2 />} />
    </Routes>
  );
};

export default Step2Page;
