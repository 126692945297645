import React, {useContext, useEffect, useState} from 'react';
import {LayoutBox, LayoutBoxHeader, LayoutBoxHeaderH2, LayoutBoxHeaderRight, LayoutContentsHeader, LayoutContentsInner, LayoutContentsWrap} from "../../constants/LayoutCons";
import Side from "components/Side";
import {GetMenuUrl, } from "../../constants/MenuCons";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import {TableMinusIcon, TablePlusIcon} from "../../constants/KerasTable";
import * as lodash from "lodash";
import {v4 as uuidv4} from "uuid";
const Decimal = require('decimal.js');

export default ({}) => {

  const { num } = useParams()
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const 토양추출액독성실험종 = appContext.appData['토양추출액독성실험종'];

  const 엑셀데이터처리함수 = (data) => {
    if(Array.isArray(data) === false) return;
    if(data.length === 0) return;
    토양추출액독성실험종[num]['시료'] = [];
    let insertData = [];
    data.map(row => {
      if(insertData[row['con']] === undefined) {
        insertData[row['con']] = [];
      }
      insertData[row['con']].push([row[1]]);
    });
    Object.keys(insertData).map((시료명) => {
      console.log('insertData[시료명]', insertData[시료명]);
      토양추출액독성실험종[num]['시료'].push({
          key: uuidv4(),
          'checked': false,
          'con': 시료명,
          '평균': 0,
          '%전환': 0,
          '데이터': insertData[시료명]
        }
      );
    });
    calc();
  }


  useEffect(() => {
    if(num === undefined) {
      try {
        토양추출액독성실험종.map((실험, i) => {
          if(실험['checked'] === true) {
            throw i;
          }
        });
        navigate(`/ecotoxicity`);
      } catch (num) {
        navigate(`/ecotoxicity/step2/${num}`);
      }
    } else if(토양추출액독성실험종[num]['checked'] === false) {
      navigate(`/ecotoxicity`);
    }
  }, [navigate]);


  const calc = () => {
    토양추출액독성실험종[num]['시료'].map((시료, 시료순번) => {
      let total = 0, length = 시료['데이터'].length;
      const 데이터배열 = 시료['데이터'];
      const 첫번째시료 = 토양추출액독성실험종[num]['시료'][0];
      const 첫번째시료평균 = 첫번째시료['평균'];
      데이터배열.map(데이터 => {
        데이터.map(num => {
          try {
            if(num !== 0 && num !== '') {
              total = new Decimal(num).plus(total).toNumber();
            }
          } catch (e) {}
        });
      });
      if(length === 0) length = 1;
      시료['평균'] = Number(new Decimal(total).div(length).toNumber());
      if(시료순번 === 0) {
        시료['%전환'] = 100;
      } else {
        if(첫번째시료평균 === 0 || 시료['평균'] === 0) {
          시료['%전환'] = 0.001;
        } else {
          시료['%전환'] = Number(new Decimal(시료['평균']).div(첫번째시료평균).mul(100).toNumber());
          if(new Decimal(시료['평균']).div(첫번째시료평균).mul(100).toNumber() === 0) {
            시료['%전환'] = 0.001;
          }
        }
      }
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }


  return (
    <div className="container my-7 px-0">
      <div className="my-5">
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              {
                토양추출액독성실험종[num] &&
                <>

                  <div className={`bg-white text-sm mb-[10px]`}>
                    <div className="sticky top-0 bg-white">
                      <div className={`flex justify-between py-[6px] px-[20px] border-t border-black`}>
                        <div className="flex gap-[5px]">
                          <button
                            type={'button'}
                            className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                            onClick={e => {
                              const 순번 = 토양추출액독성실험종[num]['순번'];
                              let data = [];
                              순번.map(o => {
                                data.push(0);
                              });
                              토양추출액독성실험종[num]['시료'].push({
                                key: uuidv4(),
                                'checked': false,
                                'con': '',
                                '평균': 0,
                                '%전환': 0,
                                '데이터': [data]
                              });
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                              console.log(data);
                            }}
                          >
                            행추가
                          </button>
                          <button
                            type={'button'}
                            className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                            onClick={e => {
                              토양추출액독성실험종[num]['시료'] = 토양추출액독성실험종[num]['시료'].filter(o => ! o.checked);
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}
                          >
                            행삭제
                          </button>
                        </div>
                        <button
                          type={'button'}
                          className={`font-normal bg-[#60448D] w-[204px] h-[37px] rounded-[5px] text-[16px] text-white`}
                          onClick={() => {
                            appContext.importExcel(엑셀데이터처리함수);
                          }}
                        >
                          엑셀 데이터 가져오기
                        </button>
                      </div>
                      <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                        <div className={`mr-2.5`}>
                          <input
                            type="checkbox"
                            onChange={e => {
                              토양추출액독성실험종[num]['시료'].map(o => {
                                o['checked'] = e.target.checked;
                              })
                              appContext.setAppData(lodash.merge({}, appContext.appData));
                            }}
                          />
                        </div>
                        <div className={`w-[80px] mr-2.5`}>con.</div>
                        <div className={`w-[80px] mr-2.5`}>평균</div>
                        <div className={`w-[80px] mr-2.5`}>%전환</div>
                        <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center border-l border-[#CDCDCD]`}>
                          <div className="flex gap-1 pl-[8px] py-2">
                            <div className={`flex-[20px] flex-shrink-0 flex-grow-0`}></div>
                            {
                              토양추출액독성실험종[num]['순번'].map(num => {
                                return  <div key={num} className={`flex-[80px] flex-shrink-0 flex-grow-0`}>{num}</div>
                              })
                            }
                          </div>
                        </div>
                      </div>

                    </div>
                    {
                      토양추출액독성실험종[num]['시료'].length > 0 && 토양추출액독성실험종[num]['시료'].map((시료, 시료순번) => {
                        const 데이터배열 = 시료['데이터'];
                        return <React.Fragment key={시료.key}>
                          <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                            <div className={`mr-2.5`}>
                              <input
                                type="checkbox"
                                checked={시료['checked']}
                                onChange={e => {
                                  시료['checked'] = e.target.checked;
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }}
                              />
                            </div>
                            <div className={`w-[80px] mr-2.5`}>
                              <input
                                type="text"
                                className="h-[98px] border border-[#CDCDCD] rounded-[5px] w-[80px]"
                                value={시료['con'] === undefined ? '' : 시료['con']}
                                onChange={e => {
                                  시료['con'] = e.target.value;
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }}
                                style={{height: `${데이터배열.length * 30}px`}}
                              />
                            </div>
                            <div className={`w-[80px] mr-2.5`}>
                              <input
                                type="text"
                                className="h-[98px] border border-[#CDCDCD] rounded-[5px] w-[80px]"
                                value={시료['평균'] === undefined ? '' : 시료['평균']}
                                onChange={e => {
                                  시료['평균'] = e.target.value;
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }}
                                style={{height: `${데이터배열.length * 30}px`}}
                              />
                            </div>
                            <div className={`w-[80px] mr-2.5`}>
                              <input
                                type="text"
                                className="h-[98px] border border-[#CDCDCD] rounded-[5px] w-[80px]"
                                value={시료['%전환'] === undefined ? '' : 시료['%전환']}
                                onChange={e => {
                                  시료['%전환'] = e.target.value;
                                  appContext.setAppData(lodash.merge({}, appContext.appData));
                                }}
                                style={{height: `${데이터배열.length * 30}px`}}
                              />
                            </div>
                            <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center border-l border-[#CDCDCD]`}>
                              <div className="flex flex-col gap-1 pl-[8px] py-2">
                                {
                                  데이터배열.length > 0 && 데이터배열.map((데이터, 데이터순번) => {
                                    return <React.Fragment key={데이터순번}>
                                      <div className="flex w-[497px] gap-1 py-[5px] items-center">
                                        <div className={`flex-[20px] flex-shrink-0 flex-grow-0`}>
                                          {
                                            데이터순번 === 0 ? <img src={TablePlusIcon} alt="" className="cursor-pointer" onClick={e => {
                                                let data = [];
                                                토양추출액독성실험종[num]['순번'].map(o => {
                                                  data.push(0);
                                                });
                                                데이터배열.push(data);
                                                appContext.setAppData(lodash.merge({}, appContext.appData));
                                              }}/>
                                              : <img src={TableMinusIcon} alt="" className="cursor-pointer" onClick={e => {
                                                데이터배열.splice(데이터순번, 1);
                                                appContext.setAppData(lodash.merge({}, appContext.appData));
                                              }}/>
                                          }
                                        </div>
                                          {
                                            토양추출액독성실험종[num]['순번'].map(i => {
                                              return  <div key={i}><input type="text" className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`} value={데이터[i-1] === undefined ? '' : 데이터[i-1]} onChange={e => {
                                                시료['데이터'][데이터순번][i-1] = e.target.value;
                                                appContext.setAppData(lodash.merge({}, appContext.appData));

                                                calc();
                                              }}/></div>
                                            })
                                          }
                                      </div>
                                    </React.Fragment>
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      })
                    }
                  </div>
                </>
              }

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

