import React from 'react';
import {Route, Routes} from "react-router-dom";
import Step1Page from "./Step1Page";


const Router = ({}) => {
  return (
    <>
      <Routes>
        <Route path={'step1'} element={<Step1Page />} />
      </Routes>
    </>
  );
};

export default Router;
