import MidDot from 'img/middot.png';

export const LayoutBox = ({children}) => <div className="">{children}</div>
export const LayoutBoxHeader = ({children}) => <div className="flex justify-between items-end pb-2 border-b border-gray-950 mb-4">{children}</div>
export const LayoutBoxHeaderH2 = ({children}) => <h2 className="text-xl font-bold">{children}</h2>
export const LayoutBoxHeaderRight = ({children}) => <div className="flex gap-4 items-center">{children}</div>
export const LayoutContentsWrap = ({children}) => <div className="flex justify-between gap-[70px]">{children}</div>
export const LayoutContentsHeader = ({children}) => <div className="flex justify-between pb-2.5 border-b border-gray-900 mb-8 gap-[20px]">{children}</div>
export const LayoutContentsInner = ({children}) => <div className="flex-1 rounded py-[14px] max-w-[860px]">{children}</div>

export const LayoutSubTitle = ({children}) => <div className={`text-[18px] font-bold px-[14px] flex gap-[10px] items-center`}><img src={MidDot} alt="" className={`w-[4px] h-[4px]`}/> {children}</div>
export const LayoutSubTitle2 = ({children}) => <div className={`text-[16px] px-[14px] flex gap-[10px] items-center`}><img src={MidDot} alt="" className={`w-[4px] h-[4px]`}/> {children}</div>
