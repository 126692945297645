import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import AddressClose from 'img/addressClose.png'

const DaumPost = ({setActiveAddress, setAddress}) => {
  return (
    <div className={`fixed top-0 left-0 right-0 bottom-0`}>
      <div className="flex flex-col w-[807px] h-[676px] absolute top-1/2 left-1/2 bg-white z-10 translate-x-[-50%] translate-y-[-50%] border-[#DEE2E6] border rounded-[10px]">
        <h2 className={`p-4 text-[20px] font-bold flex justify-between items-center border-b border-[#DEE2E6]`}>
          주소찾기
          <div className={`p-1 cursor-pointer`} onClick={() => {
            setActiveAddress(false)
          }}>
            <img src={AddressClose} alt="" className={`w-[16px] h-[16px]`}/>
          </div>
        </h2>
        <div className={`flex-1 flex justify-center p-8`}>
          <div className={`w-[457px] h-[550px] border border-[#999999]`}>
            <DaumPostcode
              style={{height: 550}}
              onComplete={(data) => {
                setAddress(data.address);
                setActiveAddress(false);
              }}
              autoClose={false}
              defaultQuery=''
            />
          </div>
        </div>
      </div>
      <div className={`fixed top-0 left-0 right-0 bottom-0 bg-white opacity-60`} />
    </div>
  );
};

export default DaumPost;
