import React, {useContext, useEffect} from 'react';
import Side from "components/Side";
import {useLocation, useNavigate} from "react-router-dom";
import {LayoutBox, LayoutContentsInner, LayoutContentsWrap, LayoutSubTitle} from "../../constants/LayoutCons";
import {CheckboxLabel, CheckboxWrap, FormGroup, FormWrap} from "../../constants/FormConstants";
import AppContext from "../../contexts/AppContext";
import * as lodash from "lodash";
import {v4 as uuidv4} from "uuid";
import Toast from "../../util/Toast";
import Decimal from "decimal.js";

export default  ({}) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const pollutants = ['유기물질', '중금속', '기타'];

  const onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const objs = Array.from(document.getElementsByClassName("scs"));
    objs.map(obj => {
      obj.scrollLeft = scrollLeft;
    })
  }

  const checkPlaces = (value, flagPlace) => {
    if(typeof value !== 'string') {
      value = '' + value;
    }
    if(value.toString().indexOf('.') === -1) {
      return true;
    }
    return flagPlace >= value.split('.')[1].length;
  }

/*
  const fixFloat = (value, place) => {
    const f = Math.pow(10, place);
    if(isNaN(parseFloat(value))) {
      return 0;
    }
    return checkPlaces(value, place) ?  value : parseFloat(Math.ceil(value * f) / f).toFixed(place);
  }
*/

  const check = (type, key, checked) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][type][key] = checked;
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }


  const onChangeText = (key, field, value) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o =>
      o.key === key ? {...o, [field]: value} : o
    );
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }
  const onChangeChecked = (key, checked) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o =>
      o.key === key ? {...o, 'checked': checked} : o
    );
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const addTd = () => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].push({
      key: uuidv4(),
      'checked': false,
      '시료명': '',
      '위도': '',
      '경사': '',
      '경도': '',
      '고도': '',

      '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
      'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
      'F': '', '시안화합물': '',
    },);
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  const removeTd = () => {
    try {
      let flag = false;
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
        if(o.checked === true) flag = true;
      })
      if(flag) {
        appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].filter(o => o.checked === false);
        appContext.setAppData(lodash.merge({}, appContext.appData));
      } else {
        Toast.error('삭제 할 항목을 선택 후 행삭제해 주세요.');
      }
    } catch (e) {
      Toast.error('행삭제 실패');
    }
  }

  const calc = () => {
    try {
      // 평균
      pollutants.map(pollutant => {
        Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(v => {
          if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][v] === true) {
            let total = 0, length = 0;
            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
              if(parseFloat(o[v]) && o[v] > 0) {
                ++length;
                total += parseFloat(o[v]);
              }
            });
            try {
              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['평균'][v] = isNaN(new Decimal(total).div(length).toNumber()) ? 0 : new Decimal(total).div(length).toNumber();
            } catch (e) {
              appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['평균'][v] = 0;
            }
          }
        })
      })

      // 표준 편차
      pollutants.map(pollutant => {
        Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(v => {
          if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][v] === true) {
            let total = 0, length = 0;
            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
              if(parseFloat(o[v]) && o[v] > 0) {
                ++length;
                let value = parseFloat(o[v]) - appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['평균'][v];
                total += parseFloat((Math.pow(value, 2)));
              }
            });

            let result = Math.sqrt(new Decimal(total).div(length - 1));
            appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['표준편차'][v] = parseFloat(result);
          }
        })
      })

      // 변동계수
      pollutants.map(pollutant => {
        Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(v => {
          let result = 0;
          appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
            if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['표준편차'][v] && appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['평균'][v]) {
              result = parseFloat(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['표준편차'][v]) / parseFloat(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['평균'][v]);
            }
          });
          appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['변동계수'][v] = result;
        })
      })

      // 기준치민감도
      pollutants.map(pollutant => {
        Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(v => {
          let result = 0;
          appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
            if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['변동계수'][v]) {
              result = 0.4 / parseFloat(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['변동계수'][v]);
            }
          });
          appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['기준치민감도'][v] = result;
        })
      })

      // 통계학적 시료개수
      let max = 0;
      pollutants.map(pollutant => {
        Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(v => {
          let result = 0;
          appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
            if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['기준치민감도'][v]) {
              result = Math.pow(((1.645+0.842)/appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['기준치민감도'][v]), 2) + (0.5 * Math.pow(1.645, 2));
            }
          });
          result = parseFloat(result);
          if(max < result) {
            max = result;
          }
          appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['통계학적 시료개수'][v] = result;
        })
      })


      // 적정 시료 개수
      appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['적정 시료 개수'] = parseInt(0.3 * max);

      // 추가 시료 개수
      if(appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수'] > appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['적정 시료 개수']) {
        appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['추가 시료 개수'] = 0;
      } else {
        appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['추가 시료 개수'] = parseInt(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['적정 시료 개수'] - appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수']);
      }

      appContext.setAppData(lodash.merge({}, appContext.appData));
    } catch (e) {
      console.log(e);
      Toast.error('계산 실패!');
    }
  }

  const allCheck = (checked) => {
    appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'] = appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
      return {...o, checked: checked}
    });
    appContext.setAppData(lodash.merge({}, appContext.appData));
  }

  return (
    <div className="container my-7 px-0">
      <div>
        <LayoutBox>
          <LayoutContentsWrap>
            <Side />
            <LayoutContentsInner>
              <FormWrap>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 1.유기물질</LayoutSubTitle>
                  <CheckboxWrap>
                    {
                      Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질']['유기물질']).map(key => {
                        return <React.Fragment key={key}>
                          <CheckboxLabel>
                            <input type="checkbox" checked={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질']['유기물질'][key]} name={'유기물질'} value={key} onChange={e => check('유기물질', key, e.target.checked)}/> {key}
                          </CheckboxLabel>
                        </React.Fragment>
                      })
                    }
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 2.중금속</LayoutSubTitle>
                  <CheckboxWrap>
                    {
                      Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질']['중금속']).map(key => {
                        return <React.Fragment key={key}>
                          <CheckboxLabel>
                            <input type="checkbox" checked={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질']['중금속'][key]} name={'중금속'} value={key} onChange={e => check('중금속', key, e.target.checked)}/> {key}
                          </CheckboxLabel>
                        </React.Fragment>
                      })
                    }
                  </CheckboxWrap>
                </FormGroup>
                <FormGroup>
                  <LayoutSubTitle>오염물질 종류 - 3.기타</LayoutSubTitle>
                  <CheckboxWrap>
                    {
                      Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질']['기타']).map(key => {
                        return <React.Fragment key={key}>
                          <CheckboxLabel>
                            <input type="checkbox" checked={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질']['기타'][key]} name={'기타'} value={key} onChange={e => check('기타', key, e.target.checked)}/> {key}
                          </CheckboxLabel>
                        </React.Fragment>
                      })
                    }
                  </CheckboxWrap>
                </FormGroup>
              </FormWrap>

              <div className={`bg-white text-sm`}>
                <div className="sticky top-0 bg-white">
                  <div className={`flex justify-between py-[6px] px-[20px] border-t border-black`}>
                    <div className="flex gap-[5px]">
                      <button
                        type={'button'}
                        className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                        onClick={addTd}
                      >
                        행추가
                      </button>
                      <button
                        type={'button'}
                        className={`font-normal bg-[#60448D] w-[109px] h-[37px] rounded-[5px] text-[16px] text-white`}
                        onClick={removeTd}
                      >
                        행삭제
                      </button>
                    </div>
                    <button
                      type={'button'}
                      className={`font-normal bg-[#60448D] w-[94px] h-[37px] rounded-[5px] text-[16px] text-white`}
                      onClick={calc}
                    >
                      계산
                    </button>
                  </div>
                  <div className={`w-full flex px-[12px] items-center border-t border-b border-black`}>
                    <div className={`mr-2.5`}>
                      <input type="checkbox" onChange={e => allCheck(e.target.checked)}/>
                    </div>
                    <div className={`w-[80px] mr-2.5`}>시료명</div>
                    <div className={`w-[200px] flex flex-col border-r`}>
                      <div className={`flex border-b border-[#CDCDCD]`}>
                        <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>위도</div>
                        <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경사(%)</div>
                      </div>
                      <div className={`flex pb-[15px]`}>
                        <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>경도</div>
                        <div className={`flex-[100px] flex-grow-0 py-2 px-2.5 h-[45px] flex justify-center items-center`}>고도(m)</div>
                      </div>
                    </div>
                    <div className={`flex-1 overflow-hidden  text-center`}>
                      <div className="h-[46px] border-b border-[#cdcdcd] text-left px-[30px] py-[8px] flex items-center">토양농도 (mg/kg)</div>
                      <div className="flex w-[525px] gap-1 pl-[10px] py-[8px] overflow-x-scroll overflow-y-hidden scs" onScroll={e => onScroll(e)}>
                        {
                          pollutants.map(pollutant => {
                            return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                              return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true && <div key={key} className={`flex-[100px] flex-shrink-0 h-[29px] flex-grow-0 flex items-center justify-center`}>{key}</div>
                            })
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>

                {
                  appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['시료'].map(o => {
                    return <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`} key={o.key}>
                      <div className={`mr-2.5`}>
                        <input
                          type="checkbox"
                          checked={o.checked}
                          onChange={e => onChangeChecked(o.key, e.target.checked)}
                        />
                      </div>
                      <div className={`w-[80px] mr-2.5`}>
                        <input
                          type="text"
                          className={`p-2.5 border border-[#CDCDCD] rounded-[5px] h-[80px] w-[80px]`}
                          placeholder={'시료명'}
                          value={o['시료명']}
                          onChange={e => onChangeText(o.key, '시료명', e.target.value)}
                        />
                      </div>
                      <div className={`w-[200px] flex flex-col border-r border-[#CDCDCD]`}>
                        <div className={`flex pt-[8px] pb-[8px] pr-[8px] border-b border-[#cdcdcd] gap-1`}>
                          <div className={`flex-1 gap-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'위도'}
                              value={o['위도']}
                              onChange={e => onChangeText(o.key, '위도', e.target.value)}
                            />
                          </div>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'경사(%)'}
                              value={o['경사']}
                              onChange={e => onChangeText(o.key, '경사', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className={`flex pt-[8px] pb-[8px] pr-[8px] gap-1`}>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'경도'}
                              value={o['경도']}
                              onChange={e => onChangeText(o.key, '경도', e.target.value)}
                            />
                          </div>
                          <div className={`flex-1`}>
                            <input
                              type="text"
                              className={`w-[92px] h-[30px] border border-[#CDCDCD] rounded-[5px] px-1`}
                              placeholder={'고도(m)'}
                              value={o['고도']}
                              onChange={e => onChangeText(o.key, '고도', e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`flex-1 w-[525px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                        <div className="flex gap-1 pl-[8px] py-[5px]">
                          {
                            pollutants.map(pollutant => {
                              return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                                return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                                  <div className={``} key={key}>
                                    <input
                                      type="text"
                                      className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                      value={o[key]}
                                      onChange={e => onChangeText(o.key, key, e.target.value)}
                                    />
                                  </div>
                              })
                            })
                          }
                        </div>
                      </div>
                    </div>
                  })
                }


                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>평균(m)</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      {
                        pollutants.map(pollutant => {
                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                              <div className={``} key={key}>
                                <input
                                  type="text"
                                  className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                  readOnly={true}
                                  value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['평균'][key] === '' ?
                                  '계산전' :
                                  Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['평균'][key]).toFixed(3)}/>
                              </div>
                          })
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>표준편차(s)</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      {
                        pollutants.map(pollutant => {
                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                              <div className={``} key={key}>
                                <input
                                  type="text"
                                  className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                  readOnly={true}
                                  value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['표준편차'][key] === '' ?
                                    '계산전' :
                                    Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['표준편차'][key]).toFixed(3)}/>
                              </div>
                          })
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>변동계수(CV)</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      {
                        pollutants.map(pollutant => {
                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                              <div className={``} key={key}>
                                <input
                                  type="text"
                                  className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                  readOnly={true}
                                  value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['변동계수'][key] === '' ?
                                    '계산전' :
                                    Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['변동계수'][key]).toFixed(3)}/>
                              </div>
                          })
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>토양정밀조사 시료 개수(n1)</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      {
                        pollutants.map(pollutant => {
                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                              <div className={``} key={key}>
                                <input
                                  type="text"
                                  className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                  readOnly={true}
                                  value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['시료개수']}
                                />
                              </div>
                          })
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>기준치민감도(D)</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      {
                        pollutants.map(pollutant => {
                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                              <div className={``} key={key}>
                                <input
                                  type="text"
                                  className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                  readOnly={true}
                                  value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['기준치민감도'][key] === '' ?
                                    '계산전' :
                                    Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['기준치민감도'][key]).toFixed(3) }/>
                              </div>
                          })
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>통계학적 시료개수(n2)</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      {
                        pollutants.map(pollutant => {
                          return Object.keys(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant]).map(key => {
                            return appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['오염물질'][pollutant][key] === true &&
                              <div className={``} key={key}>
                                <input
                                  type="text"
                                  className={`w-[100px] h-[30px] border border-[#cdcdcd] rounded-[5px] px-1`}
                                  readOnly={true}
                                  value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['통계학적 시료개수'][key] === '' ?
                                    '계산전' :
                                    Number(appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['통계학적 시료개수'][key]).toFixed(3)}/>
                              </div>
                          })
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>적정 시료 개수</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      <div className={`w-full`}>
                        <input
                          type="text"
                          className={`h-[30px] w-full border border-[#cdcdcd] rounded-[5px] px-1`}
                          readOnly={true}
                          value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['적정 시료 개수'] === false ? 'n2중 최대값의 30%, 예 87*0.3 = 26': appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['적정 시료 개수']}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`w-full flex px-[12px] items-center border-b border-[#cdcdcd]`}>
                  <div className={`flex-1 text-center`}>추가 시료 개수</div>
                  <div className={`flex-[526px] border-l border-[#cdcdcd] flex-shrink-0 flex-grow-0 w-[526px] overflow-hidden overflow-x-auto scrollbar-hide text-center scs`} onScroll={e => onScroll(e)}>
                    <div className="flex gap-1 py-[5px] pl-[8px]">
                      <div className={`w-full`}>
                        <input
                          type="text"
                          className={`h-[30px] w-full border border-[#cdcdcd] rounded-[5px] px-1`}
                          readOnly={true}
                          value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['추가 시료 개수'] === false ? 'n1 – 적정시료 개수, 0 (if n1 > 적정시료개수)' : appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['추가 시료 개수']}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className={`mt-2.5`}>
                <div className={`mb-[30px]`}>추가 시료채취 필요한가?</div>
                <div className={`px-[22px]`}>
                  <div className="flex gap-[30px] items-end">
                    <div className="flex-1 flex flex-col gap-2">
                      <LayoutSubTitle>추가 시료개수</LayoutSubTitle>
                      <input
                        type="text"
                        className={`h-[43px] p-2 rounded border border-[#cdcdcd]`}
                        value={appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['추가시료개수']}
                        onChange={e => {
                          appContext.appData['평가범위 결정 및 현장조사 계획수립']['변동계수 산정-기존조사 결과 있을 때']['추가시료개수'] = e.target.value;
                          appContext.setAppData(lodash.merge({}, appContext.appData));
                        }}
                      />
                      <div className={`h-[43px] bg-[#60448D] rounded w-full text-white px-[16px] py-[7px] cursor-pointer`} onClick={() => {
                        navigate(`/determining/step2/sub1`);
                      }}>[Yes] 2-1.변동계수 산정-추가조사 후 입력 이동</div>
                    </div>
                    <div className="flex-1 flex">
                      <div className={`h-[43px] bg-[#60448D] rounded w-full text-white px-[16px] py-[7px] cursor-pointer`} onClick={() => {
                        navigate(`/determining/step4`);
                      }}>[No] 4.평가단위 결정 이동</div>
                    </div>
                  </div>
                </div>
              </div>

            </LayoutContentsInner>
          </LayoutContentsWrap>
        </LayoutBox>
      </div>
    </div>
  );
};

