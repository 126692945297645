import React from 'react';
import {Route, Routes} from "react-router-dom";
import Step1Page from "./Step1Page";
import Step2Page from "./Step2Page";


const Router = ({}) => {
  return (
    <>
      <Routes>
        <Route path={'step1'} element={<Step1Page />} />
        <Route path={'step2'} element={<Step2Page />} />
      </Routes>
    </>
  );
};

export default Router;
