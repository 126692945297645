import Toast from "../util/Toast";
import {v4 as uuidv4} from "uuid";
import SideDot from "../img/sideDot.png";
import React from "react";

export const 중금속분석토양농도 = {
  'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
}

export const 중금속분석결과데이터 = {
  'checked': false,
  '시료명': '시료명',
  '토양농도': [
    {...중금속분석토양농도},
  ]
};

export const 변동계수시료데이터 = {
  'checked': false,
  '시료명': '시료명',
  '위도': '36.975710',
  '경사': '20',
  '경도': '128.179965',
  '고도': '1005',

  '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
  'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
  'F': '', '시안화합물': '',
}

export const 토양농도 = {
  '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
  'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
  'F': '', '시안화합물': '',
}

export const 대조구역변동계수시료데이터 = {
  'checked': false,
  '시료명': '시료명',
  '위도': '36.975710',
  '경사': '20',
  '경도': '128.179965',
  '고도': '1005',
  '토양농도': [
    {...토양농도}
  ]
}

export const InitialSettingAppData = {
  '평가단위 이미지': {
    'image': '',
    'name': '',
  },
  '대조구역 지점 표기 이미지': {
    'image': '',
    'name': '',
  },
  '국토환경성 평가지도 이미지': {
    'image': '',
    'name': '',
  },
  '현장사전조사': {
    '대상부지 기본정보': {
      '조사지점': '',
      '부지에 대한 접근성': '',
      '조사 용이성': '',
      '지표수 및 지하수 유무': '',
      '통제 지역 존재 여부': '',
      '주변 토지 및 수계에 대한 정보': '',
      '기타 필요한 사항': '',
    },
    '생태수용체 기본정보': {
      '주요 관속식물상': '',
      '주요 무척추동물(곤충 등)': '',
      '주요 양서파충류상': '',
      '주요 조류상': '',
      '주요 포유류상': '',
      '주요 어류상': '',
      '기타 필요한 사항': '',
    },
  },
  '문헌조사': {
    '국토환경성 평가지도': {
      '대상부지': {
        '1등급(매우높음)': false,
        '2등급(높음)': false,
        '3등급(보통)': false,
        '4등급(낮음)': false,
        '5등급(매우낮음)': false,
      },
      '부지 인근 1km': {
        '1등급(매우높음)': false,
        '2등급(높음)': false,
        '3등급(보통)': false,
        '4등급(낮음)': false,
        '5등급(매우낮음)': false,
      },
      '부지 인근 5km': {
        '1등급(매우높음)': false,
        '2등급(높음)': false,
        '3등급(보통)': false,
        '4등급(낮음)': false,
        '5등급(매우낮음)': false,
      },
      '비고': '',
    },
    '생태적 가치평가': {
      '대상부지': {
        '1등급(매우높음)': false,
        '2등급(높음)': false,
        '3등급(보통)': false,
        '4등급(낮음)': false,
        '5등급(매우낮음)': false,
      },
      '부지 인근 1km': {
        '1등급(매우높음)': false,
        '2등급(높음)': false,
        '3등급(보통)': false,
        '4등급(낮음)': false,
        '5등급(매우낮음)': false,
      },
      '부지 인근 5km': {
        '1등급(매우높음)': false,
        '2등급(높음)': false,
        '3등급(보통)': false,
        '4등급(낮음)': false,
        '5등급(매우낮음)': false,
      },
      '비고': '',
      '생태/자연도 이미지': {
        'image': '',
        'name': '',
      }
    },
    '대상부지 기본정보': {
      '주소': '',
      '토지용도': [
        {'용도명': '전', 'checked': false},
        {'용도명': '답', 'checked': false},
        {'용도명': '과수원', 'checked': false},
        {'용도명': '목장', 'checked': false},
        {'용도명': '임야', 'checked': false},
        {'용도명': '대지', 'checked': false},
        {'용도명': '공장', 'checked': false},
        {'용도명': '학교', 'checked': false},
        {'용도명': '주차장', 'checked': false},
        {'용도명': '주유소', 'checked': false},
        {'용도명': '창고', 'checked': false},
        {'용도명': '도로', 'checked': false},
        {'용도명': '철도', 'checked': false},
        {'용도명': '하천', 'checked': false},
        {'용도명': '공원', 'checked': false},
        {'용도명': '잡종지', 'checked': false},
      ],
      '부지이력': [
        {'부지명': '광산', 'checked': false},
        {'부지명': '사격장', 'checked': false},
        {'부지명': '유류저장시설', 'checked': false},
        {'부지명': '화학물질저장시설', 'checked': false},
        {'부지명': '기타', 'checked': false},
      ],
      '부지이력기타': '',
      '유기물질': {
        '유기인': false,
        '폴리클로리네이티드비페닐': false,
        '페놀': false,
        '벤젠': false,
        '톨루엔': false,
        '에틸벤젠': false,
        '크실렌': false,
        '석유계총탄화수소': false,
        '트리클로로에틸렌': false,
        '테트라클로로에틸렌': false,
        '벤조(a)피렌': false,
      },
      '중금속': {
        'Cd': false,
        'Cu': false,
        'As': false,
        'Hg': false,
        'Pb': false,
        'Cr6+': false,
        'Zn': false,
        'Ni': false,
      },
      '기타': {
        'F': false,
        '시안화합물': false,
      },
      '지적도등본 이미지': {
        'image': '',
        'name': '',
      },
      '수치지형도 이미지': {
        'image': '',
        'name': '',
      },
      '항공사진 이미지': {
        'image': '',
        'name': '',
      },
      '토지(임야)대장등본 이미지': {
        'image': '',
        'name': '',
      },
      '토지이용계획 이미지': {
        'image': '',
        'name': '',
      },
    },
    '오염물질 정보': {
      '유기물질': {
        '유기인': {
          'checked': false,
          '물질명': '유기인',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '폴리클로리네이티드비페닐': {
          'checked': false,
          '물질명': '폴리클로리네이티드비페닐',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '페놀': {
          'checked': false,
          '물질명': '페놀',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '벤젠': {
          'checked': false,
          '물질명': '벤젠',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '톨루엔': {
          'checked': false,
          '물질명': '톨루엔',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '에틸벤젠': {
          'checked': false,
          '물질명': '에틸벤젠',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '크실렌': {
          'checked': false,
          '물질명': '크실렌',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '석유계총탄화수소': {
          'checked': false,
          '물질명': '석유계총탄화수소',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '트리클로로에틸렌': {
          'checked': false,
          '물질명': '트리클로로에틸렌',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '테트라클로로에틸렌': {
          'checked': false,
          '물질명': '테트라클로로에틸렌',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '벤조(a)피렌': {
          'checked': false,
          '물질명': '벤조(a)피렌',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        }
      },
      '중금속': {
        'Cd': {
          'checked': true,
          '물질명': 'Cd',
          '신뢰값': '0.39',
          '국외기준': '12',
          '판정': '미달',
        },
        'Cu': {
          'checked': true,
          '물질명': 'Cu',
          '신뢰값': '18.679',
          '국외기준': '60',
          '판정': '미달',
        },
        'As': {
          'checked': true,
          '물질명': 'As',
          '신뢰값': '562.38',
          '국외기준': '56',
          '판정': '초과',
        },
        'Hg': {
          'checked': false,
          '물질명': 'Hg',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        'Pb': {
          'checked': true,
          '물질명': 'Pb',
          '신뢰값': '554.23',
          '국외기준': '490',
          '판정': '초과',
        },
        'Cr6+': {
          'checked': true,
          '물질명': 'Cr6+',
          '신뢰값': '0.026',
          '국외기준': '-',
          '판정': '계산전',
        },
        'Zn': {
          'checked': true,
          '물질명': 'Zn',
          '신뢰값': '18909.09',
          '국외기준': '210',
          '판정': '초과',
        },
        'Ni': {
          'checked': true,
          '물질명': 'Ni',
          '신뢰값': '46.04',
          '국외기준': '65',
          '판정': '미달',
        },
      },
      '기타': {
        'F': {
          'checked': false,
          '물질명': 'F',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
        '시안화합물': {
          'checked': false,
          '물질명': '시안화합물',
          '신뢰값': '',
          '국외기준': '',
          '판정': '계산전',
        },
      },
    }
  },
  '생태위해성평가 필요성': {
    '생태위해성평가': {
      '생태위해성평가가 필요한가?': '아니오',
      '대상부지에 대한 문헌조사 및 현장 사전조사 결과를 바탕으로 1개 이상 기준에 포함될 시 생태위해성평가 이행': {
        '오염원, 오염범위, 오염물질 종류 및 오염물질 농도 중 한 가지 이상 뚜렷한 경우': false,
        '오염원 또는 예상 오염범위의 파악이 어려워 오염범위에 대한 불확실성이 높은 경우': false,
        '오염물질 생태유해성이 매우 높은 것으로 확인되거나 정보가 부족하여 생태유해성에 대한 불확실성이 높은 경우': false,
        '기조사된 오염물질의 농도가 현저하게 높아 생태수용체에 위해가 나타날 것으로 판단되는 경우': false,
        '기조사된 오염물질의 농도가 국외 생태보호 기준치를 초과하는 경우': false,
        '『야생동물 보호 및 관리에 관한 법률』, 『문화재보호법』 등의 법률에서 관리하는 생물체가 확인되는 경우': false,
      },
      '대상부지 및 주변 지역이 다음 사항에 대해 하나 이상을 포함하는 경우 생태위해성평가 이행': {
        '『환경정책기본법』 제23조(환경친화적 계획기법등의 작성·보급) 및 시행령 제11조의2(환경성 평가지도의 작성)에 의해 작성된 환경 · 생태적 평가 및 국토환경성평가 지도 등급상 대상부지가 1에서 3등급 사이거나, 대상부지 인근 1 km에 1또는 2등급, 부지 인근 5 km에 1등급이 인접한 경우': false,
        '『자연환경보전법』 제34조(생태ㆍ자연도의 작성ㆍ활용)에 의해 작성된 생태ㆍ자연도 등급상 대상부지가 1등급 또는 별도관리지역 이거나, 대상부지 인근 1 ~ 5km에 별도관리지역이 인접한 경우': false,
      },
    }
  },
  '평가범위 결정 및 현장조사 계획수립': {
    '평가범위 결정': '',
    '시료개수': 4,
    // step2
    '변동계수 산정-기존조사 결과 있을 때': {
      '추가시료개수': 0,
      '오염물질': {
        '유기물질': {
          '유기인': false, '폴리클로리네이티드비페닐': false, '페놀': false, '벤젠': false, '톨루엔': false, '에틸벤젠': false, '크실렌': false, '석유계총탄화수소': false, '트리클로로에틸렌': false, '테트라클로로에틸렌': false, '벤조(a)피렌': false,
        },
        '중금속': {
          'Cd': true, 'Cu': true, 'As': true, 'Hg': false, 'Pb': true, 'Cr6+': false, 'Zn': true, 'Ni': true,
        },
        '기타': {
          'F': false, '시안화합물': false,
        },
      },
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'decisionChecked': false,
          '시료명': '',
          '위도': '',
          '경사': '',
          '경도': '',
          '고도': '',

          '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
          'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
          'F': '', '시안화합물': '',
        },
      ],
      '평균': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '표준편차': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '변동계수': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '기준치민감도': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '통계학적 시료개수': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '적정 시료 개수': '',
      '추가 시료 개수': '',
    },
    '변동계수 산정-추가조사 후 입력': {
      '추가시료개수': 0,
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'decisionChecked': false,
          '시료명': '', '위도': '', '경사': '', '경도': '', '고도': '',
          '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
          'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
          'F': '', '시안화합물': '',
        },
      ],
      '평균': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '표준편차': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
    },
    '변동계수 산정-기존조사+추가조사 결과 입력': {
      '평균': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '표준편차': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
    },

    // step3
    '변동계수 산정-정밀조사 실시': {
      '오염물질': {
        '유기물질': {
          '유기인': false, '폴리클로리네이티드비페닐': false, '페놀': false, '벤젠': false, '톨루엔': false, '에틸벤젠': false, '크실렌': false, '석유계총탄화수소': false, '트리클로로에틸렌': false, '테트라클로로에틸렌': false, '벤조(a)피렌': false,
        },
        '중금속': {
          'Cd': true, 'Cu': true, 'As': true, 'Hg': false, 'Pb': true, 'Cr6+': false, 'Zn': true, 'Ni': true,
        },
        '기타': {
          'F': false, '시안화합물': false,
        },
      },
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'decisionChecked': false,
          '시료명': '',
          '위도': '',
          '경사': '',
          '경도': '',
          '고도': '',

          '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
          'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
          'F': '', '시안화합물': '',
        },
      ],
      '평균': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '표준편차': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '변동계수': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '기준치민감도': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '통계학적 시료개수': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '적정 시료 개수': '',
      '추가 시료 개수': '',
    },
    '변동계수 산정-정밀조사 추가조사 후 입력': {
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'decisionChecked': false,
          '시료명': '', '위도': '', '경사': '', '경도': '', '고도': '',
          '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
          'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
          'F': '', '시안화합물': '',
        }
      ],
      '평균': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '표준편차': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
    },
    '변동계수 산정-기존조사+추가조사 결과 입력2': {
      '평균': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '표준편차': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
    },

    '대조구역 정보 입력': {
      '오염물질': {
        '유기물질': {
          '유기인': false, '폴리클로리네이티드비페닐': false, '페놀': false, '벤젠': false, '톨루엔': false, '에틸벤젠': false, '크실렌': false, '석유계총탄화수소': false, '트리클로로에틸렌': false, '테트라클로로에틸렌': false, '벤조(a)피렌': false,
        },
        '중금속': {
          'Cd': true, 'Cu': true, 'As': true, 'Hg': false, 'Pb': true, 'Cr6+': false, 'Zn': true, 'Ni': true,
        },
        '기타': {
          'F': false, '시안화합물': false,
        },
      },
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          '시료명': '',
          '위도': '',
          '경사': '',
          '경도': '',
          '고도': '',
          '토양농도': [
            {
              '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
              'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
              'F': '', '시안화합물': '',
            }
          ]
        },
      ],
      '평균': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '표준편차': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '변동계수': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '기준치민감도': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '통계학적 시료개수': {
        '유기인': '', '폴리클로리네이티드비페닐': '', '페놀': '', '벤젠': '', '톨루엔': '', '에틸벤젠': '', '크실렌': '', '석유계총탄화수소': '', '트리클로로에틸렌': '', '테트라클로로에틸렌': '', '벤조(a)피렌': '',
        'Cd': '', 'Cu': '', 'As': '', 'Hg': '', 'Pb': '', 'Cr6+': '', 'Zn': '', 'Ni': '',
        'F': '', '시안화합물': '',
      },
      '적정 시료 개수': false,
      '추가 시료 개수': false,
    },
  },
  // 생태위해성평가
  '중금속 분석결과': {
    '중금속': {
      'Cd': true, 'Cu': true, 'As': true, 'Hg': false, 'Pb': true, 'Cr6+': false, 'Zn': true, 'Ni': true,
    },
    '시료': [
      {
        key: uuidv4(),
        'checked': false,
        '시료명': '',
        '토양농도': [
          {'Cd': 0, 'Cu': 0, 'As': 0, 'Hg': 0, 'Pb': 0, 'Cr6+': 0, 'Zn': 0, 'Ni': 0,},
        ]
      }
    ],
    '평균값 계산 여부': false,
    '평균값 계산': [

    ]
  },
  'CaCl2 추출농도': {
    '유무': '유',
    '중금속': {
      'Cd': true, 'Cu': true, 'As': true, 'Hg': false, 'Pb': true, 'Cr6+': false, 'Zn': true, 'Ni': true,
    },
    '시료': [
      {
        key: uuidv4(),
        'checked': false,
        '시료명': '',
        '토양농도': [
          {'Cd': '0.00', 'Cu': '0.00', 'As': '0.00', 'Hg': '0.00', 'Pb': '0.00', 'Cr6+': '0.00', 'Zn': '0.00', 'Ni': '0.00',},
        ]
      }
    ],
    '평균값 계산 여부': false,
    '평균값 계산': [

    ]
  },
  'HNO3 추출농도': {
    '유무': '유',
    '중금속': {
      'Cd': true, 'Cu': true, 'As': true, 'Hg': true, 'Pb': true, 'Cr6+': true, 'Zn': true, 'Ni': true,
    },
    '시료': [
      {
        key: uuidv4(),
        'checked': false,
        '시료명': '',
        '토양농도': [
          {'As': 0.00, 'Cd': 0.00, 'Cu': 0.00,  'Ni': 0.00, 'Pb': 0.00 , 'Zn': 0.00, 'Hg': 0.00, 'Cr6+': 0.00},
        ]
      }
    ],
    '평균값 계산 여부': false,
    '평균값 계산': [

    ]
  },
  '토양 중금속 농도': {
    '유무': '유',
    '중금속': {
      'Cd': true, 'Cu': true, 'As': true, 'Hg': true, 'Pb': true, 'Cr6+': true, 'Zn': true, 'Ni': true,
    },
    '시료': [
      {
        key: uuidv4(),
        'checked': false,
        '시료명': '',
        '토양농도': [
          {'As': 0.00, 'Cd': 0.00, 'Cu': 0.00,  'Ni': 0.00, 'Pb': 0.00 , 'Zn': 0.00, 'Hg': 0.00, 'Cr6+': 0.00},
        ]
      }
    ],
    '평균값 계산 여부': false,
    '평균값 계산': [

    ]
  },
  '토양독성실험종': [
    {
      '실험명': '단자엽식물강',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '쌍자엽식물강',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '녹조강',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '환대강',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '곤충강',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '톡토기강',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '거미강',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '취균강',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '기타',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
  ],
  '토양추출액독성실험종': [
    {
      '실험명': '물벼룩',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '어류(수정란)',
      'checked': false,
      '순번': [
        '노출개체수수', '정상개체수', '7d-정상비율'
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0, 0, 0],
          ]
        }
      ]
    },
    {
      '실험명': '어류',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '조류',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '선충',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '수생식물',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '미생물',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
    {
      '실험명': '기타',
      'checked': false,
      '순번': [
        1,
      ],
      '시료': [
        {
          key: uuidv4(),
          'checked': false,
          'con': '',
          '평균': 0,
          '%전환': 0,
          '데이터': [
            [0],
            [0],
            [0],
          ]
        }
      ]
    },
  ],
  'Eco plate': {
    '시료': [
      {
        key: uuidv4(),
        'checked': false,
        '시료명': '',
        '평균': 0,
        '%전환': 0,
        '데이터': [
          [0],
          [0],
          [0],
        ]
      }
    ]
  },
  'Bait-lamina': {
    '시료': [
      {
        key: uuidv4(),
        'checked': false,
        '시료명': '',
        '평균': 0,
        '%전환': 0,
        '데이터': [
          [0, 0],
        ]
      }
    ]
  },
  '톡토기 종 풍부도': {
    '시료': [
      {
        key: uuidv4(),
        'checked': false,
        '시료명': '',
        '평균': 0,
        '%전환': 0,
        '데이터': [
          [0],
          [0],
          [0],
        ]
      }
    ]
  },
  '응애 종 풍부도': {
    '시료': [
      {
        key: uuidv4(),
        'checked': false,
        '시료명': '',
        '평균': 0,
        '%전환': 0,
        '데이터': [
          [0],
          [0],
          [0],
        ]
      }
    ]
  },
  '식생조사': {
    '항목': [
      'R3', 'Site 1', 'Site 2', 'Site 3', 'Site 4', 'Site 5', 'Site 6'
    ],
    '시료': [
      {
        key: uuidv4(),
        'checked': false,
        '종명': '',
        '데이터' : {'Site 1': 0, 'Site 2': 0, 'Site 3': 0, 'Site 4': 0, 'Site 5': 0, 'Site 6': 0, 'R3': 0}
      }
    ],
    '분류군수': {'Site 1': 0, 'Site 2': 0, 'Site 3': 0, 'Site 4': 0, 'Site 5': 0, 'Site 6': 0, 'R3': 0},
    '피도합': {'Site 1': 0, 'Site 2': 0, 'Site 3': 0, 'Site 4': 0, 'Site 5': 0, 'Site 6': 0, 'R3': 0},
  },

  '화학 위해도': {

  },
  '생태독성 위해도': {

  },
  '생태 위해도': {

  },
  '통합위해도': {

  },
  '평가단위 결정 지도 url': '',
  '통합위해도 산출 지도 url': ''
};

export const SideData = {
  '기초조사': {
    'literature': {
      'name' : '문헌조사',
      'sub': {
        'step1' : {name: '대상부지 기본정보'},
        'step2' : {name: '생태적 가치평가\n- 국토환경성 평가지도'},
        'step3' : {name: '생태적 가치평가\n- 생태/자연도'},
        'step4' : {name: '오염물질 정보'},
      }
    },
    'ecological': {
      'name' : '생태위해성평가 필요성',
      'sub': {
        'step1' : {name: '생태위해성평가가 필요한가?'},
      }
    },
    'investigation': {
      'name' : '현장사전조사',
      'sub': {
        'step1' : {name: '대상부지 기본정보'},
        'step2' : {name: '생태수용체 기본정보'},
      }
    },
    'determining': {
      'name' : '평가범위 결정 및 현장조사 계획수립',
      'sub': {
        'step1' : {name: '평가범위 결정'},
        'step2' : {
          name: '변동계수 산정\n-기존조사 결과 있을 때',
          sub: {
            'sub1': {name: '변동계수 산정-추가조사 후 입력'},
            'sub2': {name: '변동계수 산정-기존조사+추가조사 결과 입력 페이지'},
          }
        },
        'step3' : {
          name: '변동계수 산정\n-정밀조사 실시',
          sub: {
            'sub1': {name: '변동계수 산정-정밀조사 추가조사 후 입력'},
            'sub2': {name: '변동계수 산정-기존조사+추가조사 결과 입력 페이지'},
          }
        },
        'step4' : {name: '평가단위 결정'},
        'step5' : {name: '대조구역 정보 입력'},
      }
    },
    'final': {
      'name' : '기초조사 최종 보고서'
    },
  },
  '생태위해성평가': {
    'chemistry': {
      'name' : '화학평가',
      'sub': {
        'step1' : {name: '1.중금속 분석결과'},
        'step2' : {name: '2.0.001 M CaCl2를 이용한 중금속 추출농도'},
        'step3' : {name: '3.0.43 M HNO3를 이용한 중금속 추출농도'},
        'step4' : {name: '4.증류수로 추출된 토양 중금속 농도'},
        'step5' : {name: '5.화학평가 결과'},
      }
    },
    'ecotoxicity': {
      'name': '생태독성평가',
      'sub': {
        'step1': {name: '1.토양독성실험종'},
        'step2': {name: '2.토양추출액실험종'},
        'step3': {name: '3.생태독성평가 결과'},
      }
    },
    'riskecological': {
      'name': '생태평가',
      'sub': {
        'step1': {name: '1.Eco plate'},
        'step2': {name: '2.Bait-lamina'},
        'step3': {name: '3.톡토기 종 풍부도'},
        'step4': {name: '4.응애 종 풍부도'},
        'step5': {name: '5.식생조사'},
        'step6': {name: '6.생태평가 결과'},
      }
    },
    'integrated': {
      'name': '통합위해도 산출'
    },
    'finalreport': {
      'name': '생태위해성평가 최종 보고서'
    }
  }
};

export const GetMenuUrl = (currentPathname, navigate, appData = {}) => {
  const MenuOrder = ['/literature/step1', '/literature/step2', '/literature/step3', '/literature/step4',
    '/ecological/step1',
    '/investigation/step1', '/investigation/step2',
    '/determining/step1',
    '/determining/step2', '/determining/step2/sub1', '/determining/step2/sub2',
    '/determining/step3', '/determining/step3/sub1', '/determining/step3/sub2',
    '/determining/step4', '/determining/step5',
    '/final',
    '/chemistry/step1', '/chemistry/step2', '/chemistry/step3', '/chemistry/step4', '/chemistry/step5',
    '/ecotoxicity', '/ecotoxicity/step1', '/ecotoxicity/step2', '/ecotoxicity/step3',
    '/riskecological/step1', '/riskecological/step2', '/riskecological/step3', '/riskecological/step4', '/riskecological/step5', '/riskecological/step6',
    '/integrated', '/finalreport'
  ];
  if(currentPathname === '/determining/step2' || currentPathname === '/determining/step3') {
    Toast.info('하단 버튼을 이용해 주세요.');
    return;
  }
  if(currentPathname === '/determining/step2/sub2' || currentPathname === '/determining/step3/sub2') {
    navigate('/determining/step4');
    return;
  }
  if(currentPathname === '/determining/step1') {
    if(appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'YES') {
      navigate('/determining/step2');
      return;
    } else if(appData['평가범위 결정 및 현장조사 계획수립']['평가범위 결정'] === 'NO') {
      navigate('/determining/step3');
      return;
    }
    Toast.error('Yes/No 선택 후 시도해 주세요.');
    return;
  }

  if(currentPathname.indexOf('/ecotoxicity/step1') !== -1) {
    let 순번배열 = appData['토양독성실험종'].map((실험, 순번) => 실험['checked'] ? 순번 : undefined).filter((순번) => 순번 !== undefined)
    const urlData = currentPathname.split('/');
    if(순번배열.indexOf(Number(urlData[3])) !== -1) {
      let 다음순번 = 순번배열.indexOf(Number(urlData[3])) + 1;
      navigate(순번배열[다음순번] === undefined ? '/ecotoxicity/step2' : '/ecotoxicity/step1/' + 순번배열[다음순번]);
      return;
    }
  }
  if(currentPathname.indexOf('/ecotoxicity/step2') !== -1) {
    let 순번배열 = appData['토양추출액독성실험종'].map((실험, 순번) => 실험['checked'] ? 순번 : undefined).filter((순번) => 순번 !== undefined)
    const urlData = currentPathname.split('/');
    if(순번배열.indexOf(Number(urlData[3])) !== -1) {
      let 다음순번 = 순번배열.indexOf(Number(urlData[3])) + 1;
      navigate(순번배열[다음순번] === undefined ? '/ecotoxicity/step3' : '/ecotoxicity/step2/' + 순번배열[다음순번]);
      return;
    }
  }

  if(MenuOrder.indexOf(currentPathname) === -1 || ! MenuOrder[MenuOrder.indexOf(currentPathname) + 1]) {
    alert('다음 메뉴가 없습니다.');
    return;
  }
  navigate(MenuOrder[MenuOrder.indexOf(currentPathname) + 1]);
}
