import React from 'react';
import Step4 from "components/literature/Step4";

const Step4Page = ({}) => {
  return (
    <Step4 />
  );
};

export default Step4Page;
